import React from 'react';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import FormMgr from './FormMgr';
import { useGlobalContext } from '../SamState';
import FontPicker, { FontCalloutRecord, calloutRecordToFontString, styleValueToCalloutRecord } from './FontPicker';
import RifmNumeric from './RifmNumeric';
import DatePicker from './DatePicker';
import { mergeOptionsRecords } from '../libSupport';
import ColorfulPicker, { CssColor } from './ColorfulPicker';

import { CustomFormatterCallback, CustomValidatorCallback, FormFieldRecord, FormFieldSizingRecord, FormFieldType, MediaValueRecord } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';

// form styled as horizontal fields, labels and fields centered in their divs, support for media sizes

const MasterContainer = styled.div<{ $visibility: string }>`
    visibility: ${props => props.$visibility};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    padding-top: 0;
`
const HeaderContainer = styled.p<{ $fontSize: number; }>`
    font-size: ${props => props.$fontSize}px;
    margin-top; 4px;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    width: 100%;
`
const FormRowContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
`
const LabelAndInputContainer = styled.div`
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const LabelContainer = styled.p<{ $fontSize: number; $isBold?: boolean }>`
    color: ${props => props.$isBold ? "black" : "#666"};
    font-size: ${props => props.$fontSize}px;
    margin: 0;
    font-weight: ${props => props.$isBold ? "bold" : "regular"};
    margin-bottom: 2px;
`
const StyledInput = styled.input<{ $borderColor: string; $height: number; $width?: number }>`
    border: 1px solid ${props => props.$borderColor};     /* #666 or red if error */
    height: ${props => props.$height}px;
    width: ${props => props.$width}px;
`
const StyledTextArea = styled.textarea<{ $borderColor: string; $height: number; $backColor: string }>`
    border: 2px solid ${props => props.$borderColor};     /* #666 or red if error */
    height: ${props => props.$height}px;
    background-color: ${props => props.$backColor};      /* should be backColor25 */
    border-style: outset;
`
const StyledSelect = styled.select<{ $inputHeight: number }>`
    height: ${props => props.$inputHeight}px;
    border: 1px solid #ccc;
`

interface InputProps {
    forms: FormMgr;
    formId: string;
    fontSize: number;
    labelFontSize: number;
    headerFontSize: number;
    customFormatter?: CustomFormatterCallback;
    customValidator?: CustomValidatorCallback;
}
interface SimpleHzFormProps {
    id: string;
    fields?: FormFieldRecord[]; // if not passed parent MUST use FormMgr.initFieldsAndValues
    initialValues?: Record<string, any>;
    fontSize?: number;          // default to 14px
    fieldHeight?: number;       // default to 24px
    headerFontSize?: number;    // isVertical must true and headers must be included in field array
    labelFontSize?: number;     // default to 85% of fontSize
    hideForm?: boolean;
    customFormatter?: CustomFormatterCallback;
    customValidator?: CustomValidatorCallback;
}
const SimpleHzForm: React.FC<SimpleHzFormProps> = (props) => {
    const fontSize = props.fontSize ?? 14;
    const inputProps: InputProps = {
        formId: props.id,
        forms: new FormMgr(useGlobalContext().setContext),
        fontSize,
        labelFontSize: props.labelFontSize ? props.labelFontSize : fontSize * .85,
        headerFontSize: props.headerFontSize ?? 18,
        customFormatter: props.customFormatter,
        customValidator: props.customValidator
    };

    React.useEffect(() => {
        if (props.fields) {
            inputProps.forms.initFieldsAndValues(inputProps.formId, props.fields, props.initialValues, inputProps.labelFontSize);
        }
    }, [inputProps.formId]);

    const renderRow = (startIndex: number, fields: FormFieldRecord[]): { nextIndex: number; row: JSX.Element[] } => {
        const row: JSX.Element[] = [];
        while (true) {
            if (startIndex >= fields.length) {
                break;
            }
            row.push(<LabelAndInput fieldIndex={startIndex} inputProps={inputProps} />);
            if (fields[startIndex].type === FormFieldType.header) {
                // next field is a header so it gets its own row
                return { nextIndex: startIndex + 1, row };
            }
            if (startIndex < fields.length - 1) {
                // we have at least 1 more field to go so do a lookahead for headers and linebreaks
                if (fields[startIndex + 1].type === FormFieldType.break) {
                    // next field is a linebreak so skip it
                    return { nextIndex: startIndex + 2, row };
                }
                if (fields[startIndex + 1].type === FormFieldType.header) {
                    // next field is a header so it gets its own row
                    return { nextIndex: startIndex + 1, row };
                }

            }
            startIndex++;
        }
        return { nextIndex: startIndex, row };      // if we get this far we have finished all fields
    }

    const rows: JSX.Element[] = [];
    if (inputProps.forms.getFormValues(inputProps.formId)) {
        const fields = inputProps.forms.getFormFields(inputProps.formId) as FormFieldRecord[];
        let index = 0;
        while (index < fields.length) {
            const { nextIndex, row } = renderRow(index, fields);
            rows.push(<FormRowContainer>{row}</FormRowContainer>);
            index = nextIndex;
        }
    }

    return (
        <MasterContainer $visibility={props.hideForm ? "hidden" : "visible"}>
            {rows}
        </MasterContainer>
    )
}
//--------------------------------------------------------------
interface LabelAndInputProps {
    fieldIndex: number;
    inputProps: InputProps;
}
const LabelAndInput: React.FC<LabelAndInputProps> = (props) => {
    const field = props.inputProps.forms.getFieldByIndex(props.inputProps.formId, props.fieldIndex) as FormFieldRecord;
    if (field.type === FormFieldType.header) {
        return <HeaderContainer $fontSize={props.inputProps.headerFontSize}>{field.label}</HeaderContainer>
    }
    return (
        <LabelAndInputContainer>
            <LabelContainer $fontSize={props.inputProps.labelFontSize} $isBold={field.boldLabel}>{field.label}</LabelContainer>
            <Input {...props} />
        </LabelAndInputContainer>
    )
}
//--------------------------------------------------------------
const Input: React.FC<LabelAndInputProps> = (props) => {
    const field = props.inputProps.forms.getFieldByIndex(props.inputProps.formId, props.fieldIndex) as FormFieldRecord;
    const handleChange = (value: string | number | boolean, name: string) => {
        //        console.log("handleChange on " + name + ", value=" + value)
        if (field.validator) {
            if (field.validator.isAllUpper) {
                value = (value as string).toUpperCase();
            }
            if (field.validator.maxLength) {
                value = (value as string).substring(0, field.validator.maxLength);
            }
        }
        if (props.inputProps.forms.getValue(props.inputProps.formId, field.name!) !== value) {
            props.inputProps.forms.setValue(props.inputProps.formId, field.name!, value);
            props.inputProps.forms.deleteError(props.inputProps.formId, field.name!);
        }
    }
    const handleFontSubmitted = (callout: FontCalloutRecord | null, name: string) => {
        if (callout) {
            handleChange(calloutRecordToFontString(callout), name);
        }
    }
    const getFormattedValue = (field: FormFieldRecord): string => {
        let value: string = props.inputProps.forms.getValue(props.inputProps.formId, field.name!);
        if (props.inputProps.customFormatter) {
            const formatted = props.inputProps.customFormatter(field.name!, value);
            if (formatted) {
                return formatted;
            }
        }
        return value;
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const target = e.target as HTMLInputElement;
        handleChange(target.value, target.id);
    }
    const handleCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as any;
        handleChange(target.checked as boolean, target.id);
    }
    const error = props.inputProps.forms.getError(props.inputProps.formId, field.name! ?? '');
    const isError = !!error;
    switch (field.type) {
        case FormFieldType.text:
            return <StyledInput $borderColor={isError ? "red" : "#ccc"} $width={field.size!.widthPx ? field.size!.widthPx : undefined}
                value={getFormattedValue(field)} $height={field.size!.height!} placeholder={field.placeholder}
                type={field.validator && field.validator.isPassword ? "password" : "text"}
                onChange={handleInputChange} />
        case FormFieldType.multiLine:
            return <StyledTextArea id={field.name!} $borderColor={isError ? "red" : "#ccc"}
                value={getFormattedValue(field)} $height={field.size!.height!} placeholder={field.placeholder}
                $backColor={field.multiLineBackColor ?? app.themes.backColor25 ?? "white"} onChange={handleInputChange} />
        case FormFieldType.combo:
            return (
                <StyledSelect id={field.name!} $inputHeight={field.size!.height!} value={getFormattedValue(field)}
                    onChange={handleInputChange}>
                    {field.comboSource!.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>{option.caption ?? option.value}</option>
                        );
                    })}
                </StyledSelect>
            )
        case FormFieldType.color:
            return <ColorInput initialColor={props.inputProps.forms.getValue(props.inputProps.formId, field.name!)} colorSubmitted={color => handleChange(color, field.name!)} />
        case FormFieldType.checkbox:
            return (
                <input id={field.name!} type="checkbox" value='' checked={props.inputProps.forms.getValue(props.inputProps.formId, field.name!)}
                    onChange={handleCheckedChanged} />
            )
        case FormFieldType.date:
            return (
                <DatePicker name={field.name!} value={props.inputProps.forms.getValue(props.inputProps.formId, field.name!)} onChange={handleChange} />
            )
        case FormFieldType.fontFamily:
            return (
                <FontPicker id={props.inputProps.formId + "font"} width={80} selectedCallout={styleValueToCalloutRecord(props.inputProps.forms.getValue(props.inputProps.formId, field.name!))}
                    fontSubmitted={(callout: FontCalloutRecord | null) => handleFontSubmitted(callout, field.name!)} />
            )
        case FormFieldType.phone:
            return (
                <RifmNumeric name={field.name!} fieldType={field.type} initialValue={props.inputProps.forms.getValue(props.inputProps.formId, field.name!)} height={field.size!.height!}
                    borderColor={isError ? "red" : "#ccc"} textAlign={"left"}
                    onChange={handleChange} />
            )
        case FormFieldType.int:
        case FormFieldType.decimal:
            return (
                <RifmNumeric name={field.name!} fieldType={field.type} initialValue={props.inputProps.forms.getValue(props.inputProps.formId, field.name!)}
                    borderColor={isError ? "red" : "#ccc"} width={field.size?.widthPx}
                    placeholder={field.placeholder} height={field.size!.height!}
                    onChange={handleChange} />
            )
        case FormFieldType.digitsOnly:
            return (
                <RifmNumeric name={field.name!} fieldType={field.type} initialValue={props.inputProps.forms.getValue(props.inputProps.formId, field.name!)}
                    borderColor={isError ? "red" : "#ccc"} width={field.size?.widthPx}
                    placeholder={field.placeholder} height={field.size!.height!} textAlign={"left"}
                    onChange={handleChange} />
            )
    }
    return null;

}
//-------------------------------------------------------
const ColorInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`
const CurrColorBoxAndLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const CurrColorBox = styled.div<{ $backColor: string }>`
    width: 40px;
    height: 18px;
    background-color: ${props => props.$backColor};
    border: 1px solid black;
`
const CurrColorLabel = styled.p`
    margin: 0;
    margin-left: 8px;
`
const ClickToChangeText = styled.p`
    margin: 0;
    font-size: 12px;
`
interface ColorInputProps {
    initialColor: string;
    colorSubmitted: (color: string) => void;
}
const ColorInput: React.FC<ColorInputProps> = (props) => {
    const [pickerShown, setPickerShown] = React.useState(false);

    const colorClicked = () => {
        setPickerShown(true);
    }
    const colorSubmitted = (color: CssColor | null) => {
        setPickerShown(false);
        if (color) {
            props.colorSubmitted(color.toRgbString());
        }
    }

    const initialColor = new CssColor(props.initialColor);

    return (
        <>
            <ColorInputContainer onClick={colorClicked}>
                <CurrColorBoxAndLabel>
                    <CurrColorBox $backColor={props.initialColor} />
                    <CurrColorLabel>{initialColor.toHexString()}</CurrColorLabel>
                </CurrColorBoxAndLabel>
                <ClickToChangeText>(click to change)</ClickToChangeText>
            </ColorInputContainer>
            {pickerShown && <ColorfulPicker color={props.initialColor} colorSubmitted={colorSubmitted} />}
        </>
    )
}

export default SimpleHzForm;
