import React from 'react';

import MasterPage from './MasterPage';
import ProductEditor from './ProductEditor';
import ProductsGrid from './ProductsGrid';

const Products: React.FC = () => {
    const [editingSku, setEditingSku] = React.useState<string>();

    return (
        <MasterPage>
            {editingSku ? (
                <ProductEditor sku={editingSku} formSubmitted={() => setEditingSku(undefined)} />
            ) : (
                <ProductsGrid skuSelected={setEditingSku} />
            )}
        </MasterPage>
    )
}
export default Products;