import React from 'react';
import styled from 'styled-components';

import SamImage, { AttributeLocationEnum, ImageChooser, ImageRect } from './SamImageV3';
import { reorgArray } from './dashboard-support';
import { copyArrayWithUpdatedElement } from '../libSupport';

import { GraphicDimensionType, ImageEditorOptions, ImageRecord, ImageUploadOptions, RowState } from '../../interfaces/lib-api-interfaces';

// #region SHOW LIST OF IMAGES AS GRID
// allow for drag to change order, append new image from file system, append video from url
/* example render with all possible props:
<SamImageGrid id="products" images={imageList} imageBoxWidth={300} direction="row" attributesPosition={AttributesPosition.right} size={200}
    customButtons={{ [{ id: "submit", caption: "Submit"] }} customButtonClicked={buttonClicked} style={{ marginLeft: "8px" }} fontSize={13} onChange={imagesChanged}
    editOptions={{ verifyUrlApiUrl: api.verifyUrl, captions: CaptionOptionsEnum.allow, captionFormat: CaptionFormatEnum.html, allowResize: true, allowFloat: false }}
    uploadOptions={{ targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage }}
    fileOptions={{ graphicsSubfolder: "other", sizeDesignator: ImageSizeEnum.full, size: 500, dimension: GraphicDimensionType.width }}
/>
*/
// the following is for tracking sizes of images in the grid and making sure all sizes on the flex direction dimension are uniform
interface ImageSizeRecord {
    index: number;
    location: number;        // if direction="row" this is top else left
    size: { width: number; height: number };
    setSize: (newWidth: number, newHeight: number) => void;        // tell image display component to resize
}
const ImageGridContainer = styled.div<{ $direction: string; $fontSize: number }>`
    display: flex;
    justify-content: center;
    flex-direction: ${props => props.$direction};
    font-size: ${props => props.$fontSize}px;
    flex-wrap: wrap;
    align-items: flex-start;
`
// direction determines image/attribute placement:
//      "row" puts image above attributes (i.e., left to right)
//      "column" puts image to left of attributes (i.e., top to bottom)
interface ImageGridProps {
    id: string;                 // must be unique if more than one set of images on page
    images: ImageRecord[];      // filenames should not have _f or _m inserted; it is inserted based on fileOptions.sizeDesignator
    maxImages?: number;         // undefined or 0 means no max; note that passing in more images than this does not truncate images; it just does not allow adding more
    // following is height of each ROW or width of each COLUMN
    maxDisplayImageSize?: number;      // defaults to 150px; if direction is column this is width of component; if direction is row this is width of each element
    maxEditorSize?: number;     // width or height of image+attributes, default to 600px
    imageOptions: ImageEditorOptions;
    uploadOptions?: ImageUploadOptions;
    direction?: string;  // row or column (default row)
    style?: Record<string, any>;
    fontSize?: number;  // defaults to 13px
    onChange: (images: ImageRecord[], id: string) => void;
    renameOnUpload?: (file: File) => string;
}
const SamImageGrid: React.FC<ImageGridProps> = (props) => {
    const [imageSizes, setImageSizes] = React.useState<ImageSizeRecord[]>([]);

    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const fontSize = props.fontSize ?? 13;
    const direction = props.direction ?? "row";
    const maxDisplayImageSize = props.maxDisplayImageSize ?? 150;
    let editorHeight: number | undefined;
    let editorWidth: number | undefined;
    if (direction === "row") {
        editorWidth = props.maxEditorSize;
    } else {
        editorHeight = props.maxEditorSize;
    }

    const imageLoaded = (imageId: string | undefined, imageRect: ImageRect, setSize: (newWidth: number, newHeight: number) => void) => {
        //    console.log("sizeImage:", imageRect)
        setImageSizes(sizes => [...sizes,
        {
            index: imageId ? parseInt(imageId) : 0,
            location: direction === "row" ? imageRect.parentTop : imageRect.parentLeft,
            size: { width: imageRect.width, height: imageRect.height },
            setSize
        }]);
    }

    React.useEffect(() => {
        if (imageSizes.length === props.images.length - 1) {
            //          console.log("setting image sizes:", imageSizes);
            const maxes: Record<number, number> = {};           // { [location]: maxSize, [location]: maxSize ... }
            for (const imageSize of imageSizes) {
                const size = direction === "row" ? imageSize.size.height : imageSize.size.width;
                if (imageSize.location in maxes) {
                    maxes[imageSize.location] = Math.max(maxes[imageSize.location], size);
                } else {
                    maxes[imageSize.location] = size;
                }
            }
            //          console.log("maxes:", maxes)

            for (const imageSize of imageSizes) {
                let newWidth: number;
                let newHeight: number;
                if (direction === "row") {
                    newHeight = maxes[imageSize.location];
                    newWidth = imageSize.size.width;
                } else {
                    newHeight = imageSize.size.height;
                    newWidth = maxes[imageSize.location];
                }
                imageSize.setSize(newWidth, newHeight);
            }
        }

    }, [imageSizes]);


    //---- DRAG/DROP IMAGE TO CHANGE ORDER -------------
    // data is index of image being dropped onto "new image" area
    const imageDroppedAtEnd = (data: string) => {
        handleDrop(parseInt(data), props.images.length);
    }
    // move target to source and renumber
    const handleDrop = (source: number, target: number) => {
        props.onChange(reorgArray<ImageRecord>(props.images, source, target, true, true), props.id);
    }
    //----------------------------------------------------------------------

    //------- EDIT OR DELETE IMAGE --------------------------------------
    const handleChange = (newImage: ImageRecord, imageId?: string) => {
        const imageIndex = parseInt(imageId!);
        props.onChange(copyArrayWithUpdatedElement(props.images, newImage, imageIndex!), props.id);
    }
    const handleDelete = (imageId?: string) => {
        const imageIndex = parseInt(imageId!);
        const newImages = props.images.map((image, index) => {
            const returned = { ...image };
            if (index === imageIndex) {
                returned.rowState = RowState.deleted;
            }
            return returned;
        });
        props.onChange(newImages, props.id);
    }
    //----------------------------------------------------------------------

    //--------- INSERT OR APPEND NEW IMAGE/VIDEO -------------
    // image selected from file system
    const newImageSelected = (image: ImageRecord | null) => {
        //    console.log("newImageSelected:", image)
        if (image) {
            if (image.file) {
                handleAddVideo(image);
            } else {
                insertImage(image, -1);
            }
        }
    }
    // insert new image from file system; target = -1 to add to end
    // (currently we only support adding new images to end)
    const insertImage = (newImage: ImageRecord, target: number) => {
        const newImages: ImageRecord[] = [];
        console.warn("insertImage:", newImage)
        if (props.imageOptions.fileOptions?.dimension) {
            newImage.dimension = props.imageOptions.fileOptions?.dimension;
        }
        if (props.imageOptions.fileOptions?.size) {
            newImage.size = props.imageOptions.fileOptions.size;
        }
        for (let i = 0; i < props.images.length; i++) {
            if (i === target) {
                newImages.push(newImage);
            }
            newImages.push({ ...props.images[i] });
        };
        if (target === -1 || target === props.images.length) {
            newImages.push(newImage);
        }
        console.warn("props.onChange:", newImages)
        props.onChange(newImages, props.id);
    }
    const handleAddVideo = (image: ImageRecord | null) => {
        if (image) {
            image.rowState = RowState.added;
            if (props.imageOptions.fileOptions?.size) {
                image.size = props.imageOptions.fileOptions.size;
            }
            const newImages = props.images.map(image => image);
            newImages.push(image);
            props.onChange(newImages, props.id);
        }
    }
    const maxImages = (props.maxImages ?? 0) ? props.maxImages! : 999999;
    //----------------------------------------------------------------------
    return (
        <ImageGridContainer $direction={direction} $fontSize={fontSize}>
            {props.images.map((image, index) => {
                return (
                    (!image.rowState || (image.rowState & RowState.deleted) === 0) &&
                    <SamImage key={image.filename ? image.filename : image.stream_source} image={image} imageId={index + ''}
                        marginLeft={index && direction === "row" ? 8 : 0}
                        imageOptions={props.imageOptions}
                        attributeLocation={direction === "row" ? AttributeLocationEnum.below : AttributeLocationEnum.right}
                        imageDisplaySize={maxDisplayImageSize}
                        editorWidth={editorWidth}
                        handleDrop={handleDrop}
                        imageLoaded={imageLoaded}
                        onDelete={handleDelete}
                        onAttributesChanged={handleChange}
                    />
                )
            })
            }
            {props.uploadOptions && props.images.length < maxImages &&
                <ImageChooser
                    imageOptions={props.imageOptions}
                    uploadOptions={props.uploadOptions}
                    displaySize={props.maxDisplayImageSize} dimension={GraphicDimensionType.height}
                    onImageSelected={newImageSelected} onImageDropped={imageDroppedAtEnd} renameOnUpload={props.renameOnUpload} />
            }
        </ImageGridContainer>
    )

}
// #endregion SHOW LIST OF IMAGES AS GRID

export default SamImageGrid;