import React from 'react';
import styled from 'styled-components';


import MasterPage from './MasterPage';
import { usePostApi, useFetchApi } from '../libs/useDataApiV2';
import SamGrid from '../libs/dashboard/SamGridV3';
import { useTokens } from '../libs/SamState';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';

import { FormFieldRecord, FormFieldType } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import api from '../api-url';
import { IconButtonAlignEnum } from '../interfaces/lib-react-interfaces';

const TaskRow = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const AdminTasks: React.FC = () => {
    const { post } = usePostApi();
    const { fetch } = useFetchApi();
    const { getToken } = useTokens();
    const [searchText, setSearchText] = React.useState<string>();
    const [finishOrder, setFinishOrder] = React.useState(0);
    const [dataSource, setDataSource] = React.useState<Record<string, any>[]>();
    const searchRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
    const finishOrderRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const columnDefs: FormFieldRecord[] = [
        { type: FormFieldType.text, name: "item_no", label: "Item #" },
        { type: FormFieldType.text, name: "caption", label: "Caption" }
    ];

    React.useEffect(() => {
        if (searchText) {
            post(api.search, { division: app.division, searchText }, result => setDataSource(result), error => alert(error), getToken()!.token);
        }
        if (finishOrder) {
            post(api.finishOrder, { order_id: finishOrder }, () => alert("Order completed"), error => alert(error), getToken()!.token);
            setFinishOrder(0);
        }
    }, [searchText, finishOrder]);

    const searchKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            setSearchText(searchRef.current.value);
        }
    }

    const customFormatter = (dataRow: Record<string, any>, colName: string, value: any): string | null => {
        if (colName === "item_no") {
            return value[0] + "-" + value.slice(1);
        } else {
            return null;
        }
    }
    const reindex = () => {
        fetch(api.createIndex, getToken()!.token, result => alert(result), error => alert(error));
    }
    const rebuildProductUrl = () => {
        fetch(api.rebuildProductUrl, getToken()!.token, result => alert(result), error => alert(error));
    }

    const finishOrderKeyPress = (e: React.KeyboardEvent) => {
        console.log("key=" + e.key)
        if (e.key === 'Enter') {
            setFinishOrder(parseInt(finishOrderRef.current.value));
        }
    }

    const fixMediaValues = () => {
        fetch("/api/fixInfoPageMediaValues", getToken()!.token, () => alert("Values fixed"), error => alert(error));
    }

    return (
        <MasterPage>
            <h2>Fern's Garden Admin</h2>
            <ButtonsRow align={IconButtonAlignEnum.center}>
                <IconButton onClick={reindex} caption="Reindex" icon="fas fa-sync" />
                <IconButton caption="Rebuild product_url" onClick={rebuildProductUrl} />
            </ButtonsRow>
            <TaskRow>
                <input ref={searchRef} size={50} placeholder="Enter search text here" onKeyPress={searchKeyPress} />
            </TaskRow>
            <TaskRow>
                <label>Enter order number to finish (must already have been paid):</label>
                <input ref={finishOrderRef} size={20} placeholder="Order #" onKeyPress={finishOrderKeyPress} />
            </TaskRow>
            <TaskRow>
                <IconButton caption="Fix media values on InfoPage styles" onClick={fixMediaValues} />
            </TaskRow>
            {dataSource &&
                <SamGrid dataSource={dataSource} columnDefs={columnDefs} fixedCols={0} allowEditing={false} customFormatter={customFormatter} />
            }
        </MasterPage>

    )
}
export default AdminTasks;