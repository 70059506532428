import React from 'react';
import styled from 'styled-components';

import { CaptionOptionsEnum, ImageAttributesEditOptions, ImageRecord, ImageSizeEnum } from '../interfaces/lib-api-interfaces';
import SamImageGrid from '../libs/dashboard/SamImageGrid';

const RelatedContainer = styled.div`
    display: flex;
    flex-direction: column;
    h2 {
        margin: 4px;
    }
`
interface RelatedProductEditorProps {
    title: string;
    id: string;
    images: ImageRecord[];
    onChange: (images: ImageRecord[]) => void;
}
const RelatedProductEditor: React.FC<RelatedProductEditorProps> = (props) => {
    const editOptions: ImageAttributesEditOptions = { captions: CaptionOptionsEnum.readOnly };
    return (
        <RelatedContainer>
            <h2>{props.title}</h2>
            <SamImageGrid
                id={props.id} images={props.images} direction="column" 
                onChange={props.onChange}
                imageOptions={{
                    editOptions: { captions: CaptionOptionsEnum.readOnly, allowResize: false, allowFloat: false },
                    fileOptions: { sizeDesignator: ImageSizeEnum.full }
                }}
            />
        </RelatedContainer>
    )
}

export default RelatedProductEditor;
