import React from 'react';
import styled from 'styled-components';

import IconButton, { ButtonsRow } from '../IconButtonV2';
import { useSessionStore, useTokens } from '../SamState';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { genericApiError, useFetchApi, usePostApi } from '../useDataApiV2';
import MasterPage from '../../components/MasterPage';

import app from '../../appData';
import api from '../../api-url';
import SamGrid, { Cell } from './SamGridV3';
import { FormFieldRecord, FormFieldType } from '../../interfaces/lib-api-interfaces';
import { ModalInputString } from '../SamModalV2';

// for use by dashboards for React sites to edit ALL infopage content on the site
// user can select a page and InfoPageEditor is used to edit

// export const infoPagePreviewKey = "infopage";

// note that we must hide the InfoContainer rather than remove from layout, as when it is restored to layout it takes on the original props rather than current state
const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
`

// name is passed as url param; targetDomain must be in appData, uploadImage must be in api.url (if missing images are not allowed)
const InfoPageListEditor: React.FC = () => {
    const [pageList, setPageList] = React.useState<{ name: string; title: string }[]>();
    const [showNewDlg, setShowNewDlg] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();
    const { post } = usePostApi();
    const { fetch } = useFetchApi();
    const { getToken } = useTokens();
    const { getSessionStore, setSessionStore, deleteSessionStore } = useSessionStore();

    const gotPageList = (result: { name: string; title: string }[]) => {
        setPageList(result);
        setLoading(false);
    }
    React.useEffect(() => {
        setLoading(true);
        fetch(api.getInfoPageList, getToken()!.token, gotPageList, () => alert(genericApiError));
    }, []);

    const colDefs: FormFieldRecord[] = [
        { name: "name", label: 'Name' },
        { name: "title", label: "Title" },
    ];
    
    const gridCellClicked = (cell: Cell): boolean => {
        navigate("/infopage-editor", { state: { name: cell.dataRow.name } });
        return false;
    }

    const newPageNameSubmitted = (name: string | null) => {
        setShowNewDlg(false);
        if (name) {
            navigate("/infopage-editor", { state: { name }});
        }
    }

    return (
        <MasterPage>
            {(loading) && <Spinner />}
            {pageList &&
                <GridContainer>
                    <p>Click on a row to choose page or <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => setShowNewDlg(true)}>click here to create a new page</span></p>
                    <SamGrid columnDefs={colDefs} dataSource={pageList} allowEditing={false} allowInsert={false} isControlled={true}
                        gridStyles={{ rowHeight: 24, rowHoverBackColor: "yellow" }}
                        cellClicked={gridCellClicked} />
                </GridContainer>
            }
            {showNewDlg && <ModalInputString caption="Enter new page name:" onSubmit={newPageNameSubmitted} />}
        </MasterPage>
    )
}
export default InfoPageListEditor;