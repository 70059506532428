import React, { JSXElementConstructor, PropsWithChildren, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { IconButtonAlignEnum, IconButtonV2Props } from '../interfaces/lib-react-interfaces';

import app from '../appData';

export const setButtonMarginLeft = (button: IconButtonV2Props, margin: number) => {
    if (button.style) {
        if (!button.style.leftMargin) {
            button.style.marginLeft = "16px";
        }
    } else {
        button.style = { marginLeft: "16px" };
    }
}

const StyledButtonsRow = styled.div<{ $marginTop: number; $marginBottom: number; $marginRight?: number; $height: number; $justify: string; $backgroundColor?: string }>`
    margin-top: ${props => props.$marginTop}px;
    margin-bottom: ${props => props.$marginBottom}px;
    margin-right: ${props => props.$marginRight}px;
    height: ${props => props.$height}px;
    display: flex;
    justify-content: ${props => props.$justify};
    align-items: center;
    background-color: ${props => props.$backgroundColor};
`
interface ButtonsRowProps extends PropsWithChildren<{}> {
    // all numbers are em
    autoStyleButtons?: boolean;  // forces buttons height, font size, etc. to be based on given height (or default)
    height?: number;        // default: 40
    marginTop?: number;     // default: 16
    marginBottom?: number;  // default: 16
    marginRight?: number;   // default: 0
    spaceBetween?: number;  // default 8px
    align?: IconButtonAlignEnum;    // default: center
    backgroundColor?: string;
}
// note: marginLeft on button styles is overridden by spaceBetween
// autoStyleButtons overrides font size, padding and button heights
export const ButtonsRow: React.FC<ButtonsRowProps> = (props) => {
    const height = props.height ?? 40;
    const marginTop = props.marginTop ?? 16;
    const marginBottom = props.marginBottom ?? 16;
    const spaceBetween = props.spaceBetween ?? 8;
    let justify = "center";
    if (props.align) {
        if (props.align == IconButtonAlignEnum.left) {
            justify = "flex-start";
        } else if (props.align === IconButtonAlignEnum.right) {
            justify = "flex-end";
        } else if (props.align === IconButtonAlignEnum.spaceBetween) {
            justify = "space-between";
        }
    }

    const fontSize = props.autoStyleButtons ? (height * .4) : undefined;

    if (!props.children) {
        return null;
    }
    let firstFlag = true;
    return (
        <StyledButtonsRow $height={height} $marginTop={marginTop} $marginBottom={marginBottom} $marginRight={props.marginRight} $justify={justify} $backgroundColor={props.backgroundColor}>
            {React.Children.map(props.children as any, (child: ReactElement<any, string | JSXElementConstructor<any>>, index) => {
                if (!child) {
                    return null;
                }
                const marginLeft = firstFlag ? 0 : spaceBetween;
                firstFlag = false;
                return (
                    React.cloneElement(child, { fontSize, marginLeft })
                )
            })
            }
        </StyledButtonsRow>
    )
}
//----------------------------------------------------------------------------
// if props.style/props.hoverStyle not passed and app.themes.buttonStyles or app.themes.buttonHoverStyles exists, they are used
const StyledButton = styled.button<{ $btnCursor: string }>`
    cursor: ${props => props.$btnCursor};        /* to support disabled button */
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        display: inline;
    }
`
const IconButton: React.FC<IconButtonV2Props> = (props) => {
    const [hovering, setHovering] = React.useState(false);

    const defaultColor = ("color" in app.themes) ? app.themes.color as string : '';
    const style: Record<string, string> = {
        fontSize: "14px",
        padding: "4px 8px",
        border: "1px solid " + defaultColor,
        borderRadius: "15px",
        fontWeight: "bold",
        color: defaultColor,
        backgroundColor: app.themes.backColor25 ?? ''
    };
    if ("buttonStyles" in app.themes) {
        for (const prop in (app.themes.buttonStyles as Record<string, any>)) {
            style[prop] = (app.themes.buttonStyles as Record<string, any>)[prop];
        }
    }
    let styleOverride = props.style ?? (app.themes as any).buttonStyles;
    if (styleOverride) {
        for (const prop in styleOverride) {
            style[prop] = styleOverride[prop];
        }
    }
    if (props.fontSizeOverride) {
        style.fontSize = props.fontSizeOverride + "px";
        style.padding = (.29 * props.fontSizeOverride) + "px " + (.58 * props.fontSizeOverride) + "px";
    }
    if (props.marginLeft !== undefined) {
        style.marginLeft = props.marginLeft + "px";
    }
    const hoverStyle: Record<string, string> = {
        ...style,
        color: style.backgroundColor,
        backgroundColor: style.color
    };
    const hoverOverride = props.hoverStyle ?? (app.themes as any).buttonHoverStyles;
    if (hoverOverride) {
        for (const prop in hoverOverride) {
            hoverStyle[prop] = hoverOverride[prop];
        }
    }
    if (props.isDisabled) {
        style.color = "ghostwhite";
        style.backgroundColor = "lightgray";
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!props.isDisabled && props.onClick) {
            props.onClick(e);
        }
    }
    return (
        <StyledButton onClick={handleClick} $btnCursor={props.isDisabled ? "default" : "pointer"} id={props.id} style={hovering && !props.isDisabled ? hoverStyle : style}
            onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            {props.icon ? (
                <Icon {...props} />
            ) : (
                props.caption
            )}
        </StyledButton>
    );
}
// onClick={props.isDisabled ? null : props.onClick}
interface IconProps {
    iconPosition?: IconButtonAlignEnum;     // only left and right allowed
    caption: string;
    icon?: string;
}
const Icon: React.FC<IconProps> = (props) => {
    return (
        props.iconPosition === IconButtonAlignEnum.right ? (
            <React.Fragment>
                {props.caption}&nbsp;
                <i className={props.icon}></i>
            </React.Fragment>
        ) : (
            <React.Fragment>
                {props.icon!.startsWith("/graphics") || props.icon!.startsWith("http") ? (
                    <img src={props.icon} />
                ) : (
                    <i className={props.icon}></i>
                )}
                &nbsp; {props.caption}
            </React.Fragment>
        )
    );
}
export default IconButton;
