import React from 'react';
import styled from 'styled-components';

import IconButton, { ButtonsRow } from '../IconButtonV2';
import HtmlDomEditor, { HtmlDomEditorApi } from './HtmlDomEditor/HtmlDomEditor';
import { useTokens } from '../SamState';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { genericApiError, useFetchApi, usePostApi } from '../useDataApiV2';
import MasterPage from '../../components/MasterPage';
import SaveAsBox from '../forms/SaveAsBox';
import InfoPageStyles from '../InfoPageStyles';
import { InfoPageSettingsEditorModal } from './InfoPageSettingsEditor';

import { ImageUploadOptions, InfoPageApiRecord, InfoPageEditorRouterState, InfoPageSettingsRecord } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';
import api from '../../api-url';

// edit a single infoPage
// page source is passed in via router state
// if page name is passed, it is loaded from api
// OR entire page can be passed 

// interface InfoPageEditorRouterState {{name?: string, page?: InfoPageApiRecord }}      // pass only one
// navigate('/infopage-editor', { state: InfoPageEditorRouterState });
//export const infoPagePreviewKey = "infopage";

// note that we must hide the InfoContainer rather than remove from layout, as when it is restored to layout it takes on the original props rather than current state
const InfoContainer = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
`
const ControlPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: beige;
    margin-top: 16px;
`
const NameSpan = styled.span`
    margin-left: 4px;
    font-weight: bold;
`
// name is passed as url param; targetDomain must be in appData, uploadImage must be in api.url (if missing images are not allowed)
const InfoPageEditor: React.FC = () => {
    const [editor, setEditor] = React.useState<HtmlDomEditorApi>();
    const [pageName, setPageName] = React.useState<string>();
    const [title, setTitle] = React.useState<string>();
    const [editingSettings, setEditingSettings] = React.useState(false);
    const [postingInfoPage, setPostingInfoPage] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();
    const location = useLocation();     // location.state.name or location.state.page will be valid
    const { post } = usePostApi();
    const { fetch } = useFetchApi();
    const { getToken } = useTokens();
    //  const { getSessionStore, setSessionStore, deleteSessionStore } = useSessionStore();

    // this is where new pages get their initial defaults; from here on styles and image options are loaded from DB
    const gotInfoPage = (result: InfoPageApiRecord) => {
        setLoading(false);
        if (result.settings.name === "default") {
            result.settings.styles = InfoPageStyles.globalStylesDefaultValues();
        }
        setTitle(result.title);
        setPageName(result.name);
        editor!.setInfoPage(result);
    }

    React.useEffect(() => {
        if (editor) {
            const routerState = location.state as InfoPageEditorRouterState;
            if (routerState.page) {
                gotInfoPage(routerState.page);
            } else if (routerState.name) {
                fetch(api.getInfoPage + routerState.name, null, gotInfoPage, () => alert(genericApiError));
            } else {
                throw "InfoPageEditor requires name or page";
            }
        }
    }, [editor]);

    const postInfoPageSucceeded = () => {
        setPostingInfoPage(false);
        alert("Page is live on web site");
    }
    const postInfoPageFailed = (err: string) => {
        setPostingInfoPage(false);
        alert(err);
    }

    React.useEffect(() => {
        if (postingInfoPage) {
            post(api.setInfoPage, assembleInfoPage(), postInfoPageSucceeded, postInfoPageFailed, getToken()!.token);
        }
    }, [postingInfoPage]);

    const assembleInfoPage = (): InfoPageApiRecord => {
        // if editor has not yet been initialized we just need dummy info; real info will be loaded after editor initialized and api call made
        let content: string;
        let settings: InfoPageSettingsRecord;
        if (editor) {
            content = editor.getHtml();
            settings = editor.getSettings();
        } else {
            content = '';
            settings = {};
        }
     //   console.log("ASSEMBLEInfoPage returning content:", content)
        return { name: pageName, title, content, settings };
    }

    const handlePreview = () => {
        navigate("/infopage-preview", { state: { page: assembleInfoPage() } });
    }

    const imageUploadOptions: ImageUploadOptions = {
        targetDomain: app.targetDomain,
        uploadImageApiUrl: api.uploadImage
    }

    const handleSubmitInfoPage = () => {
        setPostingInfoPage(true);
    }

    const saveAsSubmitted = (name: string) => {
        setPageName(name);
        setPostingInfoPage(true);
    }

    const settingsSubmitted = (settings: InfoPageSettingsRecord | null) => {
        setEditingSettings(false);
        if (settings) {
            editor!.setSettings(settings);
        }
    }

    return (
        <MasterPage>
            {(loading || postingInfoPage) && <Spinner />}
            <InfoContainer>
                <ControlPanel>
                    {editor &&
                        <ButtonsRow height={34} marginTop={16} marginBottom={0}>
                            <p>Page name:<NameSpan>{pageName}</NameSpan></p>
                            <p style={{ marginLeft: "20px" }}>Settings name:<NameSpan>{editor.getSettings().name}</NameSpan></p>
                            <IconButton caption="Edit settings" onClick={() => setEditingSettings(true)} />
                        </ButtonsRow>
                    }
                    <ButtonsRow>
                        <IconButton caption="Preview" icon="fa fa-file-text-o" onClick={handlePreview} />
                        <IconButton caption="Save" icon="fas fa-check" onClick={handleSubmitInfoPage} />
                        <SaveAsBox onSubmit={saveAsSubmitted} />
                        <IconButton caption="Cancel" icon="fas fa-ban" onClick={() => gotInfoPage(assembleInfoPage())} />
                    </ButtonsRow>
                </ControlPanel>
                <HtmlDomEditor infoPage={assembleInfoPage()} imageUploadOptions={imageUploadOptions.uploadImageApiUrl ? imageUploadOptions : undefined}
                    getTitle={() => title ?? ''} setTitle={setTitle} isDebugging={true} editorInitialized={editor => { console.log("editorInitialized"); setEditor(editor) }}
                     />
            </InfoContainer>
            {editingSettings && <InfoPageSettingsEditorModal initialName={editor!.getSettings()!.name} infoPageName={pageName} onSubmit={settingsSubmitted} />}
        </MasterPage >
    )
}
export default InfoPageEditor;