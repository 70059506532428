import app from './appData';

const dashboardLogin = "/api/dashboardLogin/fernsgarden";
const validateToken = "/api/validateToken";

const getHomePage = "/api/fgGetHomePage";
const saveHomePage = "/api/fgSaveHomePage";
const getInfoContent = "/api/getInfoContent/"; // add division and (page url OR section code to get index of section)
const setInfoContent = "/api/setInfoContent";
const setInfoDisplayOrder = "/api/setInfoDisplayOrder";
const getDashboardProducts = "/api/fgGetDashboardProducts";   // + query;
const searchDashboardProducts = "/api/fgDashboardProductSearch";
const loadDashboardSku = "/api/fgDashboardLoadSku/";     // + loadSku
const getDashboardCategories = "/api/fgGetDashboardCategories";
const search = "/api/wsSearch";
const createIndex = "/api/fgCreateIndex";
const rebuildProductUrl = "/api/fgRebuildProductUrl";

const fetchData = "/api/loadTable/";     // + props.table + "/" + props.division;
const fetchSchema = "/api/getSchema/";       // + props.table;
const postData = "/api/updateTable";
const saveProduct = "/api/fgSaveProduct";
const saveCategories = "/api/fgSaveCategories";
const verifyUrl = "/api/fgVerifyUrl/";
const getTextContent = "/api/getTextContent";      // + ?d=target-domain u=url (domain is uri endoded)
const setTextContent = "/api/setTextContent";       // { domain: string; url: string; text: string }
const getCommonInfo = "/api/getCommonInfo";     // d=division, u=url(s)
const setCommonInfo = "/api/setCommonInfo"; 

const uploadImage = "/api/uploadImage";
const uploadImagesFromUrls = "/api/uploadImagesFromUrls";

// infoPage
const getInfoPageList = "/api/getInfoPageList/" + app.targetDomain;
const getInfoPage = "/api/getInfoPage/" + app.targetDomain + "/";       // + name
const setInfoPage = "/api/setInfoPage";
const getInfoPageSettings = "/api/getInfoPageSettings/" + app.targetDomain + "/";   // + name
const setInfoPageSettings = "/api/setInfoPageSettings";
const getInfoPageSettingsNames = "/api/getInfoPageSettingsNames/" + app.targetDomain;

const getLegacyInfoContent = "/api/getLegacyInfoContent/"; // add division and (page url OR section code to get index of section)

const finishOrder = "/api/fgFinishOrderById";

export default {
    validateToken,
    dashboardLogin,

    getHomePage,
    saveHomePage,
    getInfoContent,
    setInfoContent,
    setInfoDisplayOrder,
    getDashboardProducts,
    searchDashboardProducts,
    loadDashboardSku,
    getDashboardCategories,
    search,
    createIndex,
    rebuildProductUrl,
    fetchData,
    fetchSchema,
    postData,
    saveProduct,
    saveCategories,
    verifyUrl,
    getTextContent,
    setTextContent,
    getCommonInfo,
    setCommonInfo,

    getInfoPageList,
    getInfoPageSettingsNames,
    getInfoPage,
    setInfoPage,
    getInfoPageSettings,
    setInfoPageSettings,

    uploadImage,
    uploadImagesFromUrls,

    getLegacyInfoContent,

    finishOrder

}