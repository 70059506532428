import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';

import { getNearestParentId } from '../libSupport';
import IconButton, { ButtonsRow } from '../IconButtonV2';
import { usePostApi } from '../useDataApiV2';
import { useTokens } from '../SamState';
import Spinner from '../Spinner';
import CloseDialogBar from '../CloseDialogBar';
import SamModal, { ModalMessageBox, ModalMessageResponseFlags } from '../SamModalV2';
import RifmNumeric from '../forms/RifmNumeric';
import {
    createIframeFromImage, formatImageOrVideoSrcWithFile, formatImageUrl, formatVimeoApiUrl, formatYoutubeThumbUrl, graphicFloatTypeToString, videoHeightFactor
} from '../ImageFormatter';
import HtmlDomEditor from './HtmlDomEditor/HtmlDomEditor';
import { ToolCodeEnum } from './HtmlDomEditor/StyleMgr';

import {
    CaptionOptionsEnum, FormFieldType, GraphicDimensionType, GraphicFloatType, ImageAttributesEditOptions, ImageDragType,
    ImageEditorOptions, ImageFileOptions, ImageRecord, ImageUploadOptions, StyleRecord, VideoStreamSource
} from '../../interfaces/lib-api-interfaces';

import app from '../../appData';


/* from lib-api-interfaces.ts:
export interface ImageRecord {
    caption?: string;
    filename?: string;
    youtube_id?: string;
    blob?: string;
    url?: string;       // link
    tag?: string;       // displayed in bold (useful for SKU #)
    // following valid if image has been dragged or chosen from local filesystem
    file?: any;     // any is for Node; should be cast to File for React code 
    display_order?: number;
    size?: number; 
    dimension?: GraphicDimensionType; 
    float?: GraphicFloatType;   // currently used only for main image in info pages (about us needs to be wrapped right)
    rowState?: RowState;    // for ImageListHandler; caller should pass this as unchanged
    size_pct?: number;      // percentage to display image in apps; used for small items in fernsgarden.com
    embed_text?: string;    // youtube output text; for editing videos in dashboard
    alt?: string;
}
export enum CaptionOptionsEnum { allow = 'A', disallow = 'D', readOnly = 'R' }
export interface ImageEditorOptions {
    allowVideos?: boolean;
    uploadOptions?: ImageUploadOptions;            // images are disallowed if this is not given; next 2 also required
    editOptions?: ImageAttributesEditOptions;      // ditto
    fileOptions?: ImageFileOptions;                // ditto
}
export interface ImageAttributesEditOptions {
    allowLink?: boolean;
    verifyUrlApiUrl?: string; 
    captions: CaptionOptionsEnum;
    //   captionFormat?: CaptionFormatEnum;
    allowResize?: boolean;
    allowFloat?: boolean;       // aka "wrap"
}
// following used to display images, also for uploading
export interface ImageFileOptions {
    graphicsSubfolder?: string;      // folder under "graphics": "other", "blog", etc.
    isCdn?: boolean;                // overrides target domain to save file on https://artthisdesign.com/cdn; domain becomes the folder under cdn
    sizeDesignator?: ImageSizeEnum;     // full or magnified if image is stored in 2 versions
    size?: number;                   // default; overridden by size prop in ImageRecord; default to 500
    dimension?: GraphicDimensionType;    // ditto; default to width
}
export interface ImageUploadOptions {
    // following must be passed to enable choosing new image from file system; if not passed new images will not be allowed
    targetDomain: string;           // if isCdn true this is the directory below artthisdesign.com/cdn; else "/graphics" is appended to this domain name
    uploadImageApiUrl: string;
}
*/

/*
How to show/edit the following attributes:
(to track any editing changes pass onAttributesChanged)
(to track deletes pass onDelete)
    Delete button: pass onDelete()
    Tag (e.g., SKU #): pass image.tag
    Captions: imageOptions.editOptions.captions determines whether they are shown and are editable
    Float dropdown: imageOptions.editOptions.allowFloat
    Sizer input box and editor: imageOptions.editorOptions.allowResize
    Link url input box: imageOptions.editorOptions.allowLink
    "OK" and "Cancel" buttons at bottom: pass onSubmit and/or onCancel
*/

// following is passed to callback passed as props.imageLoaded
export interface ImageRect {
    width: number;
    height: number;
    parentTop: number;
    parentLeft: number;
}
// caller can store the setSize calls and use them to change sizes of all images in a grid, e.g. to make them consistent heights or widths
export type ImageLoadedCallback = (imageId: string | undefined, imageRect: ImageRect, setSize: (newWidth: number, newHeight: number) => void) => void;

// #region VIEW AND EDIT ONE IMAGE
// viewer can be part of a grid; when "Edit" button is pressed a modal is shown to allow editing of all attributes
// the modal editor is also called when a new image is chosen or dropped from Chooser
export enum AttributeLocationEnum { below = 'B', right = 'R' }
// note that width OR height will be "auto"
const ImageWithAttributesContainer = styled.div<{ $attributeLocation: AttributeLocationEnum; $marginLeft: number; $width: number }>`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => props.$attributeLocation === AttributeLocationEnum.below ? "column" : "row"};
    margin-left: ${props => props.$marginLeft}px;
    width: ${props => props.$width}px;
    margin-top: 8px;
    padding: 8px;
    border: 1px solid;
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
interface SamImageProps {
    image: ImageRecord;
    imageOptions: ImageEditorOptions;       // supports uploading, editing and displaying
    imageId?: string;         // used when shown from grid
    marginLeft?: number;    // px
    suppressContextMenu?: boolean;      // for use with right click to edit image
    attributeLocation?: AttributeLocationEnum;   // default to below
    imageDisplaySize?: number;     // fit to longest image dimension; default to 150px
    editorWidth?: number;        // default to 600px
    onCloseModal?: () => void;      // if passed the component is shown as modal and this is called when close bar (X) is clicked
    onDelete?: (imageId?: string) => void;      // pass to show "Delete" button
    onSubmit?: (image: ImageRecord, imageId?: string) => void;     // pass to show "OK" button
    onCancel?: (imageId?: string) => void;      // pass to show "Cancel" button
    onAttributesChanged?: (image: ImageRecord, imageId?: string) => void;     // pass to monitor when any attribute changes; use with onSubmit or not
    handleDrop?: (sourceIndex: number, targetIndex: number) => void;       // used only by SamImageGrid; if not given drag/drop not supported
    imageLoaded?: ImageLoadedCallback;          // for getting image size and changing it if desired
}
const SamImage: React.FC<SamImageProps> = (props) => {
    if (props.onCloseModal) {
        return (
            <SamModal>
                <SamImageNonModal {...props} />
            </SamModal>
        )
    }
    return (
        <SamImageNonModal {...props} />
    )
}
const SamImageNonModal: React.FC<SamImageProps> = (props) => {
    const attributeLocation = props.attributeLocation ?? AttributeLocationEnum.below;

    const imageDisplaySize = props.imageDisplaySize ?? 150;
    const width = props.editorWidth ?? 400;


    React.useEffect(() => {
        if (props.image.stream_id) {
            // this is video so create an iframe for it
            const video = { ...props.image, size: width * 0.9 };
            const editor = document.getElementById(props.image.stream_id);
            const iframe = createIframeFromImage(video);
            editor!.parentElement!.insertBefore(iframe, editor);
        }
    }, []);

    const handleContextMenu = (e: React.MouseEvent) => {
        if (props.suppressContextMenu) {
            e.preventDefault();
        }
    }


    return (
        <ImageWithAttributesContainer $attributeLocation={attributeLocation} $marginLeft={props.marginLeft ?? 0} $width={width} onContextMenu={handleContextMenu}>
            {props.onCloseModal && <CloseDialogBar onClose={props.onCloseModal} />}
            {!props.image.stream_id &&
                <ImageContainer>
                    <SizedImage imageId={props.imageId} imageDisplaySize={imageDisplaySize} handleDrop={props.handleDrop} imageLoaded={props.imageLoaded}
                        image={props.image} fileOptions={props.imageOptions.fileOptions} />
                </ImageContainer>
            }
            <ViewAndEditAttributes imageId={props.imageId} image={props.image} imageOptions={props.imageOptions} marginLeft={attributeLocation === AttributeLocationEnum.right ? 16 : 0}
                onAttributesChanged={props.onAttributesChanged} onSubmit={props.onSubmit} onCancel={props.onCancel} onDelete={props.onDelete} />
        </ImageWithAttributesContainer>
    )
}

//--------- ViewAndEditAttributes --------------------------
// does NOT show image; only attributes

// attributeLocation determines whether margin is allowed on top or left
const ViewAndEditAttributesContainer = styled.div<{ $marginLeft: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: ${props => props.$marginLeft}px;
`
const TagText = styled.p`
    font-weight: bold;
    margin: 0;
`
const TextRow = styled.div`
    text-align: left;
    margin-bottom: 12px;
    width: 100%;
`
const LabelText = styled.span`
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    margin-left: 4px;
`
const CaptionBox = styled.div<{ $border: boolean }>`
    border: ${props => props.$border ? "1px solid" : "none"};
`
// for attributes to be editable, attributesChanged OR onSubmit must be passed (or both)
interface ViewAndEditAttributesProps {
    image: ImageRecord;
    imageId?: string;       // used as element id so parent can locate this component (used to insert video iframe)
    imageOptions: ImageEditorOptions;       // supports uploading, editing and displaying
    marginLeft?: number;
    onDelete?: (imageId?: string) => void;
    onAttributesChanged?: (newImage: ImageRecord, imageId?: string) => void;        // if not passed the attributes are read only
    onSubmit?: (image: ImageRecord, imageId?: string) => void;          // displays "OK" button
    onCancel?: (imageId?: string) => void;                          // displays "Cancel" button
}
const ViewAndEditAttributes: React.FC<ViewAndEditAttributesProps> = (props) => {
    //   const [editingCaption, setEditingCaption] = React.useState(false);
    const [confirmingDelete, setConfirmingDelete] = React.useState(false);
    const [image, setImage] = React.useState<ImageRecord>();

    const linkRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const readOnly = !props.onAttributesChanged && !props.onSubmit;

    React.useEffect(() => {
        setImage(props.image);
    }, []);

    const updateAttributes = (newImage: ImageRecord) => {
        setImage(newImage);
        props.onAttributesChanged && props.onAttributesChanged(newImage, props.imageId);
    }

    const linkChanged = () => {
        updateAttributes({ ...image, url: linkRef.current.value });
    }
    const sizeChanged = (newSize: number) => {
        updateAttributes({ ...image, size: newSize });
    }
    const floatChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateAttributes({ ...image, float: e.target.value as GraphicFloatType });
    }
    const floatPctChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateAttributes({ ...image, floatPct: parseInt(e.target.value) });
    }
    const captionChanged = (text: string) => {
        updateAttributes({ ...image, caption: text });
    }
    /*
    const captionSubmitted = (caption: string | null) => {
        if (caption !== null) {
            //      console.log("captionSubmitted: " + caption)
            updateAttributes({ ...image, caption: caption ?? undefined });
        }
        setEditingCaption(false);
    }
    */
    const confirmDeleteSubmitted = (confirmed: boolean) => {
        setConfirmingDelete(false);
        if (confirmed) {
            props.onDelete!(props.imageId);
        }
    }

    const float = image && props.imageOptions.editOptions?.allowFloat ? graphicFloatTypeToString(image.float) : '';
    const imageSize = (image?.size ?? props.imageOptions.fileOptions?.size) ?? 500;
    const editableCaption = !readOnly && props.imageOptions.editOptions?.captions === CaptionOptionsEnum.allow;

    return (
        <ViewAndEditAttributesContainer id={props.imageId} $marginLeft={props.marginLeft ?? 0}>
            {image &&
                <>
                    {props.onDelete && (
                        <ButtonsRow>
                            <IconButton caption={"Delete " + (image.stream_id ? "video" : "image")} onClick={() => setConfirmingDelete(true)} />
                        </ButtonsRow>
                    )}
                    {props.image.tag && <TagText>{props.image.tag}</TagText>}
                    {props.imageOptions.editOptions?.captions !== CaptionOptionsEnum.disallow &&
                        <TextRow>
                            <CaptionBox $border={editableCaption}>
                                <LabelText>Caption:<br /></LabelText>
                                {editableCaption ? (
                                    <HtmlDomEditor infoPage={{ content: image.caption ?? '', settings: {} }} widthPct={90}
                                        includedTools={[ToolCodeEnum.bold, ToolCodeEnum.italic, ToolCodeEnum.underline, ToolCodeEnum.link]}
                                        contentChanged={captionChanged} />
                                ) : (
                                    <span dangerouslySetInnerHTML={{ __html: image.caption ?? "(none)" }} />
                                )}
                            </CaptionBox>
                            { /*
                                <i style={{ color: "green", fontSize: "18px", marginLeft: "4px" }} className="fa fa-pencil-square-o" onClick={() => setEditingCaption(true)} />
                                    {props.imageOptions.editOptions?.captions === CaptionOptionsEnum.readOnly ? (
                                <p>{image.caption}</p>
                            ) : (
                                <CaptionTextInput value={image.caption ?? ''} onChange={captionChanged} />
                            )}
                            */}
                        </TextRow>
                    }
                    {props.imageOptions.editOptions?.allowLink &&
                        <>
                            {props.onAttributesChanged ? (
                                <input ref={linkRef} value={image.url} placeholder="Enter link here (http...)" onChange={linkChanged} />
                            ) : (
                                <TextRow>
                                    <LabelText>Link:&nbsp;</LabelText>
                                    <span>{image.url ?? "(none)"}&nbsp;</span>
                                </TextRow>
                            )}
                        </>
                    }
                    {props.imageOptions.editOptions?.allowResize &&
                        <>
                            {!readOnly ? (
                                <ImageSizeForm image={image} fileOptions={props.imageOptions.fileOptions}
                                    size={imageSize}
                                    sizeChanged={sizeChanged} />
                            ) : (
                                <TextRow>
                                    <LabelText>{props.image.dimension === GraphicDimensionType.height ? "Height" : "Width"}:&nbsp;</LabelText>
                                    <span>{imageSize}</span>
                                </TextRow>
                            )}
                        </>
                    }
                    {float &&
                        <TextRow>
                            <LabelText>Wrap:&nbsp;</LabelText>
                            {!readOnly ? (
                                <>
                                    <select style={{ width: "80px" }} onChange={floatChanged} value={image.float ?? GraphicFloatType.none}>
                                        <option value={GraphicFloatType.left}>Left</option>
                                        <option value={GraphicFloatType.right}>Right</option>
                                        <option value={GraphicFloatType.none}>None</option>
                                    </select>
                                    <LabelText>% of width:&nbsp;</LabelText>
                                    <input type="number" value={image.floatPct ?? 50} onChange={floatPctChanged} />
                                </>
                            ) : (
                                <span>{float[0].toUpperCase + float.substring(1) + " at " + (image.floatPct ?? 50) + "%"}</span>
                            )}
                        </TextRow>
                    }
                    {image.filename &&
                        <TextRow>
                            <LabelText>Name:&nbsp;</LabelText>
                            <span>{image.filename}&nbsp;</span>
                        </TextRow>
                    }
                    {image.stream_id &&
                        <TextRow>
                            <LabelText>Video:&nbsp;</LabelText>
                            <span>{(image.stream_source === VideoStreamSource.vimeo ? "Vimeo #" : "YouTube #") + image.stream_id}</span>
                        </TextRow>
                    }
                    {!confirmingDelete && (props.onSubmit || props.onCancel) &&
                        <ButtonsRow>
                            {props.onSubmit &&
                                <IconButton caption="OK" icon="fas fa-check" onClick={() => props.onSubmit!(image, props.imageId)} />
                            }
                            {props.onCancel &&
                                <IconButton caption="Cancel" icon="fas fa-ban" onClick={() => props.onCancel!(props.imageId)} />
                            }
                        </ButtonsRow>
                    }
                    {confirmingDelete && <ConfirmDeleteDlg filename={image.filename ?? "this image"} onSubmit={confirmDeleteSubmitted} />}
                </>}
        </ViewAndEditAttributesContainer>
    )
}
//                     {editingCaption && <CaptionEditor caption={image.caption ?? ''} onSubmit={captionSubmitted} />}
// {(props.imageOptions.editOptions?.captions === CaptionOptionsEnum.allow || props.imageOptions.editOptions?.captions === CaptionOptionsEnum.readOnly) &&
//     <TextRow>
//         <LabelText>Caption:&nbsp;</LabelText>
//         <span dangerouslySetInnerHTML={{ __html: image.caption ?? "(none)" }} />
//         {!readOnly && props.imageOptions.editOptions?.captions !== CaptionOptionsEnum.readOnly &&
//             <i style={{ color: "green", fontSize: "18px", marginLeft: "4px" }} className="fa fa-pencil-square-o" onClick={() => setEditingCaption(true)} />
//         }
//     </TextRow>
// }


//-------------------------------------------------------------
/*
const CaptionEditorContainer = styled.div`
    padding: 16px;
`
interface CaptionEditorProps {
    caption: string;
    onSubmit: (caption: string | null) => void;
}
const CaptionEditor: React.FC<CaptionEditorProps> = (props) => {
    const [editor, setEditor] = React.useState<HtmlDomEditorApi>();
    return (
        <SamModal>
            <CaptionEditorContainer>
                <HtmlDomEditor id="figCaption" infoPage={{ content: props.caption, name: 'caption', domain: app.targetDomain }}
                        domainDefaults={{ domain: app.targetDomain, styles: globalStylesValues() }} 
                        width={600} height={200} editorInitialized={setEditor} />
                <ButtonsRow>
                    <IconButton caption="Submit" onClick={() => props.onSubmit(editor!.getHtml())} />
                    <IconButton caption="Cancel" onClick={() => props.onSubmit(null)} />
                </ButtonsRow>
            </CaptionEditorContainer>
        </SamModal>
    )
}
*/
//-------------------------------------------------------------
const ImageSizeFormContainer = styled.div`
    display: flex;
    margin-bottom: 12px;
`
const SizeInput = styled.input`
    width: 50px;
    height: 20px;
`
interface ImageSizeFormProps {
    image: ImageRecord;             // passed to sizer (not shown in local form)
    fileOptions?: ImageFileOptions;
    size: number;                   // size in fileOptions and image are ignored; this is the SOT
    sizeChanged: (newSize: number) => void;
}
const ImageSizeForm: React.FC<ImageSizeFormProps> = (props) => {
    const [showSizer, setShowSizer] = React.useState(false);
    const [rifmValueCallback, setRifmValueCallback] = React.useState<(value: string) => void>();

    const sizeInputChanged = (newSize: number | string) => {
        props.sizeChanged(newSize as number);
    }
    const onSizerSubmit = (newSize: number | null) => {
        setShowSizer(false);
        if (newSize) {
            rifmValueCallback!(newSize + '');
            props.sizeChanged(newSize);
        }
    }

    return (
        <>
            <ImageSizeFormContainer>
                <LabelText>{props.image.dimension === GraphicDimensionType.height ? "Height" : "Width"}:&nbsp;</LabelText>
                <RifmNumeric name="width" fieldType={FormFieldType.digitsOnly} numericLength={3} initialValue={props.size} width={50} height={20} showChevrons={true}
                    onChange={sizeInputChanged} setValueCallback={callback => setRifmValueCallback(() => callback)} />
                <i style={{ color: "green", fontSize: "24px", marginLeft: "4px", marginTop: "-4px" }} className="fa fa-pencil-square-o" onClick={() => setShowSizer(true)} />
            </ImageSizeFormContainer>
            {showSizer && <ImageSizer image={props.image} fileOptions={props.fileOptions} size={props.size} onSubmit={onSizerSubmit} />}
        </>
    )
}
//                 <SizeInput type="number" value={props.size} onChange={sizeInputChanged} />

/*
  fieldType: FormFieldType;      // int = 'I', money = '$', fixedPoint, phone
  name: string;
  initialValue?: string | number;   // string for phone, else number
  fontSize?: number;     // defaults to 14
  width?: number;     // omit for 100%
  height?: number;      // default to 28
  allowNegative?: boolean;
  allowZero?: boolean;
  decimalPlaces?: number;
  showChevrons?: boolean;
  borderColor?: string;
  placeholder?: string;
  textAlign?: string;   // defaults to "right" on numbers, "left" on phone, but could be passed as "center"
  numericLength?: number;   // required for type=digitsOnly
  onChange?: (value: string | number, name: string) => void;    // value is number except phone is string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
*/
const ImageSizerContainer = styled.div<{ $width: number }>`
    width: ${props => props.$width}px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
`
const ImageSizerImage = styled.img<{ $width: number }>`
    width: ${props => props.$width}px;
    height: auto;
`
const SizeLabel = styled.p`
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
`
/* input value is 1-100 --> % of max image size
    max image size is size coming in plus 20% (minimum max size is 400px)
    image is displayed as pixel width so if dimension is height the display width is multiplied by factor
*/
interface ImageSizerProps {
    image: ImageRecord;
    fileOptions?: ImageFileOptions;
    size: number;                   // size in fileOptions and image are ignored; this is the SOT
    maxDisplaySize?: number;        // default to 800px
    onSubmit: (newSize: number | null) => void;
}
const ImageSizer: React.FC<ImageSizerProps> = (props) => {
    const [size, setSize] = React.useState(props.size);
    const [widthFactor, setWidthFactor] = React.useState(1.0);      // multiply size by this to get width; if dimension is height this will not be 1.0

    const displayWidth = props.maxDisplaySize ?? 800;
    const dimension = props.fileOptions?.dimension ?? GraphicDimensionType.width;
    const maxSize = Math.max(props.size * 1.2, 400);

    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        if (dimension === GraphicDimensionType.height) {
            // factor is no longer 1; need to calculate
            setWidthFactor(target.naturalWidth / target.naturalHeight);
        }
    }

    const sizeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        // value is 0-100 so convert to image size, with range extending up to original plus 20%
        setSize(Math.round(parseInt(e.target.value) / 100 * maxSize));
    }
    return (
        <SamModal>
            <ImageSizerContainer $width={displayWidth}>
                <ImageSizerImage src={formatImageOrVideoSrcWithFile(props.image, props.fileOptions)} $width={Math.max(size * widthFactor, 100)} onLoad={imageLoaded} />
                <input type="range" style={{ width: (displayWidth - 50) + "px" }} value={size / maxSize * 100} onChange={sizeChanged} />
                <SizeLabel>{(dimension === GraphicDimensionType.width ? "Width" : "Height") + " " + size + "px"}</SizeLabel>
                <ButtonsRow>
                    <IconButton caption="Submit" onClick={() => props.onSubmit(size)} />
                    <IconButton caption="Cancel" onClick={() => props.onSubmit(null)} />
                </ButtonsRow>

            </ImageSizerContainer>
        </SamModal>
    )
}
//-------------------------------------------------------------
interface ConfirmDeleteDlgProps {
    filename: string;
    onSubmit: (confirmed: boolean) => void;
}
const ConfirmDeleteDlg: React.FC<ConfirmDeleteDlgProps> = (props) => {
    return (
        <ModalMessageBox caption={"Are you sure you want to remove " + props.filename + "?"}
            responseFlags={ModalMessageResponseFlags.yes | ModalMessageResponseFlags.no}
            onSubmit={result => props.onSubmit(result === ModalMessageResponseFlags.yes)}
        />
    )
}
// #endregion DISPLAY AND EDIT ONE IMAGE

// #region SizedImage: SINGLE IMAGE SUPPORTING DRAG/DROP
// cursor is "pointer" if drag/drop allowed, else "default"
// used to show image in grid or when user clicks "edit" from image viewer or when new image is selected
const Image = styled.img<{ $width: number; $allowDragDrop?: boolean }>`
    width: ${props => props.$width}px;
    height: auto;
    cursor: ${props => props.$allowDragDrop ? "pointer" : "default"};
`
interface SizedImageProps {
    image: ImageRecord;
    fileOptions?: ImageFileOptions;
    imageDisplaySize: number;           // image is resized so this is longest dimension; image takes 90% of box
    imageId?: string;
    handleDrop?: (sourceIndex: number, targetIndex: number) => void;       // used only by SamImageGrid; if not given drag/drop not supported
    // following allows parent to query actual image size after resizing per props.size, and then parent can reset the size after evaluating all images in its row
    imageLoaded?: ImageLoadedCallback;
}
const SizedImage: React.FC<SizedImageProps> = (props) => {
    //   const maxImageSize = props.size ?? 300;
    const [maxHeight, setMaxHeight] = React.useState(0);
    const [maxWidth, setMaxWidth] = React.useState(0);

    // console.log("SizedImage:", {propsSize: props.size, maxWidth, maxHeight });

    // this is called by parent to optionally change size of displayed image (e.g., to make consistent with others in grid)
    const setSize = (newWidth: number, newHeight: number) => {
        //   console.log("SizedImage.setSize:", { propsSize: props.size, newWidth, newHeight });
        setMaxWidth(newWidth);
        setMaxHeight(newHeight);
    }

    React.useEffect(() => {
        if (props.image.stream_id) {
            setMaxWidth(props.imageDisplaySize);
            setMaxHeight(props.imageDisplaySize * videoHeightFactor);
        }
    }, []);

    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (!maxWidth) {
            const target = e.target as HTMLImageElement;
            const maxSize = props.imageDisplaySize;
            const longestDim = Math.max(target.naturalWidth, target.naturalHeight);
            const ratio = maxSize / longestDim;
            const height = ratio * target.naturalHeight;
            const width = ratio * target.naturalWidth;
            setMaxHeight(height);
            setMaxWidth(width);
            if (props.imageLoaded) {
                const parentNode = target.parentElement;
                const parentRect = parentNode!.getBoundingClientRect();
                props.imageLoaded(props.imageId, { width, height: height, parentTop: parentRect.top, parentLeft: parentRect.left }, setSize);
            }
        }
    }

    //-------- DRAG AND DROP (parent must pass handleDrop for this to be active) --------------
    const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
        const id = getNearestParentId(e.target as HTMLImageElement).id;
        e.dataTransfer.setData(ImageDragType, id);
    }
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if (e.dataTransfer.items[i].type === ImageDragType) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const target = parseInt(getNearestParentId(e.target as HTMLImageElement).id);
        {
            props.handleDrop!(parseInt(e.dataTransfer.getData(ImageDragType)), target);
        }
    }
    //-------- END DRAG AND DROP --------------

    return (
        <Image id={props.imageId} src={formatImageOrVideoSrcWithFile(props.image, props.fileOptions)} $width={maxWidth} height={maxHeight}
            draggable={!!props.handleDrop}
            onLoad={imageLoaded}
            onDragStart={props.handleDrop ? handleDragStart : undefined}
            onDragOver={props.handleDrop ? handleDragOver : undefined}
            onDrop={props.handleDrop ? handleDrop : undefined}
        />
    )
}
// #endregion SizedImage: SINGLE IMAGE SUPPORTING DRAG/DROP

// #region DROP OR CHOOSE IMAGE FROM FILE SYSTEM
/* this supports:
        choose image from file system
        drop image from file system
        drop image from grid
*/
const ImageInputContainer = styled.div`
    display: flex;
    margin-left: 8px;
    margin-top: 8px;
    `
// square container holds image "chooser" and video "chooser"
const ImageVideoChooserContainer = styled.div<{ $size: number }>`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: ${props => props.$size}px;
    justify-content: center;
    `
// width is props.size; height is half that if videos allowed
const ImageChooserContainer = styled.div<{ $width: number }>`
    width: 100%;
    height: 100px;
    border: 1px #ccc solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
        margin - left: 8px;
        margin-right: 8px;
    }
`
const VideoChooserContainer = styled.div`
    width 100%;
`
interface ImageChooserProps {
    imageOptions: ImageEditorOptions;       // do not included editOptions here to make attributes read only
    uploadOptions: ImageUploadOptions;
    displaySize?: number;       // for display, NOT uploading (see fileOptions for uploading); default to fileOptions
    dimension?: GraphicDimensionType;    // ditto
    onImageSelected: (image: ImageRecord | null) => void;       // called after user has had chance to edit the attributes (if imageOptions.editOptions passed)
    // to set data using the following, use e.dataTransfer.setData(ImageDragType, id)
    // the following is to allow moving a grid image to the end of the list
    onImageDropped?: (data: string) => void;       // if this is passed, items can be dropped if they have a type of "Imagelist"; the data is the dataTransfer.item.data field
    renameOnUpload?: (file: File) => string;        // allows parent to suggest a filename when image is uploaded to server (api may modify for uniqueness)
    forceFilename?: (file: File) => string;        // allows parent to choose filename when image is uploaded to server (api does not modify; good for testing)
}
export const ImageChooser: React.FC<ImageChooserProps> = (props) => {
    const [imageToUpload, setImageToUpload] = React.useState<ImageRecord>();
    const [imageToEdit, setImageToEdit] = React.useState<ImageRecord>();    // set after image uploaded if editOptions have been passed in props

    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const { post, isPostLoading } = usePostApi();
    const { getToken } = useTokens();

    const displaySize = props.displaySize ?? props.imageOptions.fileOptions?.size!;

    const imageUploaded = (filename: string) => {
        const blob = URL.createObjectURL(imageToUpload!.file!);
        const newImage = { ...imageToUpload, filename, blob };
        setImageToUpload(undefined);
        if (props.imageOptions.editOptions) {
            setImageToEdit(newImage);
        } else {
            //     console.log("calling props.onImageSelected with:", newImage);
            props.onImageSelected(newImage);
        }
    }
    const videoSelected = (image: ImageRecord) => {
        if (props.imageOptions.editOptions) {
            setImageToEdit(image);
        } else {
            props.onImageSelected(image);
        }
    }

    React.useEffect(() => {
        // FOLLOWING MUST AGREE WITH api file graphics.ts, interface FormFields:
        if (imageToUpload) {
            const form = new FormData();
            form.append("file", imageToUpload.file!);
            form.append('size', imageToUpload.size + ' ');
            form.append('dimension', imageToUpload.dimension!);
            form.append('isCdn', props.imageOptions.fileOptions!.isCdn ? 'Y' : 'N');
            form.append('domain', props.uploadOptions!.targetDomain);
            form.append('outputMagnified', props.imageOptions.fileOptions?.sizeDesignator ? 'Y' : 'N');
            if (props.imageOptions.fileOptions?.graphicsSubfolder) {
                form.append('graphics_subfolder', props.imageOptions.fileOptions.graphicsSubfolder);
            }
            if (props.renameOnUpload) {
                form.append("suggested_name", props.renameOnUpload(imageToUpload.file!));
            }
            if (props.forceFilename) {
                form.append("override_name", props.forceFilename(imageToUpload.file!));
            }
            //    console.log("posting form:",
            // {
            //     filename: imageToUpload.file!.name,
            //     size: imageToUpload.size,
            //     dimension: imageToUpload.dimension!,
            //     isCdn: props.imageOptions.fileOptions!.isCdn ? 'Y' : 'N',
            //     domain: props.imageOptions.uploadOptions!.targetDomain, 
            //     graphics_subfolder: props.imageOptions.fileOptions?.graphicsSubfolder,
            //     outputMagnified: props.imageOptions.fileOptions?.sizeDesignator ? 'Y' : 'N'
            // });
            post(props.uploadOptions!.uploadImageApiUrl, form, imageUploaded, () => alert("Server error trying to upload image"), getToken()!.token, true);
            //            post("/api/userIsAlive", form, imageUploaded, () => alert("Server error trying to upload image"), getToken()!.token, true);
        }
    }, [imageToUpload]);

    const editImageSubmitted = (image: ImageRecord | null, imageId?: string) => {
        props.onImageSelected(image);
        setImageToEdit(undefined);
    }
    //-------- DRAG AND DROP ------------
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if ((e.dataTransfer.items[i].type.startsWith("image/")) || (props.onImageDropped && e.dataTransfer.items[i].type === ImageDragType)) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.files.length) {
            // dropping from file system
            if (e.dataTransfer.files.length > 1) {
                alert("Please drag only one photo at a time");
            } else {
                loadImageToUpload(e.dataTransfer.files[0]);
            }
        } else if (e.dataTransfer.getData(ImageDragType) !== '') {
            if (props.onImageDropped) {
                props.onImageDropped(e.dataTransfer.getData(ImageDragType));
            }
        }
    }
    //-------- END DRAG AND DROP ------------

    //-------- BROWSE FOR FILE ------------
    const browseClicked = (e: React.MouseEvent<HTMLElement>) => {
        fileInputRef.current.click();
    }
    const handleFileChosen = () => {
        //    console.log("got file: " + fileInputRef.current.files[0].name);
        const elem = fileInputRef.current;
        if (!isImageFile(elem.files![0])) {
            alert("File must be an image (.jpg .png .gif .tif)");
        } else {
            loadImageToUpload(elem.files![0]);
        }
    }
    // create image record for image just selected from file system
    const loadImageToUpload = (file: File) => {
        setImageToUpload({ file, size: props.imageOptions.fileOptions?.size ?? 500, dimension: props.imageOptions.fileOptions?.dimension ?? GraphicDimensionType.width });
    }
    //-------- END BROWSE FOR FILE ------------

    if (isPostLoading()) {
        return null;
    } else {
        return (
            <ImageInputContainer>
                <CloseDialogBar onClose={() => props.onImageSelected(null)} />
                <ImageVideoChooserContainer $size={displaySize ?? 200} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <ImageChooserContainer $width={displaySize ?? 200} onClick={browseClicked}>
                        <p>Drop new image here or click to browse for file</p>
                    </ImageChooserContainer>
                    {props.imageOptions.editOptions?.allowVideo &&
                        <VideoChooserContainer>
                            <AddVideoBox videoAdded={videoSelected} />
                        </VideoChooserContainer>
                    }
                </ImageVideoChooserContainer>
                <input style={{ display: "none" }} ref={fileInputRef} accept=".jpg,.jpeg,.png,.gif,.tif" onChange={handleFileChosen} type="file" />
                {imageToEdit && props.imageOptions.editOptions && <SamImage image={imageToEdit} imageOptions={props.imageOptions}
                    onSubmit={image => editImageSubmitted(image)} onCloseModal={() => editImageSubmitted(null)} />
                }
                {imageToUpload && <Spinner />}
            </ImageInputContainer>
        )
    }
}
//---------------------------------------------------
const AddVideoBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px #ccc solid;
    width: 100%;
`
const AddVideoInput = styled.textarea`
    margin-left: 3%;
    width: 90%;
    border: none;
    height: 90px;
`
interface AddVideoBoxProps {
    videoAdded: (image: ImageRecord) => void;
}
const AddVideoBox: React.FC<AddVideoBoxProps> = (props) => {
    const videoInputRef = React.useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;

    const saveClicked = () => {
        //   console.log("got video: " + videoInputRef.current.value);
        if (!videoInputRef.current.value) {
            alert('Please enter the YouTube/Vimeo "share" and "embed" text');
        } else {
            const sourceAndId = parseSourceAndIdFromVideo(videoInputRef.current.value);
            if (!sourceAndId) {
                alert('Embed text is incorrect. Please go to youtube.com or vimeo.com and re-copy');
            } else {
                videoInputRef.current.value = '';
                const image: ImageRecord = { stream_id: sourceAndId.streamId, stream_source: sourceAndId.streamSource };
                if (sourceAndId.streamSource === VideoStreamSource.youtube) {
                    image.stream_thumb_src = formatYoutubeThumbUrl(sourceAndId.streamId);
                }
                props.videoAdded(image);
            }
        }
    }
    return (
        <AddVideoBoxContainer>
            <AddVideoInput ref={videoInputRef}
                placeholder='Find video on YouTube or Vimeo, click "share." Copy and paste the provided "Link" or "Embed" text here, then click "Verify video" below to add.' />
            <ButtonsRow>
                <IconButton style={{ fontSize: "12px", height: "26px", marginBottom: "8px" }} caption="Verify video" icon="fas fa-video" onClick={saveClicked} />
            </ButtonsRow>
        </AddVideoBoxContainer>
    )
}
// #endregion DROP OR CHOOSE IMAGE FROM FILE SYSTEM

// #region IMAGE URL FORMATTING
/*
note that width is defined by the size field and height=width*.56 (width=height*1.78)
VIMEO EMBED:
<iframe src="https://player.vimeo.com/video/795195674?h=5f1796465d" width="640" height="320" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
allowfullscreen data-stream-id=ID data-stream-source=SOURCE></iframe>
stream_id field would contain "795195674?h=5f1796465d"
To convert embeded to thumbnail, use vimeo api

YOUTUBE EMBED:
<iframe width="560" height="315" src="https://www.youtube.com/embed/zaR3sVpTB98" title="YouTube video player" frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen data-stream-id=ID data-stream-source=SOURCE></iframe>
stream_id field would contain "zaR3sVpTB98"
YOUTUBE LINK for thumnail:
https://youtu.be/zaR3sVpTB98


images appear in DOM standardized as follows:
    <figure contenteditable="true" oncut="return false" onpaste="return false" 
        style="width: NNpx; margin-left: NNpx; margin-right: NNpx; margin-top: 0; float: FLOAT;">
        IMG_OR_IFRAME
        <figcaption>CAPTION</figcaption>
    </figure>

    IMG_OR_IFRAME: 
    <img style="width: 100%; height: HEIGHT; src="SRC" />
    OR (see above for vimeo/youtube)

    margin-left and margin-right depend on float 
    float is "left" "right" or "none"
    HEIGHT is "auto" for image, "NNpx" for video where NN is width * 1.76
    ID and SOURCE are image.stream_id and image.stream_source
*/

// drag and drop support for images
export const isImageFile = (file: File): boolean => {
    const filename = file.name.toLowerCase();
    const posn = filename.lastIndexOf('.');
    const ext = filename.substring(posn + 1, filename.length);
    return (ext === "jpg" || ext === "png" || ext === "gif" || ext === "jpeg");
}
/*
// optional todo:
// pass embedInFigure true to output iframe inside <figure> element; if false or not passed float and caption are ignored
// , embedInFigure: boolean = false, float: GraphicFloatType = GraphicFloatType.none, caption: string = ''
const formatIframeHtml = (streamSource: VideoStreamSource, streamId: string, width: number): string => {
    const widthStr = width + '';
    const height = width * videoHeightFactor;
    const heightStr = height + '';
    if (streamSource === VideoStreamSource.vimeo) {
        return `<iframe src="https://player.vimeo.com/video/${streamId}" width="${widthStr}" height="${heightStr}" frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
    } else if (streamSource === VideoStreamSource.youtube) {
        return `<iframe width="${widthStr}" height="${heightStr}" src="https://www.youtube.com/embed/${streamId}" title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    }
    return '';
}
*/
/* return null if invalid html or link
    example embeds:
    (VIMEO):
    <iframe src="https://player.vimeo.com/video/799664919?h=2fe89a8c52" width="640" height="360" frameborder="0" 
    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <p><a href="https://vimeo.com/799664919">BABY WHAT YOU WANT ME TO DO - BIG ELVIS</a> from 
    <a href="https://vimeo.com/user104338686">Marianne Foppiano</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
    (YOUTUBE):
    <iframe width="560" height="315" src="https://www.youtube.com/embed/zaR3sVpTB98" title="YouTube video player" frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    -------------------------------------------------------
    example links:
    (VIMEO):
    https://vimeo.com/799664919
    (YOUTUBE):
    https://youtu.be/zaR3sVpTB98
*/
export const parseSourceAndIdFromVideo = (embedOrLink: string): { streamSource: VideoStreamSource; streamId: string } | null => {
    let streamSource: VideoStreamSource;
    let startPosn = -1;
    let endPosn = -1;
    let streamId: string | null = null;
    if (embedOrLink.startsWith("https")) {
        // this is a link
        startPosn = embedOrLink.lastIndexOf('/');
        if (startPosn !== -1) {
            startPosn++;
        }
        endPosn = embedOrLink.length;
        streamSource = embedOrLink.startsWith("https://vimeo") ? VideoStreamSource.vimeo : VideoStreamSource.youtube;
        streamId = embedOrLink.substring(startPosn, endPosn);
    } else {
        if (embedOrLink.includes(".vimeo.com")) {
            streamSource = VideoStreamSource.vimeo;
            streamId = parseVideoId(embedOrLink, "vimeo.com/video/");
        } else if (embedOrLink.includes(".youtube.com")) {
            streamSource = VideoStreamSource.youtube;
            streamId = parseVideoId(embedOrLink, "/embed/");
        } else {
            return null;
        }
    }
    if (streamId === null) {
        return null;
    }
    return { streamSource, streamId };
}
const parseVideoId = (embedText: string, key: string,): string | null => {
    let startPosn = embedText.indexOf(key);
    if (startPosn === -1) {
        return null;
    }
    startPosn += key.length;
    if (embedText.length > startPosn) {
        // skip args such as ?h=xyzabc
        const quotePosn = embedText.indexOf('"', startPosn);
        let endPosn = embedText.indexOf('?', startPosn);
        if (endPosn === -1) {
            endPosn = quotePosn;
        }
        if (endPosn > -1) {
            return embedText.substring(startPosn, endPosn);
        }
    }
    return null;
}
// #endregion IMAGE URL FORMATTING

async function fetchVimeoThumbSrc(id: string): Promise<string> {
    const promise = new Promise<string>(function (resolve, reject) {
        fetch(formatVimeoApiUrl(id)).then(response => {
            if (response.ok) {
                response.json().then(json => resolve(json[0].thumbnail_large));
            } else {
                return '';
            }
        })
    });
    return promise;
}
export const loadVimeoThumbs = (streamIds: string[], onDone: (result: Record<string, string>) => void) => {
    const requests = streamIds.map(id => fetchVimeoThumbSrc(id));
    Promise.all(requests)
        .then(responses => {
            const result: Record<string, string> = {};
            for (let i = 0; i < responses.length; i++) {
                result[streamIds[i]] = responses[i];
            }
            onDone(result);
        });
}

// #region IMAGEMGR
interface FigMargins {
    marginLeft: string;
    marginRight: string;
}

// for use with pages that display HTML compatible with HTMLDomEditorV2
// to preprocess DOM, call imageMgr.processDom({ options })
// must call setFileOptions() after instantiation or images not supported
export class ImageMgr {
    imageOptions: ImageEditorOptions;       // only file options are used here but we keep everything here as source of truth

    constructor() {
        this.imageOptions = {} as ImageEditorOptions;
    }
    setImageEditorOptions = (imageOptions: ImageEditorOptions) => {
        if (!deepEqual(this.imageOptions, imageOptions)) {
            console.log("ImageMgr.setImageEditorOptions:", imageOptions)
            this.imageOptions = imageOptions;
        }
    }

    _floatEnumToString = (float: GraphicFloatType): string => {
        if (float === GraphicFloatType.left) {
            return "left";
        }
        if (float === GraphicFloatType.right) {
            return "right";
        }
        return "none";
    }
    _floatStringToMargins = (float: string): FigMargins => {
        const result = {} as FigMargins;
        if (float === "left") {
            result.marginLeft = "0";
            result.marginRight = "8px";
        } else if (float === "right") {
            result.marginLeft = "8px";
            result.marginRight = "0";
        } else {
            result.marginLeft = result.marginRight = "auto";
        }
        return result;
    }


    // note that this is used only in dashboards
    // video handling:
    //      videos are rendered as their youtube/vimeo thumbnail
    //      the data-stream-src attribute in figure element contains the thumbnail url
    //      the data-stream-src attribute is initialized when text first loaded; on vimeos it must be fetched async
    //      file is saved out of dashboard without the data-stream-src attribute; when loaded into viewer all videos are rendered as iframes (use ImageFormatter.activateImagesAndVideos)
    // images are rendered with src as formatted from options passed to constructor
    // when file is saved out of dashboard, videos will remain inactive and img src will be removed; use ImageFormatter.activateImagesAndVideos to fix
    // this is called when rendering from map
    createFigureElementFromImage = (image: ImageRecord): HTMLElement => {
        const fig = document.createElement('figure');
        fig.setAttribute("contenteditable", "false");
        const figStyles: StyleRecord = {};
        const imgStyles: StyleRecord = {};
        let dimensionStr: string;
        let imgWidth: string;
        let imgHeight: string;
        const float = graphicFloatTypeToString(image.float);
        figStyles["float"] = float;
        const margins = this._floatStringToMargins(float);
        figStyles["margin"] = "8px " + margins.marginRight + " 8px " + margins.marginLeft;
        if (float === "none") {
            figStyles["text-align"] = "center";
        }
        const img = document.createElement('img');
        if (image.stream_id) {
            // this is a video
            fig.setAttribute("data-stream-id", image.stream_id);
            fig.setAttribute("data-stream-source", image.stream_source + '');
            if (image.stream_thumb_src) {
                // this should have been initialized when text first loaded
                fig.setAttribute("data-stream-thumb-src", image.stream_thumb_src);
                img.setAttribute("src", image.stream_thumb_src);
            }
        } else {
            fig.setAttribute("data-file", image.filename!);
            img.setAttribute("src", formatImageUrl(image.filename!, this.imageOptions.fileOptions));
        }
        if (float !== "none") {
            imgHeight = "auto";
            imgWidth = "100%";
            dimensionStr = "width";
            figStyles[dimensionStr] = (image.floatPct ?? 50) + "%";
        } else if (image.dimension === GraphicDimensionType.height) {
            dimensionStr = "height";
            imgWidth = "auto";
            imgHeight = "100%";
            figStyles[dimensionStr] = (image.size ?? 200) + "px";
        } else {
            dimensionStr = "width";
            imgWidth = "100%";
            imgHeight = "auto";
            figStyles[dimensionStr] = (image.size ?? 400) + "px";
        }
        imgStyles["width"] = imgWidth;
        imgStyles["height"] = imgHeight;
        this._setElementStyles(fig, figStyles);
        this._setElementStyles(img, imgStyles);
        fig.appendChild(img);
        if (image.caption) {
            const capElem = document.createElement('figcaption');
            capElem.innerHTML = image.caption;
            const elems = capElem.getElementsByTagName("*");
            for (let i = 0; i < elems.length; i++) {
                (elems[i] as HTMLElement).style.removeProperty("text-align");
            }
            fig.appendChild(capElem);
        }
        /* we can just right click to edit video, same as editing images
        if (image.stream_id) {
            fig.style.position = "relative";
            const editHandle = document.createElement("i");
            this._setElementStyles(editHandle, {
                "z-index:": "1",
                "width": "50px",
                "height": "50px",
                "position": "absolute",
                "bottom": "0",
                "right": "0",
                "background-color": "yellow",
                "color": "black",
                "font-size": "50px",
                "class": "fa fa-pencil-square-o"
            });
            img.appendChild(editHandle);
        }
        */
        return fig;
    }

    _setElementStyles = (elem: HTMLElement, styles: StyleRecord) => {
        for (const style in styles) {
            elem.style.setProperty(style, styles[style]);
        }
    }

    deleteImageOrVideo = (figElem: HTMLElement) => {
        figElem.parentElement?.removeChild(figElem);
    }
}
// #endregion IMAGEMGR

export default SamImage;
