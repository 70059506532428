import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// routes
import Products from './components/Products';
import Home from './components/Home';
import AdminTasks from './components/AdminTasks';
import Categories from './components/Categories';
import Artists from './components/Artists';
import InfoPageEditor from './libs/dashboard/InfoPageEditor';
import InfoPagePreview from './libs/dashboard/InfoPagePreview';
import InfoPageSettingsEditor from './libs/dashboard/InfoPageSettingsEditor';
import InfoPageListEditor from './libs/dashboard/InfoPageListEditor';
import { useGlobalContext, useSessionStore } from './libs/SamState';
import FormMgr from './libs/forms/FormMgr';
import CommonLogin, { authKey } from './libs/CommonLogin';

import app from './appData';

const AppRoutes = () => {
    const { setContext } = useGlobalContext();
    const { getSessionStore } = useSessionStore();
    const forms = new FormMgr(setContext);
  
    React.useEffect(() => {
      forms.clearForms();
    }, [location]);
  
   if (app.loginRequired && !getSessionStore(authKey)) {
      return (
        <CommonLogin />
      )
    }
  
      return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/infopage-preview" element={<InfoPagePreview />} />
                <Route path="/infopage-editor" element={<InfoPageEditor />} />
                <Route path="/infopages" element={<InfoPageListEditor />} />
                 <Route path="/admin/tasks" element={<AdminTasks />} />
                <Route path="/admin/infopage-settings" element={<InfoPageSettingsEditor />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/artists/:url" element={<Artists />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;