import React from 'react';
import styled from 'styled-components';

import InfoPageStyles from './InfoPageStyles';
import LoadFontsModal from './FontLoader';
import { activateImagesAndVideos, imageEditorOptionsValues } from './ImageFormatter';

import { InfoPageApiRecord } from '../interfaces/lib-api-interfaces';

// display any page created by HtmlEditor

const Container = styled.div<{ $maxWidth: number }>`
    max-width: ${props => props.$maxWidth}px;
    margin: 16px auto 32px auto;
`
// name is passed through url, page is in session store or loaded from api
// to override ALL image options (i.e., graphicsSubfolder, etc.) set appData.infoPageThemes.imageFileOptions; this will override options set on infoPage record
interface InfoPageViewerProps {
    infoRecord: InfoPageApiRecord;
    maxWidth?: number;              // default to 900px
}
const InfoPageViewer: React.FC<InfoPageViewerProps> = (props) => {
    const [loadingFonts, setLoadingFonts] = React.useState(false);

    console.log("Viewer:", props.infoRecord)

    const divRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const contentRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const maxWidth = props.maxWidth ?? 900;

    React.useEffect(() => {
        contentRef.current.innerHTML = props.infoRecord.content;
        const imageOptions = props.infoRecord.settings.image_options ?? imageEditorOptionsValues();
        const styles = props.infoRecord.settings.styles;
        const infoPageStyles = new InfoPageStyles(divRef.current);
        infoPageStyles.setGlobalStyles(styles);
        const links = contentRef.current.getElementsByTagName('a');
        for (let i = 0; i < links.length; i++) {
            const href = links[i].getAttribute("data-href");
            if (href) {
                links[i].setAttribute("href", href);
            }
        }
        if (imageOptions?.fileOptions) {
            activateImagesAndVideos(contentRef.current, imageOptions.fileOptions, false);
        }
        setLoadingFonts(true);
    }, []);

    const gotFonts = (errors: string[]) => {
        setLoadingFonts(false);
    }

    return (
        <Container $maxWidth={maxWidth}>
            <div ref={divRef}>
                {loadingFonts && <LoadFontsModal root={contentRef.current} globalStyles={props.infoRecord.settings.styles} onDone={gotFonts} />}
                {props.infoRecord.title && <h1>{props.infoRecord.title}</h1>}
                <div ref={contentRef} />
            </div>
        </Container>
    )
}
export default InfoPageViewer;