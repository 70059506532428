import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import MasterPage from './MasterPage';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import { genericApiError, useFetchApi, usePostApi } from '../libs/useDataApiV2';
import SamGrid, { Cell } from '../libs/dashboard/SamGridV3';
import deepcopy from 'deepcopy';
import { useTokens } from '../libs/SamState';

import { FormFieldRecord, FormFieldType, InfoIndexRecord, InfoSectionTypeEnum, SetInfoDisplayOrderRecord, DivisionEnum } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import { IconButtonAlignEnum } from '../interfaces/lib-react-interfaces';
import { formatImageUrl } from '../libs/ImageFormatter';


const Artists: React.FC = () => {
    const params = useParams();
    const url = params.url as string;
  const navigate = useNavigate();

    return (
        <MasterPage>
            <h1>About the Artists</h1>
            {url === "index" ? (
                <ArtistsIndex />
            ) : (
                <React.Fragment>
                    <ButtonsRow marginBottom={16} align={IconButtonAlignEnum.center}>
                        <IconButton caption='Back to "About the Artists" index' icon="fas fa-hand-point-left" onClick={() => navigate("/artists/index")} />
                    </ButtonsRow>
                    <InfoContentEditor division={DivisionEnum.fg} url={url} />
                </React.Fragment>
            )}
        </MasterPage>
    )
}
//--------------------------------------------------------
const IndexContainer = styled.div`
    width: 600px;
    margin-left: auto;
    margin-right: auto;
`
const ArtistsIndex: React.FC = () => {
    const [data, setData] = React.useState<InfoIndexRecord[]>();
    const [fetchData, setFetchData] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (fetchData) {
            setFetchData(false);
            fetch(api.getInfoContent + DivisionEnum.fg + "/" + InfoSectionTypeEnum.artists + "?i=1", null, saveData, () => setErrorMsg(genericApiError))
        }
    }, [fetchData]);
    React.useEffect(() => {
        setFetchData(true);
    }, []);

    const saveData = (result: InfoIndexRecord[]) => {
        setData(result.map(record => {
            const rec = { ...record };
            rec.isLive = record.display_order >= 0;
            return rec;
        }
        ));
    }

    const colDefs: FormFieldRecord[] = [
        { name: "title", label: "Title" },
        { name: "image", label: '', type: FormFieldType.image, fixedWidth: 100 },
        { name: "isLive", label: "Live", type: FormFieldType.checkbox },
        { name: "editor", label: '', type: FormFieldType.icon, fixedWidth: 50, icon: "fas fa-ellipsis-h", iconFontSize: 22, cursor: "pointer" },
        { name: "url", label: '', visible: false }
    ];

    const gridFormatter = (dataRow: Record<string, any>, name: string, value: any): string | null => {
        if (name === "image" && value) {
            return formatImageUrl(value.filename as string, { graphicsSubfolder: "blog" });
        }
        return null;
    }

    const gridSaveError = () => {
        alert("Unable to save changes");
        setFetchData(true);
    }
    const gridCellClicked = (cell?: Cell) => {
        if (!cell) {
            return true;
        }

        if (cell.owningColumn === "isLive") {
            const cellWasLive = cell.dataRow.isLive as boolean;
            const newData = deepcopy(data) as InfoIndexRecord[];
            let displayOrder;
            if (cellWasLive) {
                // find minimum display_order and set to it less one, but not >=0
                displayOrder = newData.reduce((a, record) => Math.min(a, record.display_order), 0) - 1;
                displayOrder = Math.min(displayOrder, -1);
            } else {
                // same but find highest
                displayOrder = newData.reduce((a, record) => Math.max(a, record.display_order), 0) + 1;
            }
            newData[cell.rowIndex].display_order = displayOrder;
            newData[cell.rowIndex].isLive = !cellWasLive;
            setData(newData);
            post(api.setInfoDisplayOrder, newData.map(record => {
                return { info_id: record.info_id, display_order: record.display_order } as SetInfoDisplayOrderRecord;
            }), () => { }, gridSaveError, getToken()!.token);
        } else if (cell.owningColumn === "editor") {
            navigate("/artists/" + data![cell.rowIndex].url);
        }
        return true;
    }

    return (
        <IndexContainer>
            {data &&
                <SamGrid columnDefs={colDefs} dataSource={data} marginLeft={8} gridStyles={{ rowHeight: 50 }} allowEditing={false} allowInsert={false} isControlled={true}
                    cellClicked={gridCellClicked} customFormatter={gridFormatter} />
            }
        </IndexContainer>

    )
}
export default Artists;
