export const htmlToPaste =
`<p style="margin: 0px 0px 12px;"><span></span><span><span></span></span><span><span>We here at Fern’s Garden take great
            pride in featuring the creative work of artisans across America. However, every now and then we get that
            extra special experience of discoverin</span><span
            style="font-weight: normal; font-style: normal; text-align: left; font-size: 16px; font-family: Roboto, sans-serif; color: rgb(0, 0, 0);"></span><span>g
            an artisan right in our own back yard. Keri founded EKP Creations candles with her husband Emiliano right
            here in Long Beach in 2014 where they started making their recycled wine bottle candles in their kitchen. We
            added their candles to our Long Beach store in 2016 and they have been a hit ever since. The two candle
            makers are doing something truly unique by recycling wine and beer bottles into
            candles.</span></span><span></span></p>
<div style="margin: 0px 0px 12px;">
    <div width="600" class="sc-eVBRet hKvdJy" style="margin: 0px 0px 12px;"><img
            data-src="https://fernsgarden.com/graphics/blog/ekp_unnamed-1-.png"
            alt="An arrangement of some of the candles made by EKP. Photo Credit: EKP Creations"
            class="sc-gBztCq bRGlfR">
        <p style="margin: 0px 0px 12px;">An arrangement of some of the candles made by EKP. Photo Credit: EKP Creations
        </p>
    </div>
    <p style="margin: 0px 0px 12px;"><span>The spouses made a perfect team, with Keri creating intriguing scents while
            Emiliano cut the glass on the bottles. As they refined and perfected their process, they started attending
            local craft fairs where their candles would consistently sell out. The rest is history, as they expanded
            into the wholesale market and began selling candles to other retailers. Keri left her office job to make
            candles full time. In the mean time, Emiliano refined his glass cutting skills so he could hand-cut and
            polish each upcycled bottle.</span></p>
    <div width="600" class="sc-eVBRet hKvdJy" style="margin: 0px 0px 12px;"><img
            src="https://fernsgarden.com/graphics/blog/ekp_unnamed-2-.jpg"
            alt="Keri and Emiliano the candle making team! Photo Credit: EKP Creations" class="sc-gBztCq bRGlfR">
        <p style="margin: 0px 0px 12px;">Keri and Emiliano the candle making team! Photo Credit: EKP Creations</p>
    </div>
    <p style="margin: 0px 0px 12px;"><span>Today, Keri has her own studio where she creates candles and mixes scents.
            She only uses all-natural soy that was produced from American-grown soybeans. The soy burns clean and the
            scent will fill an entire room. The best part about soy-based candles is that they are completely renewable.
            Keri tells us that “the soy wax contains no toxins, carcinogens or pollutants, which means it’s less likely
            to trigger allergies.” Also with clean-burning soy wax, there is no soot residue, which is a big
            advantage.</span></p>
    <div width="600" class="sc-eVBRet hKvdJy" style="margin: 0px 0px 12px;"><img
            src="fernsgarden.com/graphics/blog/ekp_69705891-2393237574095310-1934441742426177536-o.jpg"
            class="sc-gBztCq bRGlfR"></div>
    <p style="margin: 0px 0px 12px;"><span>Keri continues to create what she calls “crave-able, memorable candle
            fragrances” that are infused with only natural essential oils. She has a variety of scents ranging from
            floral and sweet to musky and earthy making a scent for everyone. She also does an amazing job of matching
            each scent to a given wine bottle.</span></p>
    <div width="600" class="sc-eVBRet hKvdJy" style="margin: 0px 0px 12px;"><img
            src="https://fernsgarden.com/graphics/blog/ekp_unnamed-1-.pdf" class="sc-gBztCq bRGlfR"></div>
    <p style="margin: 0px 0px 12px;"><span>As Keri continues to grow her company, she has added many new products,
            including custom wine bottles and travel candles. We now carry her original upcycled wine bottle candles as
            well as some travel jar candles with her top scents. The future looks very bright for Keri, Emiliano and EKP
            Creations. We are thankful to have them in our stores, and we are excited to discover what they come up with
            next. Cheers!&nbsp;</span></p>
</div><span></span>
<p style="margin: 0px 0px 12px;"></p>`