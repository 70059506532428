import { MenuItemData, DivisionEnum } from './interfaces/lib-api-interfaces';
import { AppThemesRecord } from './interfaces/lib-react-interfaces';

const menuItems: MenuItemData[] = [
    { caption: "Home", href: "/home" },
    { caption: "Products", href: "/products" },
    { caption: "Info pages", href: "/infopages" },
    { caption: "Categories & menu", href: "/categories" },
    { caption: "About the Artists", href: "/artists/index" },
    { caption: "Admin", popupItems: [
            { caption: "Text and image settings", href: "/admin/infopage-settings" },
            { caption: "Tasks", href: "/admin/tasks" }
        ]
    }
];

const isDashboard = true;
const loginRequired = true;
//const domain = "dashboard.fernsgarden";
const targetDomain = "fg.chimes.lol";
const devProjectFolder = "fg.chimes.lol";        // applies only if this is dashboard; the folder under "active" folder that contains the target project
//const serverIp = "https://" + domain + ".com";
const logoUrl = "fg-dashboard-logo.jpg";
const division = DivisionEnum.fg;
const maxThumbnailSize = 500;
const carouselImageHeight = 500;

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const sansFonts = "Roboto,Arial,Helvetica,sans-serif";
const themes: AppThemesRecord = {
    serifFonts: "Volkhov,'Times New Roman',Times,George,Palatino,Garamond",
    sansFonts: sansFonts,
    color: "#4a5929", // Pantone 574,
    backColor50: "#d3d584",  //  583 at 50%
    backColor25: "#e9ecc2",  //  583 at 25%
    backColor10: "#f6f8e6", // 583 at 10%
    modalStyles: { fontSize: "16px", fontFamily: sansFonts, lineHeight: "24px", padding: "16px" }
}

export default {
    isDashboard,
    loginRequired,
    menuItems,
    division,
    devProjectFolder,
    //   domain,
    targetDomain,
    maxThumbnailSize,
    carouselImageHeight,
    //   serverIp,
    logoUrl,
    themes
}

