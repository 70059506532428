import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';


import IconButton, { ButtonsRow } from '../IconButtonV2';
import MasterPage from '../../components/MasterPage';
// import { infoPagePreviewKey } from './InfoPageEditor';
import { useSessionStore } from '../SamState';
import InfoPageViewer from '../InfoPageViewer';

import { InfoPageApiRecord, InfoPageEditorRouterState } from '../../interfaces/lib-api-interfaces';
import deepcopy from 'deepcopy';

// infoPage object must be in router state

const Container = styled.div<{ $maxWidth: number }>`
    max-width: ${props => props.$maxWidth}px;
    margin: 16px auto 32px auto;
`
const InfoPage: React.FC = () => {
    const [infoPageRecord, setInfoPageRecord] = React.useState<InfoPageApiRecord>();

    const navigate = useNavigate();
    const location = useLocation();
    const { getSessionStore } = useSessionStore();

    const maxWidth = 900;

    React.useEffect(() => {
    //    const data = getSessionStore(infoPagePreviewKey);
        const data = (location.state as InfoPageEditorRouterState).page;
        if (data) {
            setInfoPageRecord(data);
        }
    }, []);

    const closePreview = () => {
        // change href rather than navigate to avoid weird behavior when we return to EditInfoPage
        //     navigate(props.closePreview + "/" + params.name)
        navigate("/infopage-editor", { state: deepcopy(location.state) });
       // window.location.href = "/infopage-editor";
    }

    return (
        <MasterPage>
            <Container $maxWidth={maxWidth}>
                <ButtonsRow height={80}>
                    <IconButton caption="Close preview" onClick={closePreview} fontSizeOverride={18} />
                </ButtonsRow>
                {infoPageRecord &&
                <InfoPageViewer infoRecord={infoPageRecord} />}
            </Container>
        </MasterPage>
    )
}
export default InfoPage;