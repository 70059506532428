import React from 'react';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import { getNearestParentId } from '../libs/libSupport';
import SamContextMenu, { ContextMenuInfo, ContextMenuItem } from '../libs/SamContextMenu';
import { ModalInputString } from '../libs/SamModalV2';

import { FGCategoriesApiRecord, FGCategoryRecord, FGCategoryRecordProperty, FGCategoryTreeRecord } from '../interfaces/fg-api-interfaces';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import { fixUrl } from '../libs/api-shared/api-shared-helpers';

const inputWidth = 200;
const fontSize = 12;
const itemHeight = 18;

interface DashboardCategoryRecord {
    isHeader?: boolean;
    links?: string[];       // split from linked_urls field
    isSubMenu?: boolean;        // true if item is submenu off of popup item
    isSelected?: boolean;
    caption: string;
    id: number;
    row: number;
    col: number;
    productCount: number;
}

const MasterContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${fontSize}px;
`
const MenuContainer = styled.div`
    display: flex;
    justify-content: space-between;

`
const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
`
const CaptionContainer = styled.div<{ backColor: string; isSubMenu?: boolean }>`
    display: flex;
    align-items: center;
    height: ${itemHeight}px;
    background-color: ${props => props.backColor};
    width: ${inputWidth}px;
    margin-left: ${props => props.isSubMenu ? 12 : 0}px;
    cursor: pointer;
`

enum EnterCategoryReason { inserting = 1, renaming, insertingColumnBefore, insertingColumnAfter }    // to determine if category or header check showContextMenu.menuItem
interface SelectedMenuItem {
    row: number;        // -1 if past end of submenu
    col: number;
    index: number;      // index into data; points to start of next column if row is -1 (=data.length if past end of last column)
}
interface TooltipInfo {
    captions: string[];
    location: { x: number; y: number };
}
interface MenuEditorProps {
    data: FGCategoriesApiRecord;
    onSubmit: (data: FGCategoriesApiRecord | null) => void;
}
interface EnterCategoryRecord {
    reason: EnterCategoryReason;
    menuItem: DashboardCategoryRecord;
}
const MenuEditor: React.FC<MenuEditorProps> = (props) => {
    const [data, setData] = React.useState<DashboardCategoryRecord[]>();
    const [categories, setCategories] = React.useState<FGCategoryRecord>();
    const [copied, setCopied] = React.useState<DashboardCategoryRecord>();
    const [contextMenuInfo, setContextMenuInfo] = React.useState<ContextMenuInfo>();     // { location: {x,y}, menu: [contextMenuItem] } (userData = Cell object)
    const [showEnterCategoryDlg, setShowEnterCategoryDlg] = React.useState<EnterCategoryRecord>();
    const [tooltip, setTooltip] = React.useState<TooltipInfo>();

    const MenuDragType = "menuitem";

    React.useEffect(() => {
        setCategories(props.data.categories);
        const menu = shapeMenuData(props.data);
        setData(menu);
    }, []);

    // convert FGCategoriesApiRecord to DashboardCategoryRecord[]
    const shapeMenuData = (apiData: FGCategoriesApiRecord): DashboardCategoryRecord[] => {
        let col = 0;
        let row = 0;
        const menu: DashboardCategoryRecord[] = [];
        for (const treeRec of apiData.categoryTree) {
            row = 0;
            menu.push(loadCategoryRecord(row, col, treeRec.categoryId, apiData.categories[treeRec.categoryId]));
            row++;
            if (treeRec.subcategories) {
                for (const subRec of treeRec.subcategories) {
                    menu.push(loadCategoryRecord(row, col, subRec.categoryId, apiData.categories[subRec.categoryId]));
                    row++;
                }
            }
            col++;
        }
        console.log("shapeMenuData:", apiData); console.log("==>", menu)
        return menu;
    }
    const loadCategoryRecord = (row: number, col: number, id: number, catRec: FGCategoryRecordProperty): DashboardCategoryRecord => {
        return {
            row,
            col,
            id,
            isHeader: row === 0,
            links: catRec.links,
            caption: catRec.caption,
            productCount: catRec.productCount ?? 0
        }
    }
    /*
    export interface FGCategoryTreeRecord {
        subcategories?: FGCategoryTreeRecord[];   // always undefined if last subcategory
        categoryId: number;         // key to FGCategoryRecord for retrieving caption and url
        displayOrder?: number;       // from table; for inserting new categories
        // following are maintained by CategoryEditor
        isExpanded: boolean;
        isChecked: boolean;
        isIndented: boolean;        // in this one there is only one level of indentation
        parentId?: number;              // applies only to popup items
        subcategoryChecked?: boolean;    // applies only to main category items; used only inside category editor
    }
    export interface FGCategoryRecordProperty {
        caption: string;
        url: string;
        links?: string;
        image_filename?: string;
        productCount?: number;      // returned when called from dashboard menu editor
    }
    
        isHeader?: boolean;
        links?: string;
        isSubMenu?: boolean;        // true if item is submenu off of popup item
        isSelected?: boolean;
        caption: string;
        id: number;
        row: number;
        col: number;
        productCount: number;
    */
    const updateData = (menuData: DashboardCategoryRecord[]) => {
        setData(menuData);
    }
    const submitClicked = () => {
        const apiRecord = unshapeMenuData(data!);
        props.onSubmit(apiRecord);
    }
    // convert DashboardCategoryRecord[] to FGCategoriesApiRecord
    const unshapeMenuData = (menu: DashboardCategoryRecord[]): FGCategoriesApiRecord => {
        const categoryTree: FGCategoryTreeRecord[] = [];
        const categories: FGCategoryRecord = {};
        let treeRec: FGCategoryTreeRecord | null = null;
        for (const menuRec of menu) {
            categories[menuRec.id] = { caption: menuRec.caption, links: menuRec.links };
            if (menuRec.row === 0) {
                if (treeRec) {
                    categoryTree.push(treeRec);
                }
                treeRec = { categoryId: menuRec.id, displayOrder: (menuRec.col + 1) * 100 };
            } else {
                if (!treeRec!.subcategories) {
                    treeRec!.subcategories = [];
                }
                treeRec!.subcategories.push({ categoryId: menuRec.id, displayOrder: menuRec.row * 100 })
            }
        }
        if (treeRec) {
            categoryTree.push(treeRec);
        }
        console.log("unshapeMenuData:", menu); console.log("==>", { categories, categoryTree })
        return { categories, categoryTree };
    }

    // #region DATA RECORDS
    // also used by drag and drop
    const getSelectionFromElement = (elem: HTMLDivElement): SelectedMenuItem => {
        const indexesId = getNearestParentId(elem).id;
        const { row, col } = parseIndexes(indexesId);
        return { row, col, index: getIndex(row, col) };
    }
    // parse category/subcat indexes as given in id of div (e.g.: "1,2" -> [1,2])
    const parseIndexes = (indexes: string): { row: number; col: number } => {
        const parsed = indexes.split(',');
        return { row: parseInt(parsed[0]), col: parseInt(parsed[1]) };
    }
    const getIndex = (row: number, col: number): number => {
        if (row === -1) {
            row = 0;
            col++;
        }
        let index = data!.findIndex(item => item.row === row && item.col === col);
        if (index === -1) {
            // must have been at very end of array to return that
            index = data!.length;
        }
        return index;
    }
    // #endregion DATA RECORDS

    // #region CONTEXT MENU
    const contextMenuInvoked = (e: React.MouseEvent<HTMLSpanElement>) => {
        //        console.log("contextMenuInvoked: e.target=", e.target)
        e.stopPropagation();
        e.preventDefault();
        const selected = getSelectionFromElement(e.target as HTMLDivElement);
        const contextMenu = buildContextMenu(selected);
        setContextMenuInfo(new ContextMenuInfo({ x: e.clientX, y: e.clientY }, contextMenu));
    }

    // caption: row.caption, url: row.url, isMain: !row.isIndented
    // this is passed to the context menu component
    const buildContextMenu = (selected: SelectedMenuItem): ContextMenuItem[] => {
        const menu: ContextMenuItem[] = [];
        const copiedCaption = copied ? copied.caption : null;
        if (selected.row === -1) {
            menu.push(new ContextMenuItem("Add category", contextInsertCategoryCmd, selected));
            menu.push(new ContextMenuItem("Add submenu header", contextInsertCategoryCmd, selected));
        } else {
            const caption = data![selected.index].caption;
            menu.push(new ContextMenuItem("Rename " + caption, contextRenameCmd, selected));
            menu.push(new ContextMenuItem("Insert new category here", contextInsertCategoryCmd, selected));
            menu.push(new ContextMenuItem("Insert submenu header here", contextInsertCategoryCmd, selected));
            menu.push(new ContextMenuItem("Delete " + caption, contextDeleteCmd, selected));
        }
        menu.push(new ContextMenuItem("Insert column before this one", contextInsertColumnBeforeCmd, selected));
        menu.push(new ContextMenuItem("Insert or add column after this one", contextInsertColumnAfterCmd, selected));
        if (copiedCaption) {
            menu.push(new ContextMenuItem("Paste " + copiedCaption + " here", contextInsertSelectedCmd, selected));
        }
        return menu;
    }
    const closeContextMenu = () => {
        setContextMenuInfo(undefined);
    }
    // call this after user has entered caption via modal
    // if row=-1 add to end of column
    const insertOrAddCategory = (caption: string, row: number, col: number, isHeader: boolean) => {
        const menuData = deepcopy(data) as DashboardCategoryRecord[];
        const index = getIndex(row, col);
        if (index === menuData.length) {
            col = menuData[menuData.length - 1].col;
            row = menuData[menuData.length - 1].row + 1;
        }
        const newCategory = { caption, id: -1, row, col, isHeader: isHeader, productCount: 0 };
        menuData.splice(index, 0, newCategory);
        for (let i = index + 1; i < menuData.length && menuData[i].col === col; i++) {
            menuData[i].row++;
        }
        //   console.log("menuData:", menuData)
        updateData(menuData);
    }
    const insertColumn = (caption: string, col: number, before: boolean) => {
        const menuData = deepcopy(data) as DashboardCategoryRecord[];
        const index = getIndex(0, col);
    }
    const contextInsertCategoryCmd = (menuItem: DashboardCategoryRecord) => {
        setShowEnterCategoryDlg({ reason: EnterCategoryReason.inserting, menuItem });
        closeContextMenu();
    }
    const contextRenameCmd = (menuItem: DashboardCategoryRecord) => {
        setShowEnterCategoryDlg({ reason: EnterCategoryReason.renaming, menuItem });
        closeContextMenu();
    }
    const contextInsertColumnBeforeCmd = (menuItem: DashboardCategoryRecord) => {
        setShowEnterCategoryDlg({ reason: EnterCategoryReason.insertingColumnBefore, menuItem });
        closeContextMenu();
    }
    const contextInsertColumnAfterCmd = (menuItem: DashboardCategoryRecord) => {
        setShowEnterCategoryDlg({ reason: EnterCategoryReason.insertingColumnAfter, menuItem });
        closeContextMenu();
    }
    const contextDeleteCmd = (menuItem: DashboardCategoryRecord) => {
        const menuData = deepcopy(data) as DashboardCategoryRecord[];
        const index = getIndex(menuItem.row, menuItem.col);
        menuData.splice(index, 1);
        updateData(menuData);
        closeContextMenu();
    }
    // paste copied category here
    const contextInsertSelectedCmd = (menuItem: DashboardCategoryRecord) => {
        insertOrAddCategory(copied!.caption, menuItem.row, menuItem.col, !!copied!.isHeader);
        closeContextMenu();
    }
    // #endregion CONTEXT MENU

    //-------- DRAG AND DROP --------------
    const handleDragStart = (e: React.DragEvent) => {
        const selected = getSelectionFromElement(e.target as HTMLDivElement);
        e.dataTransfer.setData(MenuDragType, JSON.stringify(selected as Record<string, any>));
    }
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if (e.dataTransfer.items[i].type === MenuDragType) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const target = getSelectionFromElement(e.target as HTMLDivElement);
        const source = JSON.parse(e.dataTransfer.getData(MenuDragType)) as SelectedMenuItem;
        const menu: ContextMenuItem[] = [
            new ContextMenuItem("Move " + data![source.index].caption + " to here", moveCategoryCmd, { source, target })
        ];
        if (target.row !== -1) {
            menu.push(new ContextMenuItem("Link " + data![source.index].caption + " to " + data![target.index].caption, linkCategoryCmd, { source, target }));
        }
        setContextMenuInfo(new ContextMenuInfo({ x: e.clientX, y: e.clientY }, menu));
    }
    const moveCategoryCmd = (dropResult: { source: SelectedMenuItem, target: SelectedMenuItem }) => {
        let menuData = deepcopy(data) as DashboardCategoryRecord[];
        // set source to column of target, then physically move it, then renumber all rows
        if (dropResult.target.row === -1) {
            menuData[dropResult.source.index].col = menuData[dropResult.target.index - 1].col;
        } else {
            menuData[dropResult.source.index].col = menuData[dropResult.target.index].col;
        }
        const element = menuData[dropResult.source.index];
        const sceIndex = dropResult.source.index;
        let targetIndex = dropResult.target.index;
        if (sceIndex < targetIndex) {
            targetIndex--;
        }
        menuData.splice(sceIndex, 1);
        menuData.splice(targetIndex, 0, element);
        let currCol = 0;
        let currRow = 0;
        for (let index = 0; index < menuData.length; index++) {
            if (menuData[index].col !== currCol) {
                currCol = menuData[index].col;
                currRow = 0;
            }
            menuData[index].row = currRow++;
        }
        updateData(menuData);
        setContextMenuInfo(undefined);
    }
    const linkCategoryCmd = (dropResult: { source: SelectedMenuItem, target: SelectedMenuItem }) => {
        let menuData = deepcopy(data) as DashboardCategoryRecord[];
        const sourceRecord = menuData[dropResult.source.index];
        let url = sourceRecord.id === -1 ? fixUrl(sourceRecord.caption) : props.data.categories[sourceRecord.id].url!.slice(1);
        let parentUrl = '';
        if (sourceRecord.row > 0) {
            for (let index = dropResult.source.index - 1; index >= 0; index--) {
                if (menuData[index].row === 0) {
                    parentUrl = menuData[index].id === -1 ? fixUrl(menuData[index].caption) : props.data.categories[menuData[index].id].url!;
                    break;
                }
            }
        }
        if (parentUrl) {
            url = parentUrl.slice(1) + "/" + url;
        }
        if (!menuData[dropResult.target.index].links) {
            menuData[dropResult.target.index].links = [];
        }
        if (!menuData[dropResult.target.index].links!.includes(url)) {
            menuData[dropResult.target.index].links!.push(url);
        }
        updateData(menuData);
        setContextMenuInfo(undefined);
    }
    //-------- END DRAG AND DROP --------------

    // submit from enter category modal -- showEnterCategoryDlg contains reason modal was invoked
    const categoryEntered = (input: string | null) => {
        if (input) {
            console.log("categoryEntered: input=" + input + ", showEnterCategoryDlg:", showEnterCategoryDlg)
            if (showEnterCategoryDlg!.reason === EnterCategoryReason.inserting) {
                // insert new category where context menu was invoked
                insertOrAddCategory(input, showEnterCategoryDlg!.menuItem.row, showEnterCategoryDlg!.menuItem.col, false);
            } else if (showEnterCategoryDlg!.reason === EnterCategoryReason.renaming) {
                // rename category where context was invoked
                const menuData = deepcopy(data) as DashboardCategoryRecord[];
                const index = getIndex(showEnterCategoryDlg!.menuItem.row, showEnterCategoryDlg!.menuItem.col);
                menuData[index].caption = input;
                updateData(menuData);
            } else if (showEnterCategoryDlg!.reason == EnterCategoryReason.insertingColumnAfter) {
                // insert new column after cursor position
            } else if (showEnterCategoryDlg!.reason === EnterCategoryReason.insertingColumnBefore) {
                // insert new column before cursor
            } else {
                throw "Invalid enter category reason: " + showEnterCategoryDlg;
            }
        }
        setShowEnterCategoryDlg(undefined);
    }
    const onHover = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!tooltip) {
            const selected = getSelectionFromElement(e.target as HTMLDivElement);
            const rect = (e.target as HTMLDivElement).getBoundingClientRect();
            const links = data![selected.index].links;
            if (links) {
                setTooltip({ captions: links, location: { x: rect.x, y: rect.y + 3 * rect.height } });
            }
        }
    }

    const colCount = data ? data[data.length - 1].col : 0;
    const map: number[] = [];
    for (let i = 0; i < colCount; i++) {
        map.push(i);
    }

    return (
        <MasterContainer>
            {data &&
                <>
                    <ButtonsRow autoStyleButtons={true} height={50}>
                        <IconButton caption="Save all changes" icon="fas fa-check" onClick={submitClicked} />
                        <IconButton caption="Cancel all changes" icon="fas fa-ban" onClick={() => props.onSubmit(null)} />
                    </ButtonsRow>
                    <MenuContainer>
                        {map.map(index => {
                            return (
                                <ColumnContainer key={index}>
                                    {data.map(item => {
                                        if (item.col === index) {
                                            let backColor = "white";
                                            if (item.links) {
                                                backColor = "yellow";
                                            } else if (item.isHeader) {
                                                backColor = "lightgray";
                                            }
                                            return (
                                                <CaptionContainer key={item.row} id={item.row + "," + item.col} backColor={backColor}
                                                    isSubMenu={item.isSubMenu} onContextMenu={contextMenuInvoked} draggable="true"
                                                    onMouseEnter={onHover} onMouseLeave={() => setTooltip(undefined)}
                                                    onDragStart={handleDragStart}
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleDrop}>
                                                    <span>{item.caption + " (" + item.productCount + ")"}</span>
                                                </CaptionContainer>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                    <CaptionContainer key="new" id={"-1," + index} backColor="white" onContextMenu={contextMenuInvoked}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}>
                                        <span>&nbsp;</span></CaptionContainer>
                                </ColumnContainer>
                            )
                        })}
                    </MenuContainer>
                    {contextMenuInfo &&
                        <SamContextMenu closePopup={closeContextMenu} info={contextMenuInfo} />
                    }
                    {tooltip && <TooltipPopup info={tooltip} />}
                    {showEnterCategoryDlg &&
                        <ModalInputString caption="Enter category caption:"
                            default={showEnterCategoryDlg!.reason === EnterCategoryReason.renaming ? showEnterCategoryDlg.menuItem.caption : ''}
                            onSubmit={categoryEntered} />
                    }
                </>
            }
        </MasterContainer>
    )
}

//-----------------------------------------------------------------
const TooltipContainer = styled.div<{ x: number; y: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: ${props => props.y - 30}px;
    left: ${props => props.x}px;
    font-family: sans-serif;
    font-size: 14px;
    background-color: aqua;
    color: black;
    text-align: left;
    p {
        margin: 4px;
    }
`
const TooltipHeader = styled.p`
    font-weight: bold;
`
interface TooltipPopupProps {
    info: TooltipInfo;
}
const TooltipPopup: React.FC<TooltipPopupProps> = (props) => {
    return (
        <TooltipContainer x={props.info.location.x} y={props.info.location.y}>
            <TooltipHeader>Linked categories:</TooltipHeader>
            {props.info.captions.map(caption => {
                return (
                    <p key={caption}>{caption}</p>
                )
            })}
        </TooltipContainer>
    )
}
export default MenuEditor;
