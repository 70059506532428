import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import NavBar from '../libs/NavBarV5';
import Spinner from '../libs/Spinner';
import { formatImageUrl } from '../libs/ImageFormatter';

import app from '../appData';

const StyledMasterContainer = styled.div<{ $defaultFonts: string; $h1FontFamily: string; $foreColor: string }>`
    text-align: center;
    font-family: ${props => props.$defaultFonts};
    color: ${props => props.$foreColor};
    a:link {
        text-decoration: none; color: ${props => props.$foreColor}
    }
    a:visited {
        text-decoration: none; color: ${props => props.$foreColor}
    }
    a:hover {
        font-style: italic; text-decoration: underline; color: ${props => props.$foreColor}
    }
    h1 {
        font-family: ${props => props.$h1FontFamily};
        text-align: center;
        font-size: 32px;
        margin-top: 24px;
        margin-bottom: 4px;
        font-weight: bold;
    }
    h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
`
const ContentAndCart = styled.div`
    display: flex;
    flex-direction: row;
`
const StyledContentContainer = styled.div`
    width: 100%;
`
const StyledCartContainer = styled.div`
    flex-basis: 350px;
`
const MasterPage: React.FC<PropsWithChildren<{}>> = (props) => {

    /*
    const apiState = getContext(apiStateKey);
    const apiStateElapsed = apiState ? (Date.now() - apiState) : 0;
    if (apiStateElapsed) {
        // check back in 1 second to see if we are still busy; if so we can show spinner
        setTimeout(() => {
            //     console.log("setForceRerender: apiStateElapsed=" + apiStateElapsed + ", apiState=" + apiState)
            setRerender(state => !state);
        }, 1000);
    }
    */
   const apiStateElapsed = 0;

    return (
        <StyledMasterContainer $defaultFonts={app.themes.sansFonts!} $h1FontFamily={app.themes.sansFonts!}
            $foreColor={app.themes.color!}>
            {(apiStateElapsed > 1000) && <Spinner />}
            <img src={formatImageUrl(app.logoUrl, { graphicsSubfolder: "other" })} />
            <NavBar menuItems={app.menuItems} />
            {props.children}
        </StyledMasterContainer>
    );
}
export default MasterPage;
