import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';
import deepcopy from 'deepcopy';

import { genericApiError, useFetchApi, usePostApi } from '../useDataApiV2';
import IconButton, { ButtonsRow } from '../IconButtonV2';
import { useGlobalContext, useTokens } from '../SamState';
import FormMgr from '../forms/FormMgr';
import InfoPageStyles from '../InfoPageStyles';
import { flattenImageEditorOptions, imageEditorOptionsFields, imageEditorOptionsValues, splitImageEditorOptions } from '../ImageFormatter';
import Spinner from '../Spinner';
import SaveAsBox from '../forms/SaveAsBox';
import MasterPage from '../../components/MasterPage';
import SamModal from '../SamModalV2';
import SimpleHzForm from '../forms/SimpleHzForm';
import Checkbox from '../Checkbox';

import { InfoPageSettingsRecord, InfoPageStylesRecord } from '../../interfaces/lib-api-interfaces';

import api from '../../api-url';
import app from '../../appData';

// this does for settings what InfoPageEditor does for content: show dropdown to choose a settings template, then fetch, edit and post a template
// initial value can be passed via router state by setting page prop in state object to the current infoPage; if passed a "return to editor" button is shown

/* InfoPageSettingsRecord: (stored as json as named templates in InfoPage table; each domain maintains its own table)
    styles: InfoPageStylesRecord;
        Contains pseudo styles (e.g. "paragraphSpacing") that are translated into a style header record for InfoPage documents
            Support for translation is in infoPageStyles.ts which resides in general libs for use by apps displaying InfoPage docs:
                buildGlobalStyles() converts InfoPageStylesRecord into array of GlobalStyleRecord
                setGlobalStyles() then uses this array to create a style header object to embed in InfoPage documents
            Support for editing these styles is also in infoPageStyles.ts:
                globalStylesFields is array of fields suitable for passing to SamForm form editor
                globalStylesValues() extracts the default values for use when styles for a given InfoPage record are not specified
    image_options?: ImageEditorOptions;     // images disallowed if not passed
        editOptions?: ImageAttributesEditOptions;   (allow captions, allow resize, allow float)
        fileOptions?: ImageFileOptions;             (default location, default size)
  * Note regarding image_options editing:
        There are two objects contained within image_options; they are flattened for editing and unflattened ("split") for saving to DB
  * InfoPageSettingsEditor maintains all named templates for a single domain as defined in appData
  */


const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    height: 600px;
    width: 900px;
    font-family: Arial, sans-serif;
    h1 {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 0;
    }
`
const StyledSelect = styled.select`
    height: 32px;
    border: 1px solid #ccc;
`
// holds styles form and images form
const EditContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
`
const TitleAndFormContainer = styled.div<{ $marginLeft?: number; }>`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    ${props => props.$marginLeft ? ("margin-left: " + props.$marginLeft + "px") : null};
    width: 49%;
`

/* 
    the following must be valid in global files:
        appData.targetDomain
        api-url.getInfoPageSettingsNames
        api-url.getInfoPageSettings
        api-url.setInfoPageSettings
*/
interface InfoPageSettingsEditorModalProps {
    // all required for modal invokation
    initialName?: string;
    infoPageName?: string;
    onSubmit?: (settings: InfoPageSettingsRecord | null) => void;
}
export const InfoPageSettingsEditorModal: React.FC<InfoPageSettingsEditorModalProps> = (props) => {
    return (
        <SamModal>
            <InfoPageSettingsEditorComponent {...props} />
        </SamModal>
    )
}
const InfoPageSettingsEditor: React.FC = () => {
    return (
        <MasterPage>
            <InfoPageSettingsEditorComponent />
        </MasterPage>
    )
}
const InfoPageSettingsEditorComponent: React.FC<InfoPageSettingsEditorModalProps> = (props) => {
    const [settingsNames, setSettingsNames] = React.useState<string[]>();
    const [selectedName, setSelectedName] = React.useState<string>();
    const [loadSelected, setLoadSelected] = React.useState(false);
    const [settings, setSettings] = React.useState<InfoPageSettingsRecord>();   // if settings.imageOptions undefined, images are disallowed
    const [allowImagesChanged, setAllowImagesChanged] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { getToken } = useTokens();
    const { post } = usePostApi();
    const { fetch } = useFetchApi();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    React.useEffect(() => {
        setLoading(true);
        fetch(api.getInfoPageSettingsNames, getToken()!.token,
            (result: string[]) => {
                setLoading(false);
                setSettingsNames(result);
                setSelectedName(props.initialName ?? result[0]);
                setLoadSelected(true);
            }, () => alert(genericApiError));
    }, []);

    React.useEffect(() => {
        if (loadSelected) {
            setLoading(true);
            fetch(api.getInfoPageSettings + selectedName, getToken()!.token,
                (result: InfoPageSettingsRecord) => {
                    setLoading(false);
                    setLoadSelected(false);
                    if (!result.styles) {
                        result.styles = InfoPageStyles.globalStylesDefaultValues();
                    }
                    setSettings(result);
                    forms.initFieldsAndValues("styles", InfoPageStyles.globalStylesFields, result.styles);
                    if (result.image_options) {
                        forms.initFieldsAndValues("imageOptions", imageEditorOptionsFields, flattenImageEditorOptions(result.image_options));
                    }
                }, () => alert(genericApiError));
        }
    }, [loadSelected]);

    const handleSubmit = () => {
        saveAsSubmitted(selectedName!);
    }
    const handleCancel = () => {
        if (props.onSubmit) {
            props.onSubmit(null);
        } else {
            setLoadSelected(true);
        }
    }

    const saveAsSubmitted = (name: string) => {
        setSelectedName(name);
        const styles = forms.getFormValuesWithMedia("styles") as InfoPageStylesRecord;
        const image_options = splitImageEditorOptions(forms.getFormValues("imageOptions"));
        const newSettings: InfoPageSettingsRecord = { domain: settings!.domain, name, styles, image_options: settings!.image_options ? image_options : undefined };
        console.log({ settings, newSettings })
        if (allowImagesChanged || !deepEqual(settings, newSettings)) {
            setSettings(newSettings);
            setLoading(true);
            post(api.setInfoPageSettings, newSettings,
                () => {
                    setLoading(false);
                    if (!settingsNames!.includes(selectedName!)) {
                        setSettingsNames([...settingsNames!, selectedName!]);
                    }
                    setAllowImagesChanged(false);
                    props.onSubmit && props.onSubmit(newSettings);
                }, () => alert(genericApiError), getToken()!.token);
        } else {
            props.onSubmit && props.onSubmit(null);
        }
    }

    const settingsNameSelected = (e: React.ChangeEvent) => {
        setSelectedName((e.target as HTMLSelectElement).value);
        setLoadSelected(true);
    }

    const allowImagesCheckChanged = (isChecked: boolean) => {
        const newSettings = deepcopy(settings) as InfoPageSettingsRecord;
        if (isChecked) {
            newSettings.image_options = splitImageEditorOptions(imageEditorOptionsValues());
            forms.setFormValues("imageOptions", splitImageEditorOptions(imageEditorOptionsValues()));
        } else {
            delete newSettings.image_options;
        }
        setSettings(newSettings);
        setAllowImagesChanged(true);
    }

    console.log("InfoPageSettingsEditor settings:", settings)
    return (
        <Container>
            {loading && <Spinner />}
            <ButtonsRow>
                {settingsNames &&
                    <StyledSelect value={selectedName} onChange={settingsNameSelected}>
                        {settingsNames.map((name) => {
                            return (
                                <option key={name} value={name}>{name}</option>
                            );
                        })}
                    </StyledSelect>
                }
                <IconButton caption="Save" icon="fas fa-check" onClick={handleSubmit} />
                <IconButton caption="Cancel" icon="fas fa-ban" onClick={handleCancel} />
                <SaveAsBox onSubmit={saveAsSubmitted} />
            </ButtonsRow>
            {settings &&
                <EditContainer>
                    <TitleAndFormContainer>
                        <h1>Styles</h1>
                        <SimpleHzForm id={"styles"} />
                    </TitleAndFormContainer>
                    <TitleAndFormContainer $marginLeft={32}>
                        <h1>Image Options</h1>
                        <Checkbox style={{ marginTop: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}
                            checked={!!settings.image_options} caption="Allow images" checkedChanged={allowImagesCheckChanged} />
                        <SimpleHzForm hideForm={!settings.image_options} id={"imageOptions"} />
                    </TitleAndFormContainer>
                </EditContainer>
            }
        </Container>
    )
}

//-------------------------------------------------------

export default InfoPageSettingsEditor;
