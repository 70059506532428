import React from 'react';
import styled from 'styled-components';

import { StyledErrorText } from '../libs/libSupport';

import { CaptionOptionsEnum, GraphicDimensionType, ImageRecord } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import app from '../appData';
import SamImageGrid from '../libs/dashboard/SamImageGrid';

const MasterContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    margin-top: 16px;
`
const HelpText = styled.p`
    margin: 0 auto 4px auto;
    max-width: 900px;
`
const CarouselContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
    h2 {
        font-size: 18px;
        margin: 4px;
    }
`
interface SkuInfo {
    sku: string;
    caption: string
}
interface CarouselEditorProps {
    images: ImageRecord[];
    onChange: (newImages: ImageRecord[]) => void;
}
const CarouselEditor: React.FC<CarouselEditorProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>();

    return (
        <MasterContainer>
            {props.images &&
                <React.Fragment>
                    <h2>Carousel</h2>
                    <HelpText>
                        Drag and drop images to change the order. Enter a url to make them clickable (the url must be taken from the product or category url and only include the part
                        after "https://fernsgarden.com/"). Image should be 3x wider than tall. Recommended resolution: minimum 1500px x 500px.
                    </HelpText>
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <CarouselContainer>
                        <SamImageGrid id="carousel" images={props.images} direction="row" allowAddNewVideo={false}
                            onChange={props.onChange}
                            imageOptions={{
                                editOptions: { verifyUrlApiUrl: api.verifyUrl, captions: CaptionOptionsEnum.disallow, allowResize: false, allowFloat: false },
                                uploadOptions: { targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage },
                                fileOptions: { graphicsSubfolder: "carousel", size: 500, dimension: GraphicDimensionType.height }
                            }}
                        />
                    </CarouselContainer>
                </React.Fragment>
            }
        </MasterContainer>
    )
}
export default CarouselEditor;

/*
interface ImageListHandlerProps {
    id: string;         // must be unique if more than one set of images on page
    images: ImageRecord[];
    imageBoxWidth?: number;      // defaults to 300px; if direction is column this is width of component; if direction is row this is width of each element
    maxImages?: number;         // defaults to unlimited
    allowCaptions: boolean;
    verifyUrlApiUrl?: string;       // if omitted, links will not be allowed
    direction: string;  // row or column
    // following is height of each ROW or width of each COLUMN
    size?: number;       // defaults to 100px; if direction is row image width is fixed to this; if direction is column image height is fixed to this
    style?: Record<string, any>;
    fontSize?: number;  // defaults to 13px
    onChange: (images: ImageRecord[]) => void;
}

                                          {images!.map((image, index) => {
                        return (
      <ImageBox id={index + ''} key={index}>
                                <DDImage style={imageStyle} id="carousel" width={imageWidth} height={imageHeight} index={index} allowFileSystem={true}
                                    src={image.file ? URL.createObjectURL(image.file) : formatDashboardImageUrl(image.filename, "carousel")} handleDrop={handleDrop} />
                                <UrlRow>
                                    <span>Link to URL:&nbsp;</span><UrlInput value={images![index].url ? images![index].url : ''} onChange={urlChanged} />
                                </UrlRow>
                                <UrlRow>
                                    <IconButton caption="Verify" onClick={verifyUrlClicked} />
                                </UrlRow>
                                <UrlRow>
                                    {!images![index].url &&
                                        <span>To make this image clickable, enter a valid URL and click "Verify"</span>
                                    }
                                </UrlRow>
                            </ImageBox>
                        )
                    })}
                    <DDImage style={imageStyle} id="carousel" width={imageWidth} height={imageHeight} src={null} index={images!.length} allowFileSystem={true}
                        handleDrop={handleDrop} />
*/