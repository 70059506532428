import React from 'react';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import { getNearestParentId } from '../libs/libSupport';

import { FGCategoryTreeRecord, FGCategoryRecord, FGCategoryRecordProperty } from '../interfaces/fg-api-interfaces';

const MasterContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 8px;
    font-size: 14px;
    text-align: left;
    line-height: 30px;
    border: 1px solid;
    i {
        font-size: 24px;
    }
    p {
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`
const ListRow = styled.div<{ isIndented: boolean; backColor: string }>`
    margin-left: ${props => props.isIndented ? "24px" : 0};
    cursor: pointer;
    background-color: ${props => props.backColor};
`
const HelpText = styled.span`
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
`
// helper function to create array of selected category ids
export const extractSelections = (categoryTree: FGCategoryTreeRecord[]): number[] => {
    const selected = [] as number[];
    categoryTree.forEach(item => {
        if (item.isChecked) {
            selected.push(item.categoryId);
        }
        if (item.subcategories) {
            item.subcategories.forEach(subcat => {
                if (subcat.isChecked) {
                    selected.push(subcat.categoryId);
                }
            });
        }
    });
    return selected.sort((a, b) => a - b);
}
export const integrateSelections = (categoryTree: FGCategoryTreeRecord[], selections: number[]): FGCategoryTreeRecord[] => {
    categoryTree.forEach(item => {
        if (selections.includes(item.categoryId)) {
            item.isChecked = true;
        }
        if (item.subcategories) {
            item.subcategories.forEach(subcat => {
                if (selections.includes(subcat.categoryId)) {
                    subcat.isChecked = true;
                    item.subcategoryChecked = true;
                }
            })
        }
    });
    return categoryTree;
}
interface TreeRowIndexes {
    catIndex: number;
    subcatIndex: number;
}
enum EditCategoryCmdEnum { insertMain, insertSub, editCaption, editUrl }
// this does not allow for fetch and post; parent must do those
// to show checkboxes and allow selecting, pass selectedCategories to setCategoryData
interface CategoryEditorProps {
    categoryTree: FGCategoryTreeRecord[];
    categories: FGCategoryRecord;
    allowSelections: boolean;
    onChange: (categoryTree: FGCategoryTreeRecord[] | null, categories: FGCategoryRecord | null) => void;
}
const CategoryEditor: React.FC<CategoryEditorProps> = (props) => {
    const [editCategoryInfo, setEditCategoryInfo] = React.useState<{ command: EditCategoryCmdEnum; treeLocation: TreeRowIndexes }>();
    const [showContextMenu, setShowContextMenu] = React.useState<{ x: number; y: number; treeLocation: TreeRowIndexes }>();

    const propagateSelected = (treeData: FGCategoryTreeRecord[]) => {
        treeData.forEach(item => {
            if (item.subcategories) {
                item.subcategoryChecked = item.subcategories.some(popup => popup.isChecked);
            }
        });
    }

    enum ItemTagEnum { minusSign = "far fa-minus-square", plusSign = "far fa-plus-square", checked = "far fa-check-square", unchecked = "far fa-square" }
    const getItemTag = (item: FGCategoryTreeRecord): ItemTagEnum => {
        let tag = ItemTagEnum.unchecked;
        if (item.isChecked) {
            tag = ItemTagEnum.checked;
        } else if (item.isExpanded) {
            tag = ItemTagEnum.minusSign;
        } else if (item.subcategories) {
            tag = ItemTagEnum.plusSign;
        }
        return tag;
    }
    const rowClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        const id = getNearestParentId(e.target as HTMLElement).id;
        const newTree = deepcopy(props.categoryTree);
        const row = getRow(newTree, parseIndexes(id));
        const tag = getItemTag(row);
        console.log("row:", row); console.log("tag=" + tag)
        if (tag.includes("plus")) {
            row.isExpanded = true;         // expand the group
        } else if (tag.includes("minus")) {
            row.isExpanded = false;        // collapse the group
        } else if (props.allowSelections && tag.includes("check")) {
            row.isChecked = false;
        } else if (props.allowSelections) {
            console.log("checking row id" + row.categoryId)
            row.isChecked = true;
        }
        propagateSelected(newTree);
        console.log("onChange:", newTree)
        props.onChange(newTree, null);
    }
    // parse category/subcat indexes as given in id of div (e.g.: "1,2" -> [1,2])
    const parseIndexes = (indexes: string): TreeRowIndexes => {
        const parsed = indexes.split(',');
        return { catIndex: parseInt(parsed[0]), subcatIndex: parseInt(parsed[1]) };
    }
    const getRow = (tree: FGCategoryTreeRecord[], treeLocation: TreeRowIndexes): FGCategoryTreeRecord => {
        if (treeLocation.subcatIndex === -1) {
            return tree[treeLocation.catIndex];
        }
        return tree[treeLocation.catIndex].subcategories![treeLocation.subcatIndex];
    }

    return (
        <MasterContainer>
            <p>Categories</p>
            {props.categoryTree.map((item, catIndex) => {
                const tag = getItemTag(item);
                //       console.log(item.caption)
                // if item is expanded, show minus sign; if item is collapsed but has subcategories, show plus sign; if item is collapsed but has no subcats, show checkbox
                return (
                    <React.Fragment key={props.categories[item.categoryId].caption}>
                        <ListRow id={catIndex + ",-1"} backColor={item.subcategoryChecked ? "yellow" : "white"} isIndented={item.isIndented ?? false}
                            onClick={rowClicked}>
                            <i className={tag} />&nbsp;
                            {props.categories[item.categoryId].caption}
                        </ListRow>
                        {tag.includes("minus") && item.subcategories &&
                            item.subcategories!.map((popupItem, subcatIndex) => {
                                return (
                                    <ListRow key={props.categories[popupItem.categoryId].caption} id={catIndex + ',' + subcatIndex + ''} isIndented={popupItem.isIndented ?? false} 
                                        backColor="white"
                                        onClick={rowClicked}>
                                        {props.allowSelections &&
                                            <i style={{ marginRight: "4px" }} className={getItemTag(popupItem)} />
                                        }
                                        {props.categories[popupItem.categoryId].caption}
                                    </ListRow>
                                )
                            })
                        }
                    </React.Fragment>
                )
            })}
            <HelpText>To reconfigure menu use "Categories & menu" from menu bar</HelpText>
        </MasterContainer>
    );
}
export default CategoryEditor;
