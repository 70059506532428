import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import MasterPage from './MasterPage';
import { genericApiError, useFetchApi, usePostApi } from '../libs/useDataApiV2';
import { useTokens } from '../libs/SamState';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import MenuEditor from './MenuEditor';

import { FGCategoryRecord, FGCategoriesApiRecord } from '../interfaces/fg-api-interfaces';

import api from '../api-url';


const Categories: React.FC = () => {
    const [menuData, setMenuData] = React.useState<FGCategoriesApiRecord>();

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    // const gotCategories = (result: { categories: FGCategoryRecord, categoryTree: FGCategoryTreeRecord[] }) => {
    //     setCategoryTree(result.categoryTree);
    //     setCategories(result.categories);
    // }

    const gotMenuData = (result: FGCategoriesApiRecord) => {
        setMenuData(result);
    }

    React.useEffect(() => {
        fetch(api.getDashboardCategories, getToken()!.token, gotMenuData, () => alert(genericApiError));
    }, []);

    const menuSubmitted = (data: FGCategoriesApiRecord | null) => {
        if (data) {
            post(api.saveCategories, data,
                () => {
                    alert("Changes have been uploaded to server");
                    navigate("/");
                }, () => alert("Unable to upload changes to server"),
                getToken()!.token);
        }
    }

    return (
        <MasterPage>
            <h2>Edit Categories and Main Menu</h2>
            {menuData &&
                <React.Fragment>
                    <MenuEditor data={menuData} onSubmit={menuSubmitted} />
                </React.Fragment>
            }
        </MasterPage>
    )
}
export default Categories;

//                     <CategoryEditor categories={categories} categoryTree={categoryTree} allowSelections={false} onChange={handleChange} />
