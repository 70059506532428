import React from 'react';
import styled from 'styled-components';

import IconButton from '../IconButtonV2';

const SaveAsBoxContainer = styled.div`
    height: 32px;
    margin-left: 16px;
    margin-right: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
interface SaveAsBoxProps {
    onSubmit: (name: string) => void;
}
const SaveAsBox: React.FC<SaveAsBoxProps> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const saveAsClicked = () => {
        if (inputRef.current.value) {
            props.onSubmit(inputRef.current.value);
        } else {
            alert("Enter the name to save as");
        }
    }

    const keyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            saveAsClicked();
        }
    }

    return (
        <SaveAsBoxContainer>
            <IconButton caption="Save as:" icon="fa fa-floppy-o" onClick={saveAsClicked} />
            <input ref={inputRef} style={{ width: "180px", marginLeft: "2px" }} onKeyDown={keyDown} />
        </SaveAsBoxContainer>
    )
}
export default SaveAsBox;