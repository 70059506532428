import deepcopy from "deepcopy";

import DomEditor, { NodeTypeEnum } from "./DomEditor";
import { ImageRecord, StyleRecord } from '../../../interfaces/lib-api-interfaces';
import { ToolCodeEnum, ToolMapRecord, ToolbarCommandOptions } from "./StyleMgr";
import { createImageFromElement } from "../../ImageFormatter";
import UndoMgr from "./UndoMgr";

enum logFlags { none = 0, format = 1, map = 2, render = 4, renderElement = 8, select = 16, insert = 32, all = 255 }
const logLevel = logFlags.none;

export const writeLog = (logType: logFlags, text: string, obj: any) => {
    if ((logLevel & logType) !== 0) {
        console.log(text, obj);
    }
}

export const insertMarker = String.fromCharCode(131);

export interface TextMapRecord {
    index?: number;          // added by render for matching to anchor and focus indexes
    tag: string;            // allowed: p, a, #text, figure
    nodeId?: number;
    text?: string;
    styles?: StyleRecord;
    image?: ImageRecord;
    href?: string;
}
export interface TextSelectionRecord {
    // selection is isolated so offset is 0 or length of text
    anchorIndex: number;    // set by generateMap
    focusIndex: number;
    anchorNode?: Node;      // set from browser selection, updated when content is re-rendered
    focusNode?: Node;       // ditto
    anchorOffset: number;   // ditto; updated when content is mapped (selected text is isolated so offsets could change)
    focusOffset?: number;   // ditto
    isCollapsed: boolean;   // if true then cursor is at anchorOffset
    scrollX?: number;       // used only for undo records
    scrollY?: number;       // ditto
}
interface DocumentSelectionRecord {
    anchorNode: Node;
    anchorOffset: number;
    focusNode: Node;
    focusOffset: number;
    isCollapsed: boolean;
}
export interface DomFormatterProps {
    domEditor: DomEditor;
    onAfterRender: (ed: DomEditor) => void;
    onSelectionChanged: (ed: DomEditor) => void;
}

// this should be instantiated only once
// initSelection must be called after text has been added first time
export class DomFormatter {
    domEditor: DomEditor;
    onAfterRender: (ed: DomEditor) => void;
    onSelectionChanged: (ed: DomEditor) => void;
    supportedStyles: string[];
    undoMgr: UndoMgr;
    lastCommitTime: number;
    documentSelectionRange: Range;       // the last known selection
    lastTextContentLength: number;      // for deciding whether selection change is the result of text entry; if so maybe commit undo

    // active only while mapping:
    nodeId: number;
    textMap: TextMapRecord[];
    textSelection: TextSelectionRecord;

    constructor(props: DomFormatterProps) {
        this.domEditor = props.domEditor;
        this.onAfterRender = props.onAfterRender;
        this.onSelectionChanged = props.onSelectionChanged;
        this.supportedStyles = props.domEditor.styleMgr.supportedStyles;
        this.textMap = [];
        this.nodeId = 0;
        this.undoMgr = new UndoMgr();
        this.lastCommitTime = new Date().getTime();
        this.textSelection = {} as TextSelectionRecord;
        this.documentSelectionRange = {} as Range;
        this.lastTextContentLength = 0;
        document.addEventListener("selectionchange", () => {
            //      console.log("SELECTIONCHANGE:", document.getSelection(), "; innerHTML=" + this.domEditor.editorDiv.innerHTML);
            if (this.domEditor.editorDiv.innerHTML) {
                const lastAnchorNode = this.documentSelectionRange.startContainer;
                const textContentLength = lastAnchorNode.textContent?.length ?? 0;
                this._updateDocumentSelection();
                // determine if this is the result of entering one character; if so use timer to decide whether to commit
                if (this.documentSelectionRange.startContainer === lastAnchorNode && textContentLength > this.lastTextContentLength && new Date().getTime() - this.lastCommitTime > 1000) {
                    this.bookmarkAndMapSelection();     // force commit
                }
                this.lastTextContentLength = textContentLength;
                this.onSelectionChanged(this.domEditor);
            }
        });
    }
    initSelection = () => {
        this._updateDocumentSelection();
        //      console.log("DomFormatter: text before cursorToTop=" + this.domEditor.editorDiv.innerHTML)
        this.cursorToTop();
        this._updateDocumentSelection();
        //    console.log("about to bookmark and map:", this.domEditor.editorDiv.innerHTML)
        this.bookmarkAndMapSelection();     // do an initial commit
    }

    // #region UNDO
    // called from bookmarkAndMap
    commitUndo = () => {
        this.undoMgr.commit(this.textMap, this.textSelection);
        this.lastCommitTime = new Date().getTime();
    }

    rollbackUndo = () => {
        const undoRecord = this.undoMgr.rollback();
        if (undoRecord) {
            this.textMap = deepcopy(undoRecord.map);
            this.textSelection = { ...undoRecord.selection };
            this.renderFromTextMap();
            window.scrollTo(this.textSelection.scrollX!, this.textSelection.scrollY!);
        }
    }
    // #endregion UNDO

    // #region CURSOR AND SELECTION
    cursorToTop = () => {
        let anchorNode: Node | null = null;
        const iterator = document.createNodeIterator(this.domEditor.editorDiv);
        while (true) {
            const node = iterator.nextNode();
            if (!node) {
                break;
            }
            if (node.nodeType === NodeTypeEnum.text) {
                const elem = DomFormatter.findNearestElement(node, this.domEditor.editorDiv);
                if (!elem) {
                    continue;
                }
                if (DomFormatter.getAncestorElement(elem, "figure", this.domEditor.editorDiv)) {
                    continue;
                }
                anchorNode = node;
                break;
            }
        }
        if (!anchorNode) {
            // there is no text in the document -- highly unlikely since setHtml creates some
            const graf = document.createElement("p");
            const textNode = document.createTextNode(insertMarker);
            graf.appendChild(textNode);
            this.domEditor.editorDiv.appendChild(graf);
            anchorNode = textNode;
        }
        //      console.log("cursorToTop setting selection to anchor:", anchorNode)
        this._setBaseAndExtent(anchorNode, 0);
        //      console.log("cursorToTop RANGE after setting selection:", document.getSelection()!.getRangeAt(0).startContainer.cloneNode());
    }
    // following assumes content has been rendered and places the document selection at end of doc
    cursorToBottom = () => {
        this._setBaseAndExtent(this.domEditor.editorDiv, this.domEditor.editorDiv.childNodes.length);
    }
    // following assume document has been mapped and adjusts the selection to end of last text node
    _cursorToEndOfText = () => {
        this.textSelection.anchorIndex = this.textMap.length - 1;
        while (this.textSelection.anchorIndex > 0) {
            if (this.textMap[this.textSelection.anchorIndex].tag === "#text") {
                break;
            }
            this.textSelection.anchorIndex--;
        }
        this.textSelection.focusIndex = this.textSelection.anchorIndex;
        this.textSelection.anchorOffset = this.textSelection.focusOffset = (this.textMap[this.textSelection.anchorIndex].text ?? '').length;
        this.textSelection.isCollapsed = true;
    }
    // following is for collapsed selections only
    _setBaseAndExtent = (node: Node, offset: number) => {
        const sel = document.getSelection() as Selection;
        sel.setBaseAndExtent(node, offset, node, offset);
        this.documentSelectionRange = sel.getRangeAt(0);
        this.domEditor.setCursorStyles(sel!.getRangeAt(0).startContainer);
    }
    _updateDocumentSelection = (): Selection => {
        let selection = document.getSelection() as Selection;
        //     console.log("_updateDocumentSelection: initial selection:", selection)
        if (!selection || !selection.anchorNode) {
            this.cursorToTop();
            selection = document.getSelection() as Selection;
            console.log("_updateDocumentSelection after cursorToTop selection is:", selection)
        }
        //       console.log("_update... anchorNode:", selection!.anchorNode!.cloneNode(), "; _isNodeInEditor=" + this._isNodeInEditor(selection!.anchorNode));
        if (this._isNodeInEditor(selection!.anchorNode)) {
            //         console.log("_updateDocumentSelection: anchorOffset from " + this.documentSelectionRange.startOffset + " to " + selection.getRangeAt(0)!.startOffset + ":",
            //             { from: this.documentSelectionRange, to: selection.getRangeAt(0) });
            this.documentSelectionRange = selection.getRangeAt(0);
            this.domEditor.setCursorStyles(selection!.getRangeAt(0).startContainer);
        }
        return selection!;
    };

    isSelectionCollapsed = (): boolean => {
        return this.documentSelectionRange.startContainer === this.documentSelectionRange.endContainer && this.documentSelectionRange.startOffset === this.documentSelectionRange.endOffset;
    }

    _isNodeInEditor = (node: Node | null): boolean => {
        //    console.log("_isNodeInEditor: node:", node?.cloneNode(), "; node.parentNode:", node!.parentNode?.cloneNode());
        while (node && node !== this.domEditor.editorDiv) {
            // console.log("node.parentElement:", node.parentElement)
            node = node.parentElement;
        }
        //     console.log("_isNodeInEditor ending node:", node?.cloneNode())
        return (!!node && node === this.domEditor.editorDiv);
    }


    static getAncestorElement = (elem: Node | null, tag: string, root: HTMLElement): HTMLElement | null => {
        tag = tag.toUpperCase();
        while (elem && elem.nodeName !== tag && elem !== root) {
            elem = elem.parentElement;
        }
        return (!!elem && elem.nodeName === tag ? elem : null) as HTMLElement;
    }
    static findNearestElement = (node: Node, root: HTMLElement): HTMLElement | null => {
        let elem = node as HTMLElement;
        while (elem && elem !== root && elem.nodeType !== NodeTypeEnum.block) {
            elem = elem.parentElement!;
        }
        return elem === root ? null : elem;
    }
    static findNearestParagraphElement = (node: Node, root: HTMLElement): HTMLElement | null => {
        let elem = node as HTMLElement;
        while (elem && elem !== root && !this.isParagraphTag(elem.nodeName.toLowerCase())) {
            elem = elem.parentElement!;
        }
        return elem === root ? null : elem;
    }
    static isParagraphTag = (tag?: string): boolean => {
        if (!tag) {
            return false;
        }
        tag = tag.toLowerCase();
        return (tag === 'div' || tag === "p" || tag.startsWith("h"));
    }
    _scrollSelectionInfoView = () => {
        const elem = DomFormatter.findNearestElement(this.documentSelectionRange.startContainer!, this.domEditor.editorDiv);
        elem!.scrollIntoView();
    }
    // the following will properly initialize textSelection from document selection
    bookmarkAndMapSelection = () => {
        const range = this.documentSelectionRange;
        //    console.log("BOOKMARK:", range);
        this.textSelection.isCollapsed = this.isSelectionCollapsed();
        if (this.textSelection.isCollapsed) {
            writeLog(logFlags.select, "bookmark: selection collapsed on:", range.startContainer);
            if (range.startContainer.nodeType !== NodeTypeEnum.text) {
                // find and select the next text node
                let textNode = this._findNextTextNode(range.startContainer);
                if (!textNode) {
                    // no more text in document so create a text node
                    writeLog(logFlags.select, "bookmark: startContainer not text and no text after startContainer; creating text node; startContainer:", range.startContainer);
                    textNode = document.createTextNode(insertMarker);
                    (range.startContainer as HTMLElement).insertBefore(textNode, range.startContainer.firstChild);
                }
                const sel = document.getSelection()!;
                sel.setBaseAndExtent(textNode, 0, textNode, 0);
                writeLog(logFlags.select, "doc selection after adding or finding text node:", document.getSelection());
                this.textSelection.anchorNode = this.textSelection.focusNode = textNode;
                this.textSelection.anchorOffset = this.textSelection.focusOffset = 0;
            } else {
                writeLog(logFlags.select, "setting anchorNode to:", range.startContainer);
                this.textSelection.anchorNode = this.textSelection.focusNode = range.startContainer;
                this.textSelection.anchorOffset = this.textSelection.focusOffset = range.startOffset;
            }
            //    sel.anchorNode!.textContent = sel.anchorNode!.textContent?.substring(0, sel.anchorOffset) + cursorMarker + sel.anchorNode!.textContent?.substring(sel.anchorOffset);
        } else {
            let anchorNode = range.startContainer;
            let anchorOffset = range.startOffset;
            if (anchorNode!.nodeType !== NodeTypeEnum.text) {
                // find "true" focus node; focus will be at end of this node
                anchorNode = anchorNode!.childNodes[anchorOffset!];
                // if this is a text node the offset matters
                anchorOffset = anchorNode.nodeType === NodeTypeEnum.text ? 0 : -1;
            }

            let focusNode = range.endContainer;
            let focusOffset = range.endOffset;
            if (focusNode!.nodeType !== NodeTypeEnum.text) {
                // find "true" focus node; focus will be at end of this node
                focusNode = focusNode!.childNodes[focusOffset! - 1];
                // if this is a text node the offset matters
                focusOffset = focusNode.nodeType === NodeTypeEnum.text ? focusNode.textContent!.length : -1;
            }
            writeLog(logFlags.select, "before iterate:", { anchorNode, anchorOffset, focusNode, focusOffset });
            if (anchorOffset === -1) {
                const textNode = this._findNextTextNode(anchorNode);
                if (textNode) {
                    anchorNode = textNode;
                    anchorOffset = 0;
                }
            }
            if (focusOffset === -1) {
                const textNode = this._findNextTextNode(focusNode);
                if (textNode) {
                    focusNode = textNode;
                    focusOffset = textNode.textContent!.length;
                }
            }
            /* this works, replaced by _findNextTextNode
            if (anchorOffset === -1 || focusOffset === -1) {
                // one or both selected nodes are not text -- iterate and find the nearest text nodes
                const iterator = document.createNodeIterator(this.domEditor.editorDiv);
                let pastAnchor = false;
                let pastFocus = false;
                while (true) {
                    const node = iterator.nextNode();
                    if (!node) {
                        break;
                    }
                    if (anchorOffset === -1) {
                        if (pastAnchor && node.nodeType === NodeTypeEnum.text) {
                            anchorNode = node;
                            anchorOffset = 0;
                        } else if (node === anchorNode) {
                            pastAnchor = true;
                        }
                    }
                    if (focusOffset === -1) {
                        if (pastFocus && node.nodeType === NodeTypeEnum.text) {
                            focusNode = node;
                            focusOffset = node.textContent!.length;
                        } else if (node === focusNode) {
                            pastFocus = true;
                        }
                    }
                }
            }
            */
            writeLog(logFlags.select, "after iterate:", { anchorNode, anchorOffset, focusNode, focusOffset });
            if (anchorOffset === -1) {
                throw "bookmarkSelection: Unable to find a text anchor node";
            }
            if (focusOffset === -1) {
                throw "bookmarkSelection: Unable to find a text focus node";
            }

            this.textSelection.anchorNode = anchorNode;
            this.textSelection.focusNode = focusNode;
            this.textSelection.anchorOffset = anchorOffset;
            this.textSelection.focusOffset = focusOffset;
            // focusNode.textContent = focusNode.textContent!.substring(0, focusOffset) + endMarker + focusNode.textContent!.substring(focusOffset);
            // anchorNode.textContent = anchorNode.textContent!.substring(0, anchorOffset) + startMarker + anchorNode.textContent!.substring(anchorOffset);
        }
        this.generateTextMap();
        this.commitUndo();
    }

    _findNextTextNode = (startNode: Node): Node | null => {
        const iterator = document.createNodeIterator(this.domEditor.editorDiv);
        let pastNode = false;
        while (true) {
            const node = iterator.nextNode();
            if (!node) {
                return null;
            }
            if (pastNode && node.nodeType === NodeTypeEnum.text) {
                return node;
            } else if (node === startNode) {
                pastNode = true;
            }
        }
    }

    // return true if no text between start of paragraph and start of selection
    // map must be current before calling (use bookmarkAndMapSelection)
    _isSelectionAtStartOfParagraph = (): boolean => {
        if (this.textSelection.anchorOffset > 0) {
            return false;
        }
        let index = this.textSelection.anchorIndex - 1;
        while (index > 0 && !DomFormatter.isParagraphTag(this.textMap[index].tag)) {
            if (this.textMap[index].tag === "#text") {
                return false;
            }
        }
        return true;        // hit paragraph before text
    }

    _getNextNodeId = (): number => {
        let maxId = 0;
        for (const entry of this.textMap) {
            maxId = Math.max(entry.nodeId ?? 0, maxId);
        }
        return maxId + 1;
    }
    // #endregion CURSOR AND SELECTION

    // #region MAPPER
    // text map is only generated before applying formatting
    // selection must be marked with startMarker and endMarker
    // mapper guarantees that selection nodes will be isolated
    generateTextMap = () => {
        writeLog(logFlags.map, "before generateTextMap: selection:", this.textSelection);
        this.textMap = [];
        this.nodeId = 0;
        this._mapTextNode(this.domEditor.editorDiv, this.domEditor.styleMgr.getComputedStyles(this.domEditor.editorDiv));
        writeLog(logFlags.map, "after generateTextMap:", { selection: this.textSelection, map: deepcopy(this.textMap) });
    }
    _mapTextNode = (node: Node, parentStyles: StyleRecord) => {
        // writeLog(logFlags.map, "_mapTextNode:", node);
        // for text map we only want paragraphs, text nodes, images and links
        const isValidTextMapTag = (tagName: string): boolean => {
            return DomFormatter.isParagraphTag(tagName) || ["figure", "img", "a"].includes(tagName);
        }
        const tagName = node.nodeName.toLowerCase();
        const styles = this.domEditor.styleMgr.getComputedStyles(node);     // become parent styles
        const uniqueStyles = this.domEditor.styleMgr.diffStyles(parentStyles, styles) ?? {};
        //   this.domEditor.styleMgr.filterStylesByParagraph(styles, DomFormatter.isParagraphTag(tagName));
        let nodeId = 0;
        //   writeLog(logFlags.map, "filteredStyles:", styles);
        //  let nodeId = ++this.nodeId;
        if (node.nodeType === NodeTypeEnum.text) {
            // split the text node if selection is not collapsed
            const { pre, text, post, isAnchor, isFocus, anchorOffset, focusOffset } = this._splitTextOnMarkers(node as Text);
            if (isAnchor || isFocus) {
                writeLog(logFlags.map, "splitText returns:", { pre, text, post, isAnchor, isFocus, anchorOffset, focusOffset });
            }
            if (pre) {
                this.textMap.push({ tag: tagName, text: pre, styles: { ...uniqueStyles } });
            }
            const textRecord: TextMapRecord = { tag: "#text", text, styles: { ...uniqueStyles } };
            if (isAnchor) {
                this.textSelection.anchorIndex = this.textMap.length;
            }
            if (isFocus) {
                this.textSelection.focusIndex = this.textMap.length;
            }
            this.textMap.push(textRecord);
            if (post) {
                this.textMap.push({ tag: tagName, text: post, styles: { ...uniqueStyles } });
            }
            if (anchorOffset !== -1) {
                this.textSelection.anchorOffset = anchorOffset;
            }
            if (focusOffset !== -1) {
                this.textSelection.focusOffset = focusOffset;
            }
        } else if (isValidTextMapTag(tagName)) {
            nodeId = ++this.nodeId;
            const record = { tag: tagName, styles: uniqueStyles, nodeId } as TextMapRecord;
            if (tagName === 'a') {
                record.href = (node as HTMLElement).getAttribute("href") ?? (node as HTMLElement).getAttribute("data-href") ?? '';
            }
            if (tagName === 'a' || tagName === 'h1') {
                // these have styles determined by global styles
                record.styles = {};
            } else if (tagName === 'img' || tagName === "figure") {
                //  console.log((node as HTMLElement).innerHTML);
                record.tag = 'figure';
                const image = createImageFromElement(node as HTMLElement);
                if (image) {
                    record.image = image;
                }
                this.textMap.push(record);
                //     writeLog(logFlags.map, "pushing figure record:", deepcopy(record));
                return;
            }
            this.textMap.push(record);
        }
        // here is where we recurse through this element
        for (let i = 0; i < node.childNodes.length; i++) {
            this._mapTextNode(node.childNodes[i], tagName === "span" ? parentStyles : styles);  // ignore span "diffed" styles; pass parent styles down to text nodes
        }
        if (nodeId) {
            this.textMap.push({ tag: '/' + tagName, nodeId: -nodeId });
        }
    }

    _splitTextOnMarkers = (node: Text): { pre: string; text: string; post: string; isAnchor: boolean; isFocus: boolean; anchorOffset: number; focusOffset: number } => {
        let pre = '';
        let post = '';
        let textContent = node.textContent!;
        let text = textContent;
        let anchorOffset = -1;
        let focusOffset = -1;
        let isAnchor = this.textSelection.anchorNode === node;
        let isFocus = this.textSelection.focusNode === node;
        if (isAnchor) {
            if (this.textSelection.isCollapsed) {
                // isAnchor and isFocus will be true; both offsets should be valid
                anchorOffset = focusOffset = this.textSelection.anchorOffset;
            } else {
                pre = textContent.substring(0, this.textSelection.anchorOffset);
                if (isFocus) {
                    post = textContent.substring(this.textSelection.focusOffset!);
                    text = textContent.substring(this.textSelection.anchorOffset, this.textSelection.focusOffset!);
                    anchorOffset = 0;
                    focusOffset = text.length;
                } else {
                    text = textContent.substring(this.textSelection.anchorOffset);
                    anchorOffset = 0;
                }
            }
        }
        else if (isFocus) {
            // selection must not be collapsed here; this record contains focus only (not anchor)
            post = textContent.substring(this.textSelection.focusOffset!);
            text = textContent.substring(0, this.textSelection.focusOffset!);
            focusOffset = text.length;
        }
        return { pre, text, post, isAnchor, isFocus, anchorOffset, focusOffset };
    }

    // map must have valid anchor/focus/cursorOffset fields embedded; the document selection is set from these after rendering
    renderFromTextMap = () => {
        this.domEditor.editorDiv.innerHTML = '';
        for (let i = 0; i < this.textMap.length; i++) {
            this.textMap[i].index = i;
        }
        writeLog(logFlags.render, "about to render from:", { map: deepcopy(this.textMap), selection: this.textSelection });
        this._renderElementFromTextMap(this.domEditor.editorDiv, this.textMap);
        const newSel = window.getSelection() as Selection;
        writeLog(logFlags.render, "restoring selection:", this.textSelection);
        //     newSel.setBaseAndExtent(this.textSelection.anchorNode!, this.textSelection.anchorOffset, this.textSelection.focusNode!, this.textSelection.focusOffset!);
        newSel.setBaseAndExtent(this.textSelection.anchorNode!, 0, this.textSelection.focusNode!, 0);
        writeLog(logFlags.render, "selection after restoring:", window.getSelection());
        this.onAfterRender(this.domEditor);
    }
    // first and last elements assumed to be parent open and close (close is optional)
    _renderElementFromTextMap = (root: HTMLElement, map: TextMapRecord[]) => {
        writeLog(logFlags.renderElement, "_renderElementFromTextMap:", { selection: this.textSelection, map });
        for (let i = 1; i < map.length && map[i].nodeId !== -map[0].nodeId!; i++) {
            if (map[i].tag === "figure") {
                const fig = this.domEditor.imageMgr.createFigureElementFromImage(map[i].image!);
                root.appendChild(fig);
            } else if (map[i].tag !== "#text") {
                // collect the children of this block level element and render it with its kids
                const childNodeMap = [];
                for (let j = i; j < map.length && map[j].nodeId !== -map[i].nodeId!; j++) {
                    childNodeMap.push(map[j]);
                }
                // render element with children here
                const elem = document.createElement(map[i].tag);
                if (map[i].tag === 'a') {
                    elem.setAttribute("data-href", map[i].href!);
                    if (map[i].href!.startsWith("http")) {
                        elem.setAttribute("target", "_blank");
                        elem.setAttribute("rel", "noopener noreferrer");
                    }
                }
                this._setStyles(elem, map[i].styles!);
                this._renderElementFromTextMap(elem, childNodeMap);
                root.appendChild(elem);
                //      console.log("i=" + i + "; childmap length=" + childNodeMap.length + "; map length=" + map.length)
                i += childNodeMap.length;       // move past the children we just rendered
            } else {
                writeLog(logFlags.render, "rendering text node #", map[i].index);
                let addedTextNode: Node;
                // get the styles that would be in effect if we just add the text node
                const currentStyles = this.domEditor.styleMgr.getCursorStyles(root);
                //        console.log("adding text node to element: element styles are:", currentStyles, "; map styles are:", map[i].styles);
                const diffStyles = this.domEditor.styleMgr.diffStyles(currentStyles, map[i].styles!);
                if (diffStyles) {
                    // the text node has something different so enclose it in a span
                    const span = document.createElement("span");
                    this._setStyles(span, diffStyles);
                    if (map[i].text === "&nbsp;") {
                        span.innerHTML = map[i].text!;
                    } else {
                        span.textContent = map[i].text!;
                    }
                    root.appendChild(span);
                    addedTextNode = span.childNodes[0];
                } else {
                    const textNode = document.createTextNode(map[i].text!);
                    root.appendChild(textNode);
                    addedTextNode = textNode;
                }
                if (map[i].index === this.textSelection.anchorIndex) {
                    writeLog(logFlags.render, "render setting anchorNode to:", addedTextNode);
                    this.textSelection.anchorNode = addedTextNode;
                }
                if (map[i].index === this.textSelection.focusIndex!) {
                    writeLog(logFlags.render, "render setting focusNode to:", addedTextNode);
                    this.textSelection.focusNode = addedTextNode;
                }
            }
        }
    }
    _setStyles = (element: HTMLElement, styles: StyleRecord) => {
        for (const prop in styles) {
            if (prop !== "link" && prop != "figure") {
                element.style.setProperty(prop, styles[prop]);
            }
        }
    }

    // #endregion MAPPER

    // #region APPLY AND REMOVE FORMATTING
    // process all tools except links and images
    // if code is just ToolCodeEnum is was chosen from a context menu and not directly off the toolbar (e.g., "clean")
    //      context codes are nondefaultable, not emphasis and not vertical spacing
    // "defaultable" tools can be applied to future text entry (like bold/font-size/color)
    // "nondefaultable" tools can only be applied to text selections (like alignment, clean)
    // "emphasis" tools (bold/underline/italic) are always toggled from current cursor style
    // vertical spacing is for tool code "fontSpacing" -- it is defaultable and value must be passed as a VerticalSpacingRecord
    applyFormatting = (styles: StyleRecord, toolRecord: ToolMapRecord) => {
        writeLog(logFlags.format, "APPLYFORMATTING:", { styles, toolRecord, map: deepcopy(this.textMap), selection: this.textSelection });
        this.bookmarkAndMapSelection();
        writeLog(logFlags.format, "applyFormatting before apply:", { map: deepcopy(this.textMap), selection: this.textSelection });
        let savedSelection: TextSelectionRecord | null = null;
        if (this.isSelectionCollapsed()
            && (toolRecord.options & ToolbarCommandOptions.isDefaultable) !== 0) {
            // this is a defaultable tool -- insert a span with correct styling
            this._insertSpanAtSelection(styles);
            // if selection collapsed and non defaultable tool clicked, ignore the tool click
        } else {
            // the only way here if selection is collapsed is if tool is non-defaultable which means paragraph level
            const isParagraphTool = (toolRecord.options & ToolbarCommandOptions.isParagraphLevel) !== 0;
            if (isParagraphTool) {
                // expand the range to set style without expanding the physical selection
                savedSelection = { ...this.textSelection };     // save the pre-expanded selection for restoring before render
                while (this.textSelection.anchorIndex >= 0 && !DomFormatter.isParagraphTag(this.textMap[this.textSelection.anchorIndex].tag)) {
                    this.textSelection.anchorIndex--;
                }
                while (this.textSelection.focusIndex < this.textMap.length - 1 && !this.textMap[this.textSelection.focusIndex].tag.startsWith('/')
                    && !DomFormatter.isParagraphTag(this.textMap[this.textSelection.focusIndex].tag.substring(1))) {
                    this.textSelection.focusIndex++;
                }
            }
            writeLog(logFlags.format, "before applyformat loop:", { selection: this.textSelection, map: deepcopy(this.textMap) })
            for (let i = this.textSelection.anchorIndex; i <= this.textSelection.focusIndex!; i++) {
                if (this.textMap[i].styles) {
                    const isParagraphNode = DomFormatter.isParagraphTag(this.textMap[i].tag);
                    if ((isParagraphNode && isParagraphTool) || (!isParagraphNode && !isParagraphTool)) {
                        for (const style in styles) {
                            this.textMap[i].styles![style] = styles[style];
                        }
                    }
                }
            }
        }
        if (savedSelection) {
            this.textSelection = { ...savedSelection };
        }
        writeLog(logFlags.format, "after apply, before rerender:", { map: deepcopy(this.textMap), anchor: this.textMap[this.textSelection.anchorIndex], focus: this.textMap[this.textSelection.focusIndex!] });
        this.renderFromTextMap();   // render and restore selection in browser
    }

    _cleanText = (code: ToolCodeEnum) => {
        this.bookmarkAndMapSelection();
        let deletions = false;
        for (let i = this.textSelection.anchorIndex; i <= this.textSelection.focusIndex!; i++) {
            if (this.textMap[i].styles) {
                if (code === ToolCodeEnum.cleanAll || code === ToolCodeEnum.cleanAllExceptLinks) {
                    this.textMap[i].styles = {};
                    if (code !== ToolCodeEnum.cleanAllExceptLinks && (this.textMap[i].tag === 'a' || this.textMap[i].tag === '/a')) {
                        this.textMap[i].tag = '//';     // mark for deletion if we're cleaning links also
                        deletions = true;
                    }
                } else if (code === ToolCodeEnum.cleanColors) {
                    const newStyles = { ...this.textMap[i].styles };
                    delete newStyles["color"];
                    if (newStyles["text-decoration"]?.startsWith("rgb")) {
                        delete newStyles["text-decoration"];
                    }
                    this.textMap[i].styles = newStyles;
                } else if (code === ToolCodeEnum.cleanFontFamily) {
                    const newStyles = { ...this.textMap[i].styles };
                    delete newStyles["font-family"];
                    this.textMap[i].styles = newStyles;
                }
            }
        }
        if (deletions) {
            const newMap = [];
            for (const entry of this.textMap) {
                if (entry.tag !== '//') {
                    newMap.push(deepcopy(entry));
                }
            }
            this.textMap = newMap;
        }
        this.renderFromTextMap();
    }
    // #endregion APPLY AND REMOVE FORMATTING

    // #region INSERT AND DELETE
    // called from paste; if text has no paragraphs and cursor is in mid-text, text is inserted into text node; else text is inserted as new paragraph
    // returns element pasted (if an element was created from text)
    insertTextAtSelection = (text: string, literal: boolean): HTMLElement | null => {
        if (!text.includes("<") && !text.includes(">") && this.documentSelectionRange.startContainer.nodeType === NodeTypeEnum.text) {
            // special case where we insert into mid text node
            const orgOffset = this.documentSelectionRange.startOffset;
            let textContent = this.documentSelectionRange.startContainer.textContent!;
            textContent = textContent.substring(0, this.documentSelectionRange.startOffset) + text + textContent.substring(this.documentSelectionRange.startOffset);
            this.documentSelectionRange.startContainer.textContent = textContent;
            const sel = document.getSelection() as Selection;
            this._setBaseAndExtent(this.documentSelectionRange.startContainer, orgOffset + text.length);
        } else {
            const graf = document.createElement("p");
            if (literal) {
                graf.innerText = text;
            } else {
                graf.innerHTML = text;
            }
            this.insertElementAtSelection(graf);
            return graf;
        }
        return null;
    }

    // assumes selection is collapsed and inserts given span at cursor, placing document selection on it
    // used for toggling style while entering text; given span must have a text node inside it
    // bookmarkAndMap should be called first, renderTextFromMap after
    _insertSpanAtSelection = (styles: StyleRecord) => {
        const text = this.textMap[this.textSelection.anchorIndex].text!;
        const orgStyles = { ...this.textMap[this.textSelection.anchorIndex].styles };
        const newStyles = { ...orgStyles };
        for (const style in styles) {
            newStyles[style] = styles[style];
        }
        const record1 = { tag: "#text", text: text.substring(0, this.textSelection.anchorOffset), styles: { ...orgStyles } };
        const record2 = { tag: "#text", text: insertMarker, styles: newStyles };
        const record3 = { tag: "#text", text: text.substring(this.textSelection.anchorOffset!), styles: orgStyles };
        this.textMap.splice(this.textSelection.anchorIndex, 1, record1, record2, record3);
        this.textSelection.anchorIndex++;
        this.textSelection.focusIndex = this.textSelection.anchorIndex;
        this.textSelection.anchorOffset = this.textSelection.focusOffset = 0;
    }

    // if we are at start of paragraph insert before the paragraph
    // if we are in mid paragraph, split the paragraph and insert between
    // assume selection is collapsed
    insertElementAtSelection = (element: HTMLElement) => {
        this.bookmarkAndMapSelection();
        if (!this._isSelectionAtStartOfParagraph()) {
            this._splitParagraph();         // leave anchor at top of paragraph that got split off
        }
        // textSelection now points to a text node at the start of a paragraph
        let grafElem = DomFormatter.findNearestParagraphElement(document.getSelection()!.anchorNode!, this.domEditor.editorDiv);
        console.log("nearest graf element:", grafElem)
        if (!grafElem) {
            // no paragraphs in doc (this should not be possible) so insert at top of doc
            grafElem = this.domEditor.editorDiv.firstElementChild as HTMLElement;
        }
        console.log("grafElem:", grafElem);
        grafElem.parentElement!.insertBefore(element, grafElem);
        this._setBaseAndExtent(grafElem, 0);
//console.log("insertElement: after setting selection:", this.tex)
        // this should leave the selection just past the inserted element
        this._scrollSelectionInfoView();
    }
    // split into 2 paragraphs at current anchor
    // return with browser selection anchor pointing to start of "new" paragraph
    // CAUTION: this puts map and DOM out of sync; must do a re-render and re-map after this
    // NOTE: bookmarkAndMapSelection must be called before this
    // NOTE: call this only if cursor is NOT at the start of the paragraph
    _splitParagraph = () => {
        // insert a selected marker so after map it will be in its own node
        // then replace the selected node with end/start paragraphs in the map, then re-render
        // split the text into 2 nodes with anchor on second one
        const text = this.textMap[this.textSelection.anchorIndex].text!;
        const text1 = text.substring(0, this.textSelection.anchorOffset);
        const text2 = text.substring(this.textSelection.anchorOffset);
        const styles = this.textMap[this.textSelection.anchorIndex].styles!;
        const record1: TextMapRecord = { tag: "#text", text: text1, styles: { ...styles } };
        const record2: TextMapRecord = { tag: "#text", text: text2, styles: { ...styles } };
        this.textMap.splice(this.textSelection.anchorIndex, 1, record1, record2);
        this.textSelection.anchorIndex++;
        this.textSelection.focusIndex = this.textSelection.anchorIndex;
        // anchor and focus indexes now point to our node
        // locate current paragraph so we can copy it as new paragraph
        let index = this.textSelection.anchorIndex;
        while (index >= 0 && !DomFormatter.isParagraphTag(this.textMap[index].tag)) {
            index--;
        }
        const endGraf: TextMapRecord = { tag: "/" + this.textMap[index].tag, nodeId: -this.textMap[index].nodeId! };
        const startGraf: TextMapRecord = { tag: this.textMap[index].tag, nodeId: this._getNextNodeId(), styles: { ...this.textMap[index].styles } };
        this.textMap.splice(this.textSelection.anchorIndex, 1, endGraf, startGraf);
        // update closing nodeId at end of spliced paragraph
        index = this.textSelection.anchorIndex + 1;
        writeLog(logFlags.select, "looking for end of endGraf: start index=" + index + ", map:", this.textMap);
        // index is now pointing to start of second graf; the first text node here becomes the new anchor with selection collapsed
        while (index < this.textMap.length && this.textMap[index].nodeId !== endGraf.nodeId) {
            if (this.textMap[index].tag === "#text") {
                this.textSelection.anchorIndex = this.textSelection.focusIndex = index;
                this.textSelection.anchorOffset = this.textSelection.focusOffset = 0;
            }
            index++;
        }
        this.textMap[index].nodeId = -startGraf.nodeId!;
        this.textSelection.isCollapsed = true;
        this.renderFromTextMap();
    }

    insertLink = (href: string): HTMLAnchorElement => {
        this.bookmarkAndMapSelection();
        const orgAnchorIndex = this.textSelection.anchorIndex;
        const linkedMap = this._wrapTextWithLinks(href, this.textSelection.anchorIndex, this.textSelection.focusIndex);
        this.textMap.splice(this.textSelection.anchorIndex, this.textSelection.focusIndex - this.textSelection.anchorIndex + 1, ...linkedMap);
        // move anchor to the first text node of the link and focus to last text node
        while (this.textSelection.anchorIndex < this.textMap.length && this.textMap[this.textSelection.anchorIndex].tag !== "#text") {
            this.textSelection.anchorIndex++;
        }
        this.textSelection.focusIndex = orgAnchorIndex + linkedMap.length - 1;
        while (this.textSelection.focusIndex > 0 && this.textMap[this.textSelection.focusIndex].tag !== "#text") {
            this.textSelection.focusIndex--;
        }
        this.textSelection.focusOffset = this.textMap[this.textSelection.focusIndex].text!.length;
        this.renderFromTextMap();
        return DomFormatter.getAncestorElement(this.textSelection.anchorNode!, "a", this.domEditor.editorDiv) as HTMLAnchorElement;
    }
    _wrapTextWithLinks = (href: string, startIndex: number, endIndex: number): TextMapRecord[] => {
        const target: TextMapRecord[] = [];
        let nextNodeId = this._getNextNodeId();
        for (let i = startIndex; i <= endIndex; i++) {
            if (this.textMap[i].tag === "#text") {
                target.push({ tag: "a", styles: {}, href, nodeId: nextNodeId });
                target.push(deepcopy(this.textMap[i]));
                target.push({ tag: "/a", nodeId: -nextNodeId });
                nextNodeId++;
            } else {
                target.push(deepcopy(this.textMap[i]));
            }
        }
        return target;
    }
    removeLink = (linkElem: HTMLAnchorElement) => {
        // change the link to a span
        const span = document.createElement('span');
        span.innerHTML = linkElem.innerHTML;
        linkElem.parentElement!.replaceChild(span, linkElem);
    }

    deleteSelection = () => {
        this.bookmarkAndMapSelection();
        const anchorAtTopOfGraf = this._isSelectionAtStartOfParagraph();
        const newSelection = this._removeTextNodes(this.textSelection.anchorIndex, this.textSelection.focusIndex);
        this.textMap.splice(this.textSelection.anchorIndex, this.textSelection.focusIndex - this.textSelection.anchorIndex + 1, ...newSelection);
        // new anchor is first text node after deletion
        while (this.textSelection.anchorIndex < this.textMap.length && this.textMap[this.textSelection.anchorIndex].tag !== "#text") {
            this.textSelection.anchorIndex++;
        }
        if (this.textSelection.anchorIndex === this.textMap.length) {
            // there are no more text nodes; position to end of doc
            // if entire paragraph select, start a new one; otherwise just move to end of last text node
            if (anchorAtTopOfGraf) {
                const nextNodeId = this._getNextNodeId();
                const rec1 = { tag: "p", styles: {}, nodeId: nextNodeId };
                const rec2 = { tag: "#text", styles: {}, text: insertMarker };
                const rec3 = { tag: "/p", nodeId: -nextNodeId };
                this.textMap.splice(this.textMap.length - 1, 0, rec1, rec2, rec3);
                this.textSelection.anchorIndex = this.textSelection.focusIndex = this.textMap.length - 3;
                this.textSelection.anchorOffset = this.textSelection.focusOffset = 0;
            } else {
                this._cursorToEndOfText();
            }
        } else {
            this.textSelection.focusIndex = this.textSelection.anchorIndex;
            this.textSelection.focusOffset = this.textSelection.anchorOffset;
        }
        this.renderFromTextMap();
    }
    _removeTextNodes = (startIndex: number, endIndex: number): TextMapRecord[] => {
        const target: TextMapRecord[] = [];
        for (let i = startIndex; i <= endIndex; i++) {
            if (this.textMap[i].tag !== "#text") {
                target.push(deepcopy(this.textMap[i]));
            }
        }
        // remove any empty block elements
        let foundEmptyBlock = true;
        while (foundEmptyBlock) {
            foundEmptyBlock = false;
            for (let i = 0; i < target.length; i++) {
                if (target[i].nodeId ?? 0 > 0) {
                    for (let j = i + 1; j < target.length; j++) {
                        if (target[j].nodeId === -target[i].nodeId!) {
                            foundEmptyBlock = true;
                            // remove this empty block
                            for (let k = i; k <= j; k++) {
                                target[k].tag = '';         // mark for delete
                                target[k].nodeId = 0;
                            }
                        }
                    }
                }
            }
        }
        return target.filter(entry => entry.tag);
    }
    // #endregion INSERT AND DELETE
}

export default DomFormatter;


