import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';

import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import SamForm from '../libs/forms/SamFormV5';
import CategoryEditor, { extractSelections, integrateSelections } from './CategoryEditor';
import Checkbox from '../libs/Checkbox';
import { useFetchApi, usePostApi, genericApiError, MultiApiResultRecord } from '../libs/useDataApiV2';
import { useGlobalContext, useSessionStore, useTokens } from '../libs/SamState';
import { skuLongToSku } from '../libs/dashboard/dashboard-support';
import { copyArrayWithoutDeleted, skuLongToFormattedMin, StyledErrorText } from '../libs/libSupport';
import { fixUrl } from '../libs/api-shared/api-shared-helpers';
import SamImageGrid from '../libs/dashboard/SamImageGrid';
import FormMgr from '../libs/forms/FormMgr';

import { FGDashboardProductHomeSettings, FGCategoryTreeRecord, FGCategoryRecord, FGDashboardProductRecord } from '../interfaces/fg-api-interfaces';
import { CaptionOptionsEnum, FormFieldRecord, FormFieldType, GraphicDimensionType, ImageRecord, ImageSizeEnum } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import app from '../appData';
import HtmlDomEditor, { HtmlDomEditorApi } from '../libs/dashboard/HtmlDomEditor/HtmlDomEditor';
import { ToolCodeEnum } from '../libs/dashboard/HtmlDomEditor/StyleMgr';

const bulletPointWidth = 430;

const ReadOnlyBar = styled.div`
    display: flex;
    height: 34px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 16px;
`
const ReadOnlyLabel = styled.span`
    font-weight: bold;
    margin-left: 16px;
`
const ReadOnlyText = styled.span`
    margin-left: 4px;
`
const BasicInfoForm = styled.div`
    padding-bottom: 8px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const FormContainer = styled.div`
    width: 800px;
    margin-bottom: 16px;
`
const ActiveCheckboxRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 40px;
`
const Editors = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1300px;
    max-width: 1400px;
`
const CategoryContainer = styled.div`
    width: 300px;
    margin-right: 8px;
`
const TextEditorContainer = styled.div`
    width: 400px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 12px;
    margin-left: 14px;
    width: ${bulletPointWidth}px;
    h3 {
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`
const PhotoEditorContainer = styled.div`
    margin-left: 14px;
    border: 1px solid;
    padding: 12px;
    h3 {
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`
const HtmlEditorContainer = styled.div`
    div {
        margin-bottom: 16px;
        text-align: left;
    }
`
interface ProductEditorProps {
    sku: string;
    formSubmitted: () => void;
}
/* read-only fields:
    sku_long: number;
    vendor: string;
    price: number;
  fields to edit:
    category: number[]  show as list with checkboxes; multiple categories allowed
    caption: string;    defaults to description in fgpos DB; simple field
    url: string;        simple field
    keywords: string;       optional; simple field
    bullet_points: string;  text editor
    images: string[];       first image is main one
    videos: VideoRecord[];  optional
*/

const ProductEditor: React.FC<ProductEditorProps> = (props) => {
    const [homeSettings, setHomeSettings] = React.useState<FGDashboardProductHomeSettings>({} as FGDashboardProductHomeSettings);
    const [productData, setProductData] = React.useState<FGDashboardProductRecord>();   // product being edited
    const [bulletPoints, setBulletPoints] = React.useState<string>();
    const [htmlEditor, setHtmlEditor] = React.useState<HtmlDomEditorApi>();
    const [categoryTree, setCategoryTree] = React.useState<FGCategoryTreeRecord[]>();
    const [categories, setCategories] = React.useState<FGCategoryRecord>();
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [images, setImages] = React.useState<ImageRecord[]>();
    const [beginUploadData, setBeginUploadData] = React.useState(false);

    const { post } = usePostApi();
    const { multiFetch } = useFetchApi();
    const { getToken } = useTokens();
    const { setSessionStore } = useSessionStore();

    // take bullet points as found in database (\n delimited) and convert to html paragraphs
    // preserve existing <p> tags; if a line does not have tags add them
    const preprocessBullets = (points: string[]): string => {
        return "<p>" + points.join("</p><p>") + "</p>";
    }

    const handleRefresh = (e: BeforeUnloadEvent) => {
        console.log("handleRefresh:", { categoryTree, categories, bulletPoints })
        if (assemblePostData()) {
            e.preventDefault();
            return e.returnValue = "Refreshing now will cancel all changes. Are you sure?";
        }
    }
    React.useEffect(() => {
        window.addEventListener('beforeunload', handleRefresh);
        return () => {
            window.removeEventListener('beforeunload', handleRefresh);
        };
    });

    // this depends on order of multiFetch call: sku is first, categories second
    const gotData = (results: MultiApiResultRecord[]) => {
        if (!results[0].data) {
            setErrorMsg("SKU does not exist; please add to retail products first");
        } else {
            const skuData = results[0].data as unknown as FGDashboardProductRecord;
            const categoryData = results[1].data as unknown as { categories: FGCategoryRecord; categoryTree: FGCategoryTreeRecord[] };
            setSessionStore("lastSku", skuLongToFormattedMin(skuData.sku_long));
            //        console.log("gotData is setting:", { categories: categoryData.categories, skuData, images: skuData.images, points: skuData.bullet_points, settings: skuData.homePageSettings })
            setCategories(categoryData.categories);
            //            skuData.categories.sort((a, b) => a - b);
            setProductData(skuData);
            console.log("productData being set to:", skuData)
            setImages(skuData.images);
            setBulletPoints(preprocessBullets(skuData.bullet_points!));
            setHomeSettings(skuData.homePageSettings);
            const tree = integrateSelections(categoryData.categoryTree, skuData.categories);
            setCategoryTree(tree);
        }
    }

    React.useEffect(() => {
        multiFetch([api.loadDashboardSku + props.sku, api.getDashboardCategories], getToken()!.token, gotData, error => setErrorMsg(genericApiError));
    }, []);
    /* example of bullet point html:
        <p>The kids will love building their own balsa wood gliders</p><p>Just <span style="font-weight: bold;"></span>assemble </span>
        included parts to make two stunt glider stunt planes</p><p></p><p></p>
    */
    const postProcessBullets = (html: string): string[] => {
        const grafs = html.split("</p>");
        const points: string[] = [];
        for (let graf of grafs) {
            graf = graf.replaceAll("<p>", '').replaceAll("<br>", '').replaceAll("&nbsp;", '').trim();
            if (graf) {
                points.push(graf);
            }
        }
        return points;
    }

    /* FGBaseProductRecord | FGProductRecord | FGDashboardProductRecord:
        // following initialized by api, returned in productData:
            sku_long: number;   // read only
            caption?: string;   // optional for related products
            price?: number;     // read only and not expected by api
            url?: string;       // created by api
            image?: ImageRecord;    // not used here
            display_order?: number; // ditto
            images?: ImageRecord[];
            videos?: ImageRecord[];
            bullet_points?: string;
            qoh: number;       // not used here
            ymal?: YmalRecord[];    // not used here
            whereItsMade?: WhereItsMadeEnum;    // read only and not used here
            oos?: boolean;      // not used here
            isNew: boolean; 
            vendor: string;     // read only and not used here
            vendor_id: number;  // read only and not used here
            keywords: string;
            categories: number[];      // list of categories this product belongs to
            homePageSettings: FGDashboardProductHomeSettings;
        // following are set by api:
            add_date: Date;
            mod_date: Date;
    */
    React.useEffect(() => {
        if (beginUploadData) {
            setBeginUploadData(false);
            // assemble record for sending to api
            const postData = assemblePostData();
            if (postData) {
                if (postData.images && postData.images.length === 0) {
                    alert("You must include at least one image");
                    return;
                }
                if (postData.categories !== undefined && postData.categories.length === 0) {
                    alert("You must check at least one category");
                    return;
                }
                if (postData.bullet_points !== undefined && !postData.bullet_points) {
                    alert("You must include at least one bullet point");
                    return;
                }
                post(api.saveProduct, postData, postSuccess, () => { window.scrollTo(0, 0); setErrorMsg("Unable to upload product data to server") }, getToken()!.token);
            } else {
                alert("No changes found");
                props.formSubmitted();
            }

        }
    }, [beginUploadData]);

    // return null if product has not been modified
    const assemblePostData = (): FGDashboardProductRecord | null => {
        const { setContext } = useGlobalContext();
        const prodCategories = extractSelections(categoryTree!);
        const forms = new FormMgr(setContext);
        const editedData = forms.getFormValues("product") as FGDashboardProductRecord;      // caption, keywords
        const activeData = forms.getFormValues("active");
        editedData.active = activeData.active;
        editedData.homePageSettings = homeSettings;         // featured, popular, new
        editedData.bullet_points = postProcessBullets(htmlEditor!.getHtml());
        //       console.log({ processedPoints: editedData.bullet_points, rawPoints: htmlEditor!.getHtmlText() })
        editedData.images = copyArrayWithoutDeleted(images!);
        //    editedData.videos = videos;
        editedData.categories = prodCategories;

        // copy dirty fields to post object; if this is not a new product, all fields should be taken
        let postProductData: FGDashboardProductRecord;
        if (productData!.isNew) {
            postProductData = editedData;
            postProductData.sku_long = productData!.sku_long;
            postProductData.isNew = true;
        } else {
            postProductData = loadDirtyValues(editedData, productData!);    // grab all modified values
            // if anything was changed we'll need sku_long to pass to api
            if (Object.keys(postProductData).length) {
                postProductData.sku_long = productData!.sku_long;
            }
        }
        //console.log("postProductData:", postProductData)
        if (Object.keys(postProductData).length) {
            return postProductData;
        }
        return null;
    }

    const fields: FormFieldRecord[] = [
        { name: "caption", label: "Caption", width: 49 },
        { name: "keywords", label: "Keywords", width: 49 },
    ];
    const activeField: FormFieldRecord[] = [
        { name: "active", label: "Active", type: FormFieldType.checkbox }
    ]

    const postSuccess = () => {
        alert("Product data uploaded to server");
        props.formSubmitted();
    }
    const saveClicked = () => {
        setBeginUploadData(true);
    }
    // scan originalData and copy dirty fields
    const loadDirtyValues = (editedData: FGDashboardProductRecord, originalData: FGDashboardProductRecord): FGDashboardProductRecord => {
        const dirty = {} as Record<string, any>;
        for (const property in editedData) {
            // console.log({ property, editedProp: editedData[property as keyof FGDashboardProductRecord], originalProp: originalData[property as keyof FGDashboardProductRecord]});
            // console.log("result of deepEqual is " + deepEqual(editedData[property as keyof FGDashboardProductRecord], originalData[property as keyof FGDashboardProductRecord]));
            if (!deepEqual(editedData[property as keyof FGDashboardProductRecord], originalData[property as keyof FGDashboardProductRecord])) {
                //         if (editedData[property as keyof FGDashboardProductRecord] !== originalData[property as keyof FGDashboardProductRecord]) {
                dirty[property] = editedData[property as keyof FGDashboardProductRecord];
            }
        }
        return dirty as FGDashboardProductRecord;
    }
    const homeSettingsCheckedChanged = (value: boolean, id: string) => {
        const settings = { ...homeSettings } as FGDashboardProductHomeSettings;
        settings[id] = value;
        setHomeSettings(settings);
    }
    const cancelClicked = () => {
        props.formSubmitted();
    }
    const handleCategoryChange = (tree: FGCategoryTreeRecord[] | null, cats: FGCategoryRecord | null) => {
        if (cats) {
            setCategories(cats);
        }
        if (tree) {
            setCategoryTree(tree);
        }
    }

    const handleBulletPaste = (rawText: string): string => {
        const points = "<p>" + rawText.replaceAll('\r', '').split('\n').join("</p><p>") + "</p>";
        return points;
    }

    const renameOnUpload = (file: File): string => {
        const ext = file.name.substring(file.name.lastIndexOf('.') - 1);
        return skuLongToFormattedMin(productData!.sku_long) + "_" + fixUrl(productData!.caption!).substring(0, 50) + ext;
    }

    let categoryList = '';
    let isNew = false;
    if (productData && categories) {
        isNew = !productData.url;
        productData.categories.forEach(categoryId => {
            if (!categories[categoryId]) {
                console.log("category " + categoryId + " not found");
            } else {
                categoryList += categories[categoryId].caption + ", ";
            }
        });
        categoryList = categoryList.slice(0, -2);
    }

    //     console.log({ productData, categories, categoryTree, images, bulletPoints})

    return (
        <React.Fragment>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {productData && categories && categoryTree && images && bulletPoints &&
                <React.Fragment>
                    <h1>{(isNew ? "Adding" : "Editing") + " SKU " + skuLongToSku(productData.sku_long)}</h1>
                    <ReadOnlyBar>
                        <ReadOnlyLabel>Description:</ReadOnlyLabel>
                        <ReadOnlyText>{productData.caption}</ReadOnlyText>
                        <ReadOnlyLabel>Vendor:</ReadOnlyLabel>
                        <ReadOnlyText>{productData.vendor}</ReadOnlyText>
                        <ReadOnlyLabel>Price:</ReadOnlyLabel>
                        <ReadOnlyText>${productData.price!.toFixed(2)}</ReadOnlyText>
                    </ReadOnlyBar>
                    <ButtonsRow height={50} autoStyleButtons={true}>
                        <IconButton caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                        <IconButton caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
                    </ButtonsRow>
                    <BasicInfoForm>
                        <FormContainer>
                            <ActiveCheckboxRow>
                                <SamForm id="active" fields={activeField} fontSize={24} initialValues={{ active: productData.active }} />
                            </ActiveCheckboxRow>
                            <SamForm id="product" fields={fields} initialValues={productData} />
                        </FormContainer>
                        <ReadOnlyBar>
                            <ReadOnlyLabel>Categories:</ReadOnlyLabel>
                            <ReadOnlyText>{categoryList}</ReadOnlyText>
                        </ReadOnlyBar>
                        <ReadOnlyBar>
                            <Checkbox id="isFeatured" checked={homeSettings.isFeatured} checkedChanged={homeSettingsCheckedChanged} caption='Home page "featured"' />
                            <Checkbox id="isPopular" marginLeft={20} checked={homeSettings.isPopular} checkedChanged={homeSettingsCheckedChanged} caption='Home page "popular"' />
                            <Checkbox id="isNew" marginLeft={20} checked={homeSettings.isNew} checkedChanged={homeSettingsCheckedChanged} caption='Home page "new"' />
                        </ReadOnlyBar>
                    </BasicInfoForm>
                    <Editors>
                        <CategoryContainer>
                            <CategoryEditor categories={categories} categoryTree={categoryTree!} allowSelections={true} onChange={handleCategoryChange} />
                        </CategoryContainer>
                        <TextEditorContainer>
                            <h3>Bullet Points</h3>
                            <p>(each paragraph is a bullet point.)</p>
                            <HtmlEditorContainer>
                                <HtmlDomEditor id="bulletPoints" infoPage={{ content: bulletPoints, settings: {} }} width={420}
                                    includedTools={[ToolCodeEnum.bold, ToolCodeEnum.italic, ToolCodeEnum.underline, ToolCodeEnum.link]}
                                    editorInitialized={setHtmlEditor}
                                />
                            </HtmlEditorContainer>
                        </TextEditorContainer>
                        <PhotoEditorContainer>
                            <h3>Product Images</h3>
                            <SamImageGrid id="product" images={images} direction="column"
                                onChange={(images: ImageRecord[]) => setImages(images)}
                                renameOnUpload={renameOnUpload}
                                imageOptions={{
                                    editOptions: { captions: CaptionOptionsEnum.disallow, allowResize: false, allowFloat: false, allowVideo: false },
                                    fileOptions: { sizeDesignator: ImageSizeEnum.full, size: 500, dimension: GraphicDimensionType.height }
                                }}
                                uploadOptions={{ targetDomain: app.targetDomain, uploadImageApiUrl: api.uploadImage }}
                            />
                        </PhotoEditorContainer>
                    </Editors>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ProductEditor;


